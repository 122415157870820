import React, { useState, useMemo } from "react";
import TablePaginatedFooter from "./TablePaginatedFooter";
import TableColumns from "./TableColumns";
import LoadingSpinner from "../LoadingSpinner";

const TablePaginated = ({ fieldNames = [], rowsComponents }) => {
  // State for pagination and row count
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(5);

  // Calculate total pages for pagination
  const totalPages = useMemo(() => {
    return Math.ceil(rowsComponents.length / rowCount);
  }, [rowsComponents.length, rowCount]);

  // Determine rows to display based on current page and row count
  const displayedRows = useMemo(() => {
    const startIndex = (currentPage - 1) * rowCount;
    const endIndex = startIndex + rowCount;
    return rowsComponents.slice(startIndex, endIndex);
  }, [currentPage, rowCount, rowsComponents]);

  return (
    <div className="self-stretch flex flex-col items-center justify-center text-center text-[0.88rem] text-darkslategray-200">
      {fieldNames.length > 0 && <TableColumns fieldNames={fieldNames} />}
      {displayedRows.length > 0 ? displayedRows : <LoadingSpinner />}
      <TablePaginatedFooter
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        rowCount={rowCount}
        setRowCount={setRowCount}
      />
    </div>
  );
};

export default TablePaginated;
