import React from "react";
import { handleImageError } from "../utils/Utils";

const RefCard = ({ id, name, email, photo }) => {
  return (
    <div className="self-stretch rounded-3xs bg-darkslategray-1200 flex flex-row items-center justify-start p-2.5 gap-[44px] border-[1px] border-solid border-darkslategray-1100">
      <div className="flex flex-row items-center justify-start gap-[12px]">
        <img
          className="relative rounded-[50%] w-9 h-9 object-cover"
          alt=""
          src={photo}
          onError={handleImageError}
        />
        <div className="relative">Ref #{id}</div>
      </div>
      <div className="flex-1 relative">{name}</div>
      <div className="flex-1 relative hidden md:block">{email}</div>
    </div>
  );
};

export default RefCard;
