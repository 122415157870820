export const convertToCSV = (AssignerGameData) => {
    // Flatten each game and collect all unique keys as headers
    const flattenedGames = Object.values(AssignerGameData).map(game => flattenObject(game));
    const headers = [...new Set(flattenedGames.flatMap(Object.keys))];

    // Create CSV content
    let csvContent = headers.join(",") + "\n";
    flattenedGames.forEach(game => {
        let row = headers.map(header => JSON.stringify(game[header] || '')).join(",");
        csvContent += row + "\n";
    });

    return csvContent;
};

export const downloadFile = (filename, csvContent) => {
    // Create a blob with the CSV content
    const blob = new Blob([csvContent], {type: "text/csv;charset=utf-8;"});

    // Create a link element for download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";

    // Append the link to the body and trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

function flattenObject(obj, parentKey = '', result = {}) {
    for (let key in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, key)) continue;

        let propName = parentKey ? parentKey + '_' + key : key;

        if (typeof obj[key] === 'object' && obj[key] !== null) {
            if (Array.isArray(obj[key])) {
                for (let i = 0; i < obj[key].length; i++) {
                    flattenObject(obj[key][i], propName + '_' + i, result);
                }
            } else {
                flattenObject(obj[key], propName, result);
            }
        } else {
            result[propName] = obj[key];
        }
    }
    return result;
}
