import React, { useState } from "react";
import ModalInputField from "./ModalInputField";
import TitleModalWrapper from "./TitleModalWrapper";
import ModuleButtonGroup from "./ModalButtonsGroup";

const ManuallyCreateModal = ({
  isOpen,
  onClose,
  ModalTitle,
  fields,
  handleManualImport,
  requiredModalFields,
}) => {
  const [manuallyCreateformData, setManuallyCreateModalformData] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});

  //TODO: add dropdown search for the text fields
  //TODO: add picklist for gender and level of play

  const validateField = (fieldKey) => {
    if (
      requiredModalFields.includes(fieldKey) &&
      !manuallyCreateformData[fieldKey]
    ) {
      return `Please fill in the ${fieldKey} field.`;
    }
    return "";
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    requiredModalFields.forEach((field) => {
      const error = validateField(field);
      if (error) {
        errors[field] = error;
        isValid = false;
      }
    });

    setFieldErrors(errors);
    return isValid;
  };

  const handleInputChange = (e, fieldKey) => {
    setManuallyCreateModalformData((prevData) => ({
      ...prevData,
      [fieldKey]: e.target.value,
    }));

    // Clear error for the field
    if (fieldErrors[fieldKey]) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldKey]: "",
      }));
    }
  };

  const manuallyCreateSubmit = () => {
    if (!validateForm()) {
      alert("Please fill in the red fields before submitting!");
      return;
    }

    // Combine Date and Time into StartTime and prepare the game data for submission
    const { Date, Time, ...restFormData } = manuallyCreateformData;
    const startTime = `${Date} ${Time}`;

    const gameDataWithStartTime = {
      ...restFormData,
      StartTime: startTime,
    };

    // Now passing the modified data with StartTime
    handleManualImport(gameDataWithStartTime);
    setManuallyCreateModalformData({});
    onClose();
  };

  const middleButtons = [
    {
      onClick: manuallyCreateSubmit,
      text: "Create",
      theme: "DARK",
    },
  ];

  return (
    <TitleModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      title={ModalTitle}
      width={"370px"}
    >
      {/*MAIN CONTENT*/}
      <div className="rounded-3xs flex flex-col">
        <div className="self-stretch rounded-t-3xs bg-darkslategray-600 flex flex-row py-[15px] px-5 text-sm border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
          <div className="flex-1 relative font-semibold">Input</div>
          <div className="flex-1 relative font-semibold">Field</div>
        </div>

        {fields?.map((field) => (
          <ModalInputField
            key={field.key}
            field={field}
            error={fieldErrors[field.key]}
            manuallyCreateformData={manuallyCreateformData}
            handleInputChange={handleInputChange}
          />
        ))}
      </div>

      {/*FOOTER*/}
      <ModuleButtonGroup middleButtons={middleButtons} />
    </TitleModalWrapper>
  );
};

export default ManuallyCreateModal;
