import { Routes, Route } from "react-router-dom";
import SharedLayout from "./pages/Global/SharedLayout/SharedLayout";
import RefereeGames from "./pages/Referee/RefereeGames";
import RefereePay from "./pages/Referee/RefereePay";
import RefereeCalendar from "./pages/Referee/RefereeCalendar";
import RefereeOverview from "./pages/Referee/RefereeOverview";
import AssignerDashboard from "./pages/Assigner/AssignerDashboard";
import AssignGames from "./pages/Assigner/AssignGames";
import AssignerReferees from "./pages/Assigner/AssignerReferees";
import AssignerPay from "./pages/Assigner/AssignerPay";
import ProtectedRoute from "./utils/ProtectedRoute";
import { useAppContext } from "./context/MainContext/AppContext";
import LandingPage from "./pages/Global/Login/LandingPage";
import RefereeFindGames from "./pages/Referee/RefereeFindGames";
import { Role } from "./context/MainContext/Constants";
import PrivacyPolicy from "./pages/Global/Login/PrivacyPolicy";
import LoginPage from "./pages/Global/Login/Login";
import SignUp from "./pages/Global/Login/SignUp";
import SettingsPage from "./pages/Global/SettingsPage";
import ResetPage from "./pages/Global/Login/ResetPassword";

function App() {
  const { role, AssignerGameData, RefereeGameData, OrganizationData, getOrganizationData, getOrganizationGames, getGamesByUid } = useAppContext();

  switch (role) {
    case Role.Referee:
      break;
    case Role.Assigner:
      if (OrganizationData == null) {
        getOrganizationData();
      }
      break;
    default:
      break;
  }

  switch (role) {
    case Role.Referee:
      if (RefereeGameData == null) {
        getGamesByUid();
      }
      break;
    case Role.Assigner:
      if (AssignerGameData == null) {
        getOrganizationGames();
      }
      break;
    default:
      break;
  }

  return (
    <>
      <Routes>
        {/* Landing Page accessible to everyone */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset" element={<ResetPage />} />

        {/* Routes for referees */}
        { role === Role.Referee && (
          <Route
            path="/referee"
            element={
              <ProtectedRoute>
                <SharedLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<RefereeOverview />} />
            <Route path="calendar" element={<RefereeCalendar />} />
            <Route path="pay" element={<RefereePay />} />
            <Route path="games" element={<RefereeGames />} />
            <Route path="findgames" element={<RefereeFindGames />} />
            <Route path="settings" element={<SettingsPage />} />
          </Route>
        )}

        {/* Routes for assigners */}
        { (role === Role.Assigner) && (
          <Route
            path="/assigner"
            element={
              <ProtectedRoute>
                <SharedLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<AssignerDashboard />} />
            <Route path="assigngames" element={<AssignGames />} />
            <Route path="referees" element={<AssignerReferees />} />
            <Route path="pay" element={<AssignerPay />} />
          </Route>
        )}

        {/* Optionally, add a catch-all route for unknown paths */}
        {/* <Route path="*" element={<SomeNotFoundPage />} /> */}
        <Route path="*" element={<LandingPage />} />
      </Routes>
    </>
  );
}
export default App;
