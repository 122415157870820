import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTimeFilter = ({startDateTime, setStartDateTime, endDateTime, setEndDateTime}) => {
    const handleDateTimeChange = (date, type) => {
        if (type === "startDateTime") {
            setStartDateTime(date);
        } else if (type === "endDateTime") {
            setEndDateTime(date);
        }
    };

    return (
        <div className="flex flex-col gap-[1rem]">
            <div className="flex flex-row items-center gap-[1rem]">
                <label>Start Date</label>
                <DatePicker
                    selected={startDateTime}
                    onChange={(date) => handleDateTimeChange(date, "startDateTime")}
                    showTimeSelect
                    dateFormat="Pp"
                    className="rounded-3xs border-[1px] border-solid border-lightgray-100"
                />
            </div>
            <div className="flex flex-row items-center gap-[1rem]">

                <label>End Date</label>
                <DatePicker
                    selected={endDateTime}
                    onChange={(date) => handleDateTimeChange(date, "endDateTime")}
                    showTimeSelect
                    dateFormat="Pp"
                    className="rounded-3xs border-[1px] border-solid border-lightgray-100"
                />
            </div>

        </div>
    );
};

export default DateTimeFilter;
