import React from "react";
import RecentPayments from "../../components/placeholder/RefereeRecentPayments";
import SavedPayments from "../../components/placeholder/RefereeSavedPayments";
import Header from "../Global/Header";
import ComingSoonOverlay from "../../components/placeholder/ComingSoonOverlay";

const RefereePay = () => {
  return (
    <>
      <Header />
      <div className="relative bg-white min-h-screen w-full overflow-hidden flex flex-col items-center justify-start font-outfit">
        <div className="position: relative w-full flex flex-col items-center justify-center px-4 lg:px-8 xl:max-w-screen-xl mt-[2rem]">
          <div className="w-full flex flex-col lg:flex-row items-start justify-between gap-4 py-4 w- full">
            <div className="self-stretch rounded-mini w-[11.25rem] flex flex-col items-start justify-center p-[1.56rem] box-border bg-[url('/public/frame-1601@3x.png')] bg-cover bg-no-repeat bg-[top]">
              <span className="font-medium font-bold text-black">Current Balance</span>
              <span className="text-3xl font-bold text text-[1.8rem]">$223.15</span>
            </div>
            {/* Current Balance Card */}
            {/* Automatic Payments Card */}
            <div className="rounded-mini bg-darkslategray-300 box-border w-[18.81rem] flex flex-row items-center justify-start p-[0.94rem] gap-[0.69rem] text-center text-darkslategray-200 border-[1px] border-solid border-darkslategray-400">
              <div className="flex flex-col items-start justify-start gap-[0.31rem]">
                <div className="relative">Automatic Paypents</div>
                <div className="relative text-[1.25rem] font-medium text-limegreen-100">Enabled</div>
              </div>
              <div className="self-stretch relative box-border w-[0.06rem] border-r-[1px] border-solid border-gray-400" />
              <div className="relative box-border w-[0.13rem] h-[0.06rem] border-t-[1px] border-solid border-gray-500" />
              <div className="flex-1 rounded-6xs bg-firebrick-200 flex flex-row items-start justify-center py-[0.44rem] px-[0.63rem] text-[0.75rem] text-firebrick-100">
                <div className="flex flex-row items-center justify-start">
                  <div className="relative">{`Disable `}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-6">
            <RecentPayments />
            <div className="mt-4">
              <SavedPayments />
            </div>
          </div>
        </div>
        <ComingSoonOverlay noRoundedBorder={true} />
      </div>
    </>
  );
};

export default RefereePay;

// import RecentPayments from "../../components/RefereeRecentPayments";
// import SavedPayments from "../../components/RefereeSavedPayments";
// import Header from "../Global/Header";

// const RefereePay = () => {
//   return (
//     <div className="relative bg-white w-full h-[75.56rem] overflow-hidden flex flex-col items-center justify-start gap-[1.38rem] text-center text-[1rem] text-khaki-200 font-outfit">
//       <Header />
//       <div className="w-full flex flex-row items-start justify-center py-[0rem] px-[0.63rem] box-border max-w-[93.75rem]">
//         <div className="flex-1 h-[36.19rem] flex flex-col items-center justify-start gap-[1.25rem]">
//           <div className="self-stretch flex-1 flex flex-row items-start justify-between text-left text-[1rem] text-gray-200 font-outfit">
//             <div className="self-stretch flex flex-col items-start justify-center">
//               <div className="self-stretch flex-1 rounded-3xs flex flex-row items-center justify-start">
//                 <div className="self-stretch rounded-mini w-[11.25rem] flex flex-col items-start justify-center p-[1.56rem] box-border bg-[url('/public/frame-1601@3x.png')] bg-cover bg-no-repeat bg-[top]">
//                   <div className="flex-1 flex flex-col items-start justify-center gap-[0.38rem]">
//                     <div className="relative font-medium">Current Balance</div>
//                     <b className="relative text-[1.88rem]">$223.15</b>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="rounded-mini bg-darkslategray-300 box-border w-[18.81rem] flex flex-row items-center justify-start p-[0.94rem] gap-[0.69rem] text-center text-darkslategray-200 border-[1px] border-solid border-darkslategray-400">
//               <div className="flex flex-col items-start justify-start gap-[0.31rem]">
//                 <div className="relative">Automatic Paypents</div>
//                 <div className="relative text-[1.25rem] font-medium text-limegreen-100">Enabled</div>
//               </div>
//               <div className="self-stretch relative box-border w-[0.06rem] border-r-[1px] border-solid border-gray-400" />
//               <div className="relative box-border w-[0.13rem] h-[0.06rem] border-t-[1px] border-solid border-gray-500" />
//               <div className="flex-1 rounded-6xs bg-firebrick-200 flex flex-row items-start justify-center py-[0.44rem] px-[0.63rem] text-[0.75rem] text-firebrick-100">
//                 <div className="flex flex-row items-center justify-start">
//                   <div className="relative">{`Disable `}</div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="self-stretch flex flex-col items-center justify-start gap-[1.88rem] text-left text-[1.25rem] text-darkslategray-100 font-outfit">
//             <RecentPayments />
//             <SavedPayments />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RefereePay;
