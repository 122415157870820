import Header from "../Global/Header";
import TableHeader from "../../components/table/TableHeader";
import ImportModal from "../../components/Modals/ImportModal";
import ManuallyCreateModal from "../../components/Modals/ManuallyCreateModal";
import React, { useState } from "react";
import { useAppContext } from "../../context/MainContext/AppContext";
import GameAssignModal from "../../components/Modals/GameAssignModal";
import ModifyGameModal from "../../components/Modals/ModifyGameModal";
import { convertToCSV, downloadFile } from "../../utils/DownloadGames";
import { dotPulse } from "ldrs";
import GamesFilter from "../../components/Modals/GamesFilter";
import { filterGameData } from "../../utils/Utils";
import TablePaginated from "../../components/table/TablePaginated";
import AssignGamesGameRow from "../../components/AssignGamesGameRow";
import { IMPORT_GAME_EXAMPLE } from "../../utils/DownloadLinks";
import {
  ImportModalFields,
  ManuallyCreateRequiredFields,
  ManuallyCreateModalInputFields,
} from "../../utils/Constants";

dotPulse.register();

const AssignGames = () => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isManuallyCreateModalOpen, setIsManuallyCreateModalOpen] =
    useState(false);
  const [isModifyGameModalOpen, setIsModifyGameModalOpen] = useState(false);
  const [currentGameData, setCurrentGameData] = useState();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isAssignRefereeModalOpen, setIsAssignRefereeModalOpen] =
    useState(false);
  const [availableReferees, setAvailableReferees] = useState([]);

  const {
    currentOrganization,
    createGames,
    sendGamesToRef,
    AssignerGameData,
    alterGameInfo,
    alterPosition,
    FilterSettings,
    getAvailableReferees,
  } = useAppContext();

  const downloadGamesButton = () => {
    console.log(AssignerGameData);
    const gamesArray = AssignerGameData ? Object.values(AssignerGameData) : [];

    const csvData = convertToCSV(gamesArray);

    downloadFile("AssignerGameData.csv", csvData);
  };

  const handleGameData = (data) => {
    console.log(data);
    const OID = currentOrganization;
    const isCSVData = Array.isArray(data);

    const positions = "CR1, CR2, TK";

    const gamesWithPositions = (isCSVData ? data : [data]).map((game) => {
      const newGame = {
        ...game,
        RefereePattern: positions,
      };

      return newGame;
    });

    const formattedData = {
      OID: OID,
      Games: gamesWithPositions,
    };

    createGames(formattedData);
  };

  function validateRow(row) {
    let errors = [];
    // Regular expression to match the datetime format "YYYY-MM-DD HH:mm"
    const dateTimeFormatRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;

    // Validate 'StartTime' format
    if (!row["StartTime"] || !dateTimeFormatRegex.test(row["StartTime"])) {
      errors.push(
        'StartTime is missing or in an invalid format. Required format: "YYYY-MM-DD HH:mm"'
      );
    }

    // Check if 'Address' field is missing or empty
    if (!row["Address"] || row["Address"].trim() === "") {
      errors.push("Address is missing");
    }

    // Validate 'HalfDuration'
    if (row["HalfDuration"] === "") {
      errors.push("HalfDuration is required");
    } else if (isNaN(row["HalfDuration"])) {
      errors.push("HalfDuration must be a number");
    } else if (!Number.isInteger(Number(row["HalfDuration"]))) {
      errors.push("HalfDuration must be an integer");
    }

    return errors;
  }

  const handleModifyGame = (changes) => {
    setIsModifyGameModalOpen(false);
    alterGameInfo(changes);
    console.log(changes);
  };

  const openGameAssignModal = async ({ game, positionID }) => {
    setAvailableReferees(await getAvailableReferees(game.GID));
    setCurrentGameData({ ...game, positionID });
    setIsAssignRefereeModalOpen(true);
  };

  const openModifyGameModal = (game) => {
    setCurrentGameData(game);
    setIsModifyGameModalOpen(true);
  };

  const assignRefereeToGame = ({ referee, currentGameData }) => {
    alterPosition({
      PID: currentGameData.positionID,
      UID: referee.UID,
      Status: "Pending",
    });
    setIsAssignRefereeModalOpen(false);
  };

  const sendOutGames = () => {
    sendGamesToRef(currentOrganization);
    alert("The pending games were sent to the referees.");
  };

  const { filteredGamesData, filteredUniqueFields } = filterGameData(
    AssignerGameData,
    FilterSettings
  );

  const headerButtons = [
    {
      label: "Download Games",
      iconSrc: "/downloadicon.svg",
      onClick: downloadGamesButton,
    },
    {
      label: "Send Games to Refs",
      iconSrc: "/vector1.svg",
      onClick: sendOutGames,
    },
    {
      label: "Filter",
      iconSrc: "/filter.svg",
      onClick: () => setIsFilterModalOpen((prev) => !prev),
    },
    {
      label: "Create Game",
      iconSrc: "/vector1.svg",
      onClick: () => setIsManuallyCreateModalOpen((prev) => !prev),
    },
    {
      label: "Import Games",
      iconSrc: "/vector1.svg",
      onClick: () => setIsImportModalOpen((prev) => !prev),
    },
  ];

  // Construct row components
  const rowsComponents = filteredGamesData.map((game, index) => (
    <AssignGamesGameRow
      key={index}
      game={game}
      openGameAssignModal={openGameAssignModal}
      openModifyGameModal={openModifyGameModal}
    />
  ));

  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-center text-[1rem] text-khaki-200 font-outfit">
      <Header />
      <div className="w-full max-w-[93.75rem] p-[1.25rem] box-border text-left text-[1.25rem] text-darkslategray-100 flex flex-col items-center justify-center">
        <TableHeader title={"Assign List"} buttons={headerButtons} />

        <GamesFilter
          games={AssignerGameData}
          isOpen={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
        />

        <GameAssignModal
          isOpen={isAssignRefereeModalOpen}
          onClose={() => setIsAssignRefereeModalOpen(false)}
          assignRefereeToGame={assignRefereeToGame}
          availableReferees={availableReferees}
          currentGameData={currentGameData}
        />

        <ModifyGameModal
          isOpen={isModifyGameModalOpen}
          onClose={() => setIsModifyGameModalOpen(false)}
          currentGameData={currentGameData}
          handleModifyGame={handleModifyGame}
        />

        <ImportModal
          isOpen={isImportModalOpen}
          onClose={() => {
            setIsImportModalOpen(false);
          }}
          ModalTitle="Import Games"
          onImport={handleGameData}
          validateRow={validateRow}
          fields={ImportModalFields}
          exampleDownload={IMPORT_GAME_EXAMPLE}
        />

        <ManuallyCreateModal
          isOpen={isManuallyCreateModalOpen}
          onClose={() => {
            setIsManuallyCreateModalOpen(false);
          }}
          requiredModalFields={ManuallyCreateRequiredFields}
          handleManualImport={handleGameData}
          ModalTitle="Create Game"
          fields={ManuallyCreateModalInputFields}
        />

        <TablePaginated
          fieldNames={filteredUniqueFields}
          rowsComponents={rowsComponents}
        />
      </div>
    </div>
  );
};

export default AssignGames;
