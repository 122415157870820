import LandingPageFooter from "../../../components/login/LandingPageFooter";
import LandingPageHeader from "../../../components/login/LandingPageHeader";
import React, {useEffect, useRef, useState} from "react";
import {useAppContext} from "../../../context/MainContext/AppContext";
import useRouter from "../../../hooks/router";
import LoginInputField from "../../../components/login/LoginInputField";

const ResetPage = () => {
    const emailRef = useRef(null);
    const {role, handleGoogleRegister, handlePasswordReset, ResetFailed} = useAppContext();
    const [ResetSent, setResetSent] = useState(false);
    const {handleNavigation} = useRouter();

    const handleForgotPassword = () => {
        handlePasswordReset(emailRef.current.value);
        setResetSent(true);
    };

    useEffect(() => {
    }, [role, handleGoogleRegister]);

    return (
        <div className="relative bg-white w-full flex flex-col items-center justify-start text-center font-outfit">
            <LandingPageHeader/>
            <div
                className="self-stretch flex-grow overflow-auto flex flex-col items-center justify-center py-[0.63rem] px-[0rem] text-left text-[0.88rem] text-darkslategray-100 pt-[14rem] md:pt-16 min-h-screen">
                <div
                    className="rounded-3xs box-border w-[23.19rem] overflow-hidden flex flex-col items-center justify-start py-[1.25rem] px-[2rem] gap-[0.81rem] border-[1px] border-solid border-lightgray">
                    <div
                        className="self-stretch overflow-hidden flex flex-row items-start justify-start py-[0.5rem] pr-[1.75rem] pl-[0.25rem] text-[1.5rem]">
                        <div className="relative">Reset Password</div>
                    </div>

                    {ResetSent && (
                        ResetFailed
                            ? <div className="text-red-500">Email not found</div>
                            : <div className="text-red-500">Reset link sent</div>
                    )}

                    <LoginInputField
                        ref={emailRef}
                        label=""
                        type="email"
                        placeholder="Enter your email"
                    />
                    <div
                        className="self-stretch rounded-3xs bg-darkslategray-400 flex flex-col items-center justify-start text-center text-khaki-100 cursor-pointer"
                        onClick={handleForgotPassword}
                    >
                        <div
                            className="self-stretch rounded-3xs bg-darkslategray-100 flex flex-row items-center justify-center py-[0.31rem] px-[0.63rem] border-[1px] border-solid border-gray cursor-pointer">
                            <div
                                className="relative font-light flex items-center justify-center w-[4.25rem] h-[1.25rem] shrink-0">
                                Reset
                            </div>
                        </div>
                    </div>
                    <div className="self-stretch flex flex-row items-center justify-center gap-[0.63rem] text-center">
                        <div
                            className="overflow-hidden flex flex-row items-start justify-center py-[0.31rem] px-[0rem]">
                            <div className="relative font-light">{`New User? `}</div>
                        </div>
                        <div
                            className="self-stretch overflow-hidden flex flex-row items-center justify-center text-left text-royalblue-100 cursor-pointer"
                            onClick={() => handleNavigation("/signup")}
                        >
                            <div className="relative [text-decoration:underline] font-light">
                                Create Account Here
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LandingPageFooter/>
        </div>
    );
};

export default ResetPage;
