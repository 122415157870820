import React from "react";

const DashBoardStats = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-darkslategray-200">
      <div className="self-stretch flex flex-row items-start justify-start gap-[0.5rem] text-[1rem]">
        <div className="self-stretch flex-1 rounded-3xs bg-white flex flex-row items-start justify-start p-[0.94rem] border-[1px] border-solid border-darkslategray-400">
          <div className="flex-1 flex flex-col items-start justify-start gap-[1.06rem]">
            <div className="rounded-6xs overflow-hidden flex flex-row items-start justify-start p-[0.44rem] border-[1px] border-solid border-darkslategray-400">
              <img className="relative w-[1.13rem] h-[1.13rem]" alt="" src="/timer.svg" />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem]">
              <div className="self-stretch relative opacity-[0.8]">Pay Roll Out</div>
              <div className="relative text-[1.5rem] font-semibold">10,312</div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex-1 rounded-3xs bg-white flex flex-row items-start justify-start p-[0.94rem] text-[0.88rem] border-[1px] border-solid border-darkslategray-400">
          <div className="flex-1 flex flex-col items-start justify-start gap-[1.06rem]">
            <div className="rounded-6xs overflow-hidden flex flex-row items-start justify-start p-[0.44rem] border-[1px] border-solid border-darkslategray-400">
              <div className="flex flex-col items-start justify-start py-[0.06rem] px-[0.31rem]">
                <div className="relative font-medium">%</div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-[1rem]">
              <div className="self-stretch relative opacity-[0.8]">Next 10 Days in Payroll</div>
              <div className="relative text-[1.5rem] font-semibold">$10,021.21</div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex-1 rounded-3xs bg-white flex flex-row items-start justify-start p-[0.94rem] border-[1px] border-solid border-darkslategray-400">
          <div className="flex-1 flex flex-col items-start justify-start gap-[1.06rem]">
            <div className="rounded-6xs overflow-hidden flex flex-row items-start justify-start p-[0.44rem] border-[1px] border-solid border-darkslategray-400">
              <img className="relative w-[1.13rem] h-[1.13rem]" alt="" src="/vuesaxlinearshieldcross.svg" />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem]">
              <div className="self-stretch relative opacity-[0.8]">Next 30 Days in Payroll</div>
              <div className="text-[1.5rem] font-semibold">$15,321.12</div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex-1 rounded-3xs bg-white flex flex-row items-start justify-start p-[0.94rem] border-[1px] border-solid border-darkslategray-400">
          <div className="flex-1 flex flex-col items-start justify-start gap-[1.06rem]">
            <div className="rounded-6xs overflow-hidden flex flex-row items-start justify-start p-[0.44rem] border-[1px] border-solid border-darkslategray-400">
              <img className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0" alt="" src="/airplane.svg" />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem]">
              <div className="relative opacity-[0.8]">Total Invoices</div>
              <div className="relative text-[1.5rem] font-semibold">30</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoardStats;
