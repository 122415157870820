import React from 'react';
import ModalButton from './ModalButton';

const ModuleButtonGroup = ({ leftButtons, middleButtons, rightButtons }) => {
    const renderButtons = (buttons) => buttons.map((button, index) => (
        <ModalButton
            key={index}
            {...button} // Spread the button object as props to ModalButton
        />
    ));

    return (
        <div className="self-stretch flex flex-row items-center justify-between py-0 px-[30px] pt-2 text-sm text-khaki-200">
            {/* Left Buttons */}
            <div className="flex flex-row gap-[10px]">
                {leftButtons && renderButtons(leftButtons)}
            </div>

            {/* Middle Buttons - Rendered conditionally based on presence */}
            {middleButtons && middleButtons.length > 0 && (
                <div className="flex flex-grow justify-center gap-[10px]">
                    {renderButtons(middleButtons)}
                </div>
            )}

            {/* Right Buttons */}
            <div className="flex flex-row gap-[10px]">
                {rightButtons && renderButtons(rightButtons)}
            </div>
        </div>
    );
};

export default ModuleButtonGroup;
