import React, {forwardRef} from 'react';

const LoginInputField = forwardRef(({label, type, placeholder}, ref) => {
    return (
        <div className="self-stretch flex flex-col items-center text-[1rem]">
            {label && (
                <div className="self-stretch flex flex-row pl-[0.25rem]">
                    <div className="relative font-light">{label}:</div>
                </div>
            )}
            <div
                className="self-stretch flex flex-row py-[0.5rem] text-[0.88rem]">
                <div
                    className="flex-grow rounded-3xs bg-whitesmoke-100 flex flex-row py-[0.31rem] border-[1px] border-solid border-lightgray">
                    <input
                        type={type}
                        ref={ref}
                        className="bg-transparent border-none placeholder-darkslategray-200 placeholder-opacity-50"
                        placeholder={placeholder}
                    />
                </div>
            </div>
        </div>
    );
});

LoginInputField.displayName = 'LoginInputField';

export default LoginInputField;
