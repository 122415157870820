import { useAppContext } from "../../context/MainContext/AppContext";
import { useState } from "react";
import ProfileDropdown from "./ProfileDropdown";
import { refereeLinks, assignerLinks } from "../../utils/LinkData";
import HeaderLink from "./HeaderLink";
import HeaderDropdown from "./HeaderDropdown";
import { Role } from "../../context/MainContext/Constants";
import Sidebar from "../../components/Sidebar";
import {getPhotoFromDbUser, handleImageError} from "../../utils/Utils";

const RefereeHeader = () => {
  const { database_user, role, setCurrentOrganization, currentOrganization } =
    useAppContext();
  const [showProfileModal, setShowProfileModal] = useState(false);

  const organizationOptions = Object.entries(
    database_user.Organizations || {}
  ).map(([, org]) => ({
    name: org.Name,
    payload: org.OID,
  }));

  const handleModal = () => {
    setShowProfileModal(!showProfileModal);
  };

  return (
    <div className="self-stretch flex flex-col items-start justify-start text-center text-[1rem] text-khaki-200 font-outfit">
      <div className="self-stretch bg-darkslategray-100 flex flex-row items-center justify-end py-[0.94rem] px-[1.25rem]">
        <img
          className="relative w-[6.44rem] h-[3.06rem] overflow-hidden shrink-0"
          alt="fasdf"
          src="/logo.svg"
        />
        <div className="flex-1" />
        <div
          className="self-stretch rounded-3xs bg-khaki-300 box-border flex flex-row items-center justify-between py-[0.31rem] px-[0.63rem] gap-[.5rem] border-[1px] border-solid border-gray-700 cursor-pointer "
          onClick={handleModal}
        >
          <div className="flex flex-row items-center justify-start gap-[0.56rem]">
            <img
              className="relative rounded-[50%] w-[1.94rem] h-[1.94rem] object-cover"
              alt="User Profile"
              src={getPhotoFromDbUser(database_user)}
              onError={handleImageError}
            />
            <div className="flex flex-col items-start justify-start">
              <div className="relative">{database_user.Name}</div>
            </div>
          </div>
          <img
            className="relative w-[1.5rem] h-[1.5rem] relative"
            alt=""
            src="/arrowdownyellow.svg"
          />
        </div>
        {showProfileModal && (
          <ProfileDropdown
            setShowProfileModal={setShowProfileModal}
            showProfileModal={showProfileModal}
          />
        )}
      </div>
      <div className="self-stretch bg-gainsboro-100 flex flex-row items-center justify-between py-[0.63rem] px-[1.25rem]">
        <div className="hidden lg:flex flex-row items-start justify-start gap-[0.5rem]">
          {role === Role.Referee
            ? refereeLinks.map((link, i) => <HeaderLink key={i} {...link} />)
            : assignerLinks.map((link, i) => <HeaderLink key={i} {...link} />)}
        </div>

        <div className="block lg:hidden">
          <Sidebar />
        </div>
        {role === Role.Assigner && (
          <HeaderDropdown
            options={organizationOptions}
            selectedOption={currentOrganization}
            setSelectedOption={setCurrentOrganization}
          />
        )}
      </div>
    </div>
  );
};

export default RefereeHeader;
