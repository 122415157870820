import React from "react";
import LandingPageHeader from "../../../components/login/LandingPageHeader";
import LandingPageFooter from "../../../components/login/LandingPageFooter";
import CustomerTestimonial from "../../../components/login/CustomerTestimonial";
import DescriptionText from "../../../components/login/DescriptionText";

const LandingPage = () => {
    return (
        <div
            className="bg-white flex flex-col items-center justify-center text-black font-outfit">
            <LandingPageHeader/>

            <div
                className="w-full flex flex-col items-center justify-center text-5xl pt-16 max-w-[93.75rem]">
                <div
                    className="flex flex-col items-center justify-center py-[30px] gap-[19px] text-wrap">
                    <h1 className="p-2.5 text-[48px]">Add Games, Assign Referees</h1>
                    <b className="text-lg font-light text-slategray p-2.5">Handle your payment, referee assignment, and
                        registration with
                        first class customer support</b>
                </div>
                <div
                    className="flex flex-col md:flex-row pt-20 px-5 gap-[69px]">
                    <div className="flex flex-col justify-center items-center">
                        <img
                            className="relative w-[340px] sm:w-[400px] md:w-[450px] lg:w-[765px] lg:h-[470px]"
                            src="/HomeScreenPhoto.png" alt=""/>
                    </div>
                    <div className="flex flex-col items-center justify-start gap-[15px]">
                        <DescriptionText
                            title={"Assign Games"}
                            body={"Easily import your referees and games from a spreadsheet then assign the games to users"}
                        />
                        <DescriptionText
                            title={"Intuitive UI/UX"}
                            body={"Our platform is built with modern day frameworks, up to date architecture, and easy to read UI/UX"}
                        />
                        <DescriptionText
                            title={"Payment Processing"}
                            body={"We handle end to end payment, including 1099’s and all registration forms for your organization"}
                        />
                        <DescriptionText
                            title={"Text Notifications"}
                            body={"Your refs can stay up to date with all of their games both online and over text"}
                        />
                    </div>
                </div>
                <div
                    className="flex flex-col md:flex-row p-20 items-start justify-between max-w-[93.75rem]">
                    <CustomerTestimonial
                        name={"John Jussen"}
                        title={"Soccer referee"}
                        imgSource={"/JohnProfilePicture.png"}
                        quote={"“Ever since my club started using RefAlign I haven’t missed a game. The UI is easy to use and I like the color pallet compared to other assignment websites.”\n"}
                    />

                    <CustomerTestimonial
                        name={"Mike Levene"}
                        title={"Assigner"}
                        imgSource={"/MikeProfilePicture.png"}
                        quote={"“Before RefAlign, we were doing it the old fashion way, with spreadsheets and email. After having numerous mess ups with referees getting to games on time, we were looking for a lightweight solution. RefAlign has perfectly filled this void for us.”"}
                    />

                    <CustomerTestimonial
                        name={"Artsemi"}
                        title={"Soccer referee"}
                        imgSource={"/ArtProfilePicture.png"}
                        quote={"“RefAlign is easy to use, intuitive, and works well. I always enjoy seeing a game email from them.”"}
                    />
                </div>


                <div
                    className="flex flex-col md:flex-row pt-20 pb-20 px-5 gap-[69px]">
                    <div className="flex flex-col items-center justify-center gap-[15px]">
                        <DescriptionText
                            title={"Referee Information"}
                            body={"Referees can login and easily see the games they need to ref, where they need to be, who they are reffing with, and their payment amounts. The Notification system ensures everyone is on time and where they need to be."}
                        />
                        <DescriptionText
                            title={"Easily Assign Games"}
                            body={"Import games to RefAlign, assign them to referees, then push notifications to your refs to come and accept the games."}
                        />

                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <img className="relative w-[340px] sm:w-[400px] md:w-[450px] lg:w-[765px] lg:h-[470px]" alt=""
                             src="/RefAlign_HomepagePhoto2.png"/>
                    </div>
                </div>
            </div>


            <LandingPageFooter/>
        </div>
    );
};

export default LandingPage;
