import Header from "./Header";

const SettingsPage = () => {
    return (
        <div className="relative bg-white w-full h-[85.13rem] overflow-hidden flex flex-col items-center justify-start gap-[0.56rem] text-center text-[1rem] text-khaki-100 font-outfit">
            <Header />
            <div className="self-stretch flex flex-col items-center justify-center max-w-[93.75rem] text-left text-[1.25rem]">
                <div className="self-stretch flex flex-col items-start justify-start">
                    <div className="self-stretch rounded-t-3xs rounded-b-none bg-darkslategray-500 flex flex-row items-center justify-start py-[0.94rem] px-[1.25rem] border-[1px] border-solid">
                        <div className="flex-1 relative font-medium text-darkslategray-100">Account Settings</div>
                    </div>
                </div>
                <div className="self-stretch rounded-t-none rounded-b-mini flex flex-row items-start justify-start p-[0.94rem] gap-[1.88rem] border-r-[1px] border-solid border-b-[1px] border-l-[1px]">
                    <div className="flex flex-col items-start justify-start p-[0.63rem] gap-[0.63rem] text-darkslategray-100">
                        <div className="self-stretch rounded-3xs bg-darkslategray-600 flex flex-row items-center justify-start py-[0.63rem] px-[1.25rem]">
                            <div className="relative font-medium">Profile</div>
                        </div>
                        <div className="rounded-3xs flex flex-row items-center justify-start py-[0.63rem] px-[1.25rem]">
                            <div className="relative">Preferences</div>
                        </div>
                        <div className="self-stretch rounded-3xs flex flex-row items-center justify-start py-[0.63rem] px-[1.25rem]">
                            <div className="relative">Details</div>
                        </div>
                        <div className="self-stretch rounded-3xs flex flex-row items-center justify-start py-[0.63rem] px-[1.25rem]">
                            <div className="relative">Payments</div>
                        </div>
                    </div>
                    <div className="self-stretch relative box-border w-[0.06rem] border-r-[1px] border-solid border-lightgray" />
                    <div className="flex-1 flex flex-col items-start justify-start gap-[0.63rem] text-center text-darkslategray-100">
                        <div className="self-stretch flex flex-col items-start justify-start gap-[1.25rem]">
                            <div className="relative font-medium">Personal Information</div>
                            <div className="self-stretch flex flex-col items-start justify-start gap-[1.25rem] text-[1rem]">
                                <div
                                    className="self-stretch rounded-3xs flex flex-col items-start justify-start p-[0.94rem] gap-[2.06rem] text-[1.25rem] border-[1px] border-solid border-lightgray">
                                    <div className="flex-1 flex flex-row items-center justify-start gap-[0.63rem]">
                                        <img
                                            className="relative rounded-[50%] w-[4rem] h-[4rem] object-cover"
                                            alt=""
                                            src="/JohnProfilePicture.png"
                                        />
                                        <div className="flex flex-col items-start justify-start gap-[0.31rem]">
                                            <div className="relative ">John Jussen</div>
                                            <div className="flex flex-col items-start justify-start opacity-[0.5]">
                                                <div className="relative">Referee</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="self-stretch flex flex-row items-start justify-end gap-[28.75rem] text-[1rem]">
                                        <div className="flex-1 flex flex-col items-start justify-start gap-[2.06rem]">
                                            <div className="flex flex-row items-start justify-start gap-[4.31rem]">
                                                <div className="flex flex-col items-start justify-start gap-[0.44rem]">
                                                    <div className="relative opacity-[0.5]">Email</div>
                                                    <div className="relative">JohnJussen@gmail.com</div>
                                                </div>
                                                <div className="flex flex-col items-start justify-start gap-[0.44rem]">
                                                    <div className="relative opacity-[0.5]">
                                                        Phone Number
                                                    </div>
                                                    <div className="relative">2356789101</div>
                                                </div>
                                            </div>
                                            <div className="flex flex-row items-start justify-start gap-[3rem]">
                                                <div className="flex flex-col items-start justify-start gap-[0.44rem]">
                                                    <div className="relative opacity-[0.5]">
                                                        Referee badge ID/Number
                                                    </div>
                                                    <div className="relative">#12368</div>
                                                </div>
                                                <div className="flex flex-col items-start justify-start gap-[0.44rem]">
                                                    <div className="relative opacity-[0.5]">
                                                        Current Location
                                                    </div>
                                                    <div className="relative">San Jose, Ca</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="rounded-xl flex flex-row items-start justify-start py-[0.63rem] px-[0.94rem] gap-[0.31rem] text-darkslategray-100 border-[1px] border-solid border-lightgray">
                                            <img
                                                className="relative w-[1.25rem] h-[1.25rem]"
                                                alt=""
                                                src="/editiconblack.svg"
                                            />
                                            <div className="relative">Edit</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-start gap-[1.25rem]">
                            <div className="relative font-medium">Preferences</div>
                            <div className="self-stretch flex flex-col items-start justify-start text-[1rem]">
                                <div
                                    className="self-stretch rounded-3xs flex flex-col items-start justify-start p-[0.94rem] border-[1px] border-solid border-lightgray">
                                    <div
                                        className="self-stretch flex flex-row items-center justify-start gap-[28.75rem]">
                                        <div className="flex-1 flex flex-col items-start justify-start">
                                            <div className="flex flex-row items-start justify-start gap-[4.31rem]">
                                                <div className="flex flex-col items-start justify-start gap-[0.44rem]">
                                                    <div className="relative opacity-[0.5]">
                                                        Preferred hours
                                                    </div>
                                                    <div className="relative">8am-10pm</div>
                                                </div>
                                                <div className="flex flex-col items-start justify-start gap-[0.44rem]">
                                                    <div className="relative opacity-[0.5]">Role</div>
                                                    <div className="relative">Center</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rounded-xl flex flex-row items-start justify-start py-[0.63rem] px-[0.94rem] gap-[0.31rem] text-darkslategray-100 border-[1px] border-solid border-">
                                            <img
                                                className="relative w-[1.25rem] h-[1.25rem]"
                                                alt=""
                                                src="/editiconblack.svg"
                                            />
                                            <div className="relative">Edit</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;
