import {getPhotoFromDbUser, handleImageError} from "../../utils/Utils";
import {useEffect, useState} from "react";
import TitleModalWrapper from "./TitleModalWrapper";

const GameAssignModal = ({isOpen, onClose, assignRefereeToGame, availableReferees, currentGameData}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredAvailableReferees, setFilteredAvailableReferees] = useState([]);

    // Handle input change
    const handleInputChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    useEffect(() => {
        // Filter available referees based on the search term
        const filtered = availableReferees.filter(referee => {
            const email = referee.Email ? referee.Email.toLowerCase() : '';
            const name = referee.Name ? referee.Name.toLowerCase() : '';
            return email.includes(searchTerm) || name.includes(searchTerm);
        });

        setFilteredAvailableReferees(filtered);
    }, [searchTerm, availableReferees]);

    // Wrap the onClose function to also clear the search term
    const handleClose = () => {
        setSearchTerm(''); // Reset the search term
        onClose(); // Call the original onClose function
    };

    return (
        <TitleModalWrapper isOpen={isOpen} onClose={handleClose} title="Search Refs" width="776px">
            <div
                className="self-stretch flex flex-col items-start justify-start gap-[21px] text-center text-sm text-darkslategray-200">
                <div className="flex items-center">
                    <img className="relative w-2.5 h-2.5 m-2" alt="" src="/searchnormal6.svg"/>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder="Search Referee"
                        className="w-full rounded-3xs text-xs text-black placeholder:text-darkslategray-100 border-[1px] border-solid border-lightgray-100 focus:border-lightgray-100"
                    />
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[9px] text-left text-xs"
                     style={{height: 'calc(8 * 60px)', overflowY: 'auto'}}>
                    {filteredAvailableReferees?.map((referee) => (
                        <RefereeRow
                            key={referee.UID}
                            referee={referee}
                            currentGameData={currentGameData}
                            assignRefereeToGame={assignRefereeToGame}
                        />
                    ))}
                </div>
            </div>
        </TitleModalWrapper>
    );
};

export default GameAssignModal;

const RefereeRow = ({referee, currentGameData, assignRefereeToGame}) => {
    return (
        <div
            className="self-stretch rounded-3xs bg-darkslategray-1200 flex flex-row items-center justify-start p-2.5 gap-[44px] border-[1px] border-solid border-darkslategray-1100">
            <div className="flex-1 flex flex-row items-center justify-start gap-[12px]">
                <img className="relative rounded-[50%] w-9 h-9 object-cover" alt=""
                     src={getPhotoFromDbUser(referee)} onError={handleImageError}/>
                <div className="relative">{referee.Name}</div>
                <div className="relative">{referee.Email}</div>
            </div>
            <div
                className="rounded-3xs flex flex-row items-center justify-center gap-[.4rem] p-2.5 bg-limegreen-200 text-limegreen-100 cursor-pointer"
                onClick={() => assignRefereeToGame({referee, currentGameData})}
            >
                <img className="relative w-[.75rem] h-[.75rem] border-limegreen-100" alt="" src="/vector1.svg"/>
                <div className="relative">Assign Game</div>
            </div>
        </div>
    );
};
