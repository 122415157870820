import RefereeRefalignPay from "../../components/placeholder/RefereeRefalignPay";
import RefereeReportGames from "../../components/RefereeReportGames";
import RefereeAnnouncements from "../../components/RefereeAnnouncements";
import RefereeAssignedGames from "../../components/RefereeAssignedGames";
import Header from "../Global/Header";
import RefereeUpcomingGames from "../../components/RefereeUpcomingGames";
import ReportGameModal from "../../components/Modals/ReportGameModal";
import React, { useState, useEffect } from "react";
import { useAppContext } from "../../context/MainContext/AppContext";
import {
  filterPendingGames,
  filterUpcomingGames,
  filterReportGames,
} from "../../utils/filterGamesData";

const RefereeOverview = () => {
  const { alterPosition, database_user, RefereeGameData } =
    useAppContext();
  const [isReportGameModalOpen, setIsReportGameModalOpen] = useState(false);
  const [reportGameData, setReportGameData] = useState(null);
  const [reportGameReferees, setReportGameReferees] = useState(null);
  const UID = database_user.UID;

  const [filteredPendingGames, setFilteredPendingGames] = useState(null);
  const [filteredUpcomingGames, setFilteredUpcomingGames] = useState(null);
  const [filteredReportGames, setFilteredReportGames] = useState(null);

  useEffect(() => {
    if (RefereeGameData && UID) {
      setFilteredPendingGames(filterPendingGames(RefereeGameData, UID));
      setFilteredUpcomingGames(filterUpcomingGames(RefereeGameData, UID));
      setFilteredReportGames(filterReportGames(RefereeGameData, UID));
    }
  }, [RefereeGameData, UID]);

  const handleButtonClick = {
    handleReportGames: (data) => {
      setReportGameData(data);

      const positionsArray = Object.entries(data.gameData.Positions).map(
        ([id, positionData]) => {
          return { id, ...positionData };
        }
      );

      setReportGameReferees(positionsArray);
      setIsReportGameModalOpen(true);
    },

    handleAssignedGames: (data) => {
      console.log(data);
      alterPosition(data);
    },

    handleUpcomingGames: (data) => {
      alterPosition(data);
      // alert("Error, please reach out to your assigner to turnback this game!");
    },

    handleSubmitReportGameModal: (data) => {
      alterPosition(data);
      console.log(data);
      setIsReportGameModalOpen(false);
    },
  };

  useEffect(() => {
  }, []);

  return (
    <div className="relative bg-white w-full h-[59.56rem] flex flex-col items-start justify-start">
      <Header />
      <div className="self-stretch flex-1 flex flex-col items-center justify-start p-[1rem] gap-[1.25rem]">
        <div className="w-full flex flex-col items-center justify-start gap-[0.94rem] max-w-[93.75rem] xl:flex-row">
          <div className="self-stretch flex flex-col items-start justify-between">
            <RefereeRefalignPay />
          </div>
          <RefereeReportGames
            apidata={filteredReportGames}
            handleButtonClick={handleButtonClick}
            UID={UID}
          />
          <RefereeAnnouncements />
        </div>
        <div className="w-full flex-1 flex flex-col xl:flex-row items-start justify-start gap-[1.25rem] max-w-[93.75rem]">
          <RefereeAssignedGames
            pendingGames={filteredPendingGames}
            UID={UID}
            handleButtonClick={handleButtonClick}
          />
          <RefereeUpcomingGames
            upcomingGames={filteredUpcomingGames}
            UID={UID}
            handleButtonClick={handleButtonClick}
          />
        </div>
      </div>

      <ReportGameModal
        isOpen={isReportGameModalOpen}
        onClose={() => setIsReportGameModalOpen(false)}
        handleButtonClick={handleButtonClick}
        reportGameData={reportGameData}
        reportGameReferees={reportGameReferees}
      />
    </div>
  );
};

export default RefereeOverview;
