import LandingPageFooter from "../../../components/login/LandingPageFooter";
import LandingPageHeader from "../../../components/login/LandingPageHeader";
import React, {useEffect, useRef, useState} from "react";
import {Role} from "../../../context/MainContext/Constants";
import {useAppContext} from "../../../context/MainContext/AppContext";
import useRouter from "../../../hooks/router";
import LoginInputField from "../../../components/login/LoginInputField";
import {mapFirebaseErrorToMessage, validateCredentials} from "../../../utils/Utils";
import ModalButton from "../../../components/Modals/ModalButton";

const SignUp = () => {
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const { role, handleRegularRegister, handleGoogleRegister } = useAppContext();
    const { handleNavigation } = useRouter();

    const [signupError, setSignupError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleRegister = async () => {
        setLoading(true);
        setSignupError('');

        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const error = validateCredentials(name, email, password);

        if (error) {
            setSignupError(error);
            setLoading(false);
            return;
        }

        try {
            await handleRegularRegister(name, email, password);
        } catch (error) {
            const errorMessage = mapFirebaseErrorToMessage(error.message);
            setSignupError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleGRegister = async () => {
        setLoading(true);
        setSignupError('');

        try {
            await handleGoogleRegister();
        } catch (error) {
            const errorMessage = mapFirebaseErrorToMessage(error.message);
            setSignupError(errorMessage);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (role) {
            switch (role) {
                case Role.Referee:
                    handleNavigation(`/referee/`);
                    break;
                case Role.Assigner:
                    handleNavigation(`/assigner/`);
                    break;
            }
        }
    }, [role, handleGoogleRegister]);

    return (
        <div className="relative bg-white w-full flex flex-col items-center justify-start text-center font-outfit">
            <LandingPageHeader/>
            <div
                className="flex flex-col items-center justify-start pt-[7rem] md:justify-center md:pt-0 min-h-screen text-darkslategray-100">
                <div
                    className="rounded-3xs flex flex-col items-center w-[19rem] py-[1.25rem] px-[2rem] gap-[0.81rem] border-[1px] border-solid border-lightgray">
                    <div
                        className="flex flex-row text-[1.5rem]">
                        <div>Sign up</div>
                    </div>

                    {signupError && <div className="text-red-500">{signupError}</div>}

                    <LoginInputField
                        ref={nameRef}
                        label="Name"
                        type="text"
                        placeholder="Enter your name"
                    />
                    <LoginInputField
                        ref={emailRef}
                        label="Email"
                        type="email"
                        placeholder="Enter your email"
                    />
                    <LoginInputField
                        ref={passwordRef}
                        label="Password"
                        type="password"
                        placeholder="Enter your password"
                    />
                    <ModalButton
                        onClick={handleRegister}
                        text='Sign Up'
                        theme='DARK'
                        width='275px'
                        state={loading ? "LOADING": "ACTIVE"}
                    />
                    <ModalButton
                        onClick={handleGRegister}
                        text="Sign up with Google"
                        theme="GOOGLE"
                        state={loading ? "LOADING" : "ACTIVE"}
                        icon="/googleicon.svg"
                        width='275px'
                    />
                    <div className="self-stretch flex flex-row items-center justify-center gap-[0.63rem] text-center">
                        <div
                            className="overflow-hidden flex flex-row items-start justify-center py-[0.31rem] px-[0rem]">
                            <div className="relative font-light">{`Already a user? `}</div>
                        </div>
                        <div
                            className="self-stretch overflow-hidden flex flex-row items-center justify-center text-left text-royalblue-100 cursor-pointer"
                            onClick={() => handleNavigation("/login")}
                        >
                            <div className="relative [text-decoration:underline] font-light">
                                Sign in here
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LandingPageFooter/>
        </div>
    );
};

export default SignUp;
