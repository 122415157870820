import React from "react";
import RefereeOverviewGameRow from "./RefereeOverviewGameRow";
import TableHeader from "./table/TableHeader";

const RefereeReportGames = ({ apidata, openReportGameModal, UID, handleButtonClick }) => {
  const reportGamesArray = apidata ? Object.values(apidata).slice(0, 4) : null;

  return (
    <div className="self-stretch flex-1 flex flex-col items-center justify-center text-left text-[1.25rem] text-darkslategray-100 font-outfit">
        <TableHeader
            title={"Report Games"}
        />
      <div className="self-stretch flex-1 rounded-t-none rounded-b-3xs flex flex-col items-center justify-between py-[0.63rem] px-[0.94rem] gap-[0.56rem] text-[0.69rem] border-[1px] border-solid border-lightgray-100">
        {reportGamesArray === null ? (
          <div className="flex flex-1 items-center justify-center">
            <l-dot-pulse size="43" speed="1.3" color="#2d556c"></l-dot-pulse>
          </div>
        ) : (
          reportGamesArray.map((apidata, index) => (
            <RefereeOverviewGameRow
              key={index}
              handleButtonClick={handleButtonClick}
              apidata={apidata}
              UID={UID}
              bgColor={"bg-goldenrod-100"}
              openReportGameModal={openReportGameModal}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default RefereeReportGames;