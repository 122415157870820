import Header from "../Global/Header";
import DashBoardStats from "../../components/PaymentDashBoard";
import ComingSoonOverlay from "../../components/placeholder/ComingSoonOverlay";

const AssignerPay = () => {
  return (
    <>
      <Header />
      <div className="position: relative bg-white min-h-screen overflow-hidden flex flex-col items-center justify-start gap-[1.25rem] text-center text-[1rem] text-khaki-200 font-outfit pt-[2rem]">
        <div className="w-full flex flex-col items-center justify-center py-[0rem] px-[1.25rem] box-border gap-[1.44rem] max-w-[93.75rem] text-left text-[1.25rem] text-darkslategray-200">
          <div className="self-stretch rounded-mini bg-white flex flex-col items-start justify-start p-[1.25rem] border-[1px] border-solid border-lightgray-100">
            <DashBoardStats />
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[0.94rem] text-darkslategray-100 w-[100%]">
            <div className="h-[30.88rem] flex flex-col items-start justify-start">
              <div className="self-stretch rounded-t-3xs rounded-b-none bg-darkslategray-500 flex flex-row items-center justify-start py-[0.63rem] px-[1.25rem] gap-[1.69rem] border-[1px] border-solid border-lightgray-100">
                <div className="flex-1 relative font-medium"> Payment Activities</div>
                <div className="rounded-6xs bg-white flex flex-row items-center justify-start py-[0.44rem] px-[0.63rem] text-[0.75rem] border-[1px] border-solid border-lightgray-100">
                  <div className="flex flex-row items-center justify-start gap-[0.44rem] opacity-[0.8]">
                    <div className="flex flex-row items-center justify-start">
                      <div className="relative">Monthly</div>
                    </div>
                    <img className="relative w-[0.88rem] h-[0.94rem]" alt="" src="/arrowdownblack.svg" />
                  </div>
                </div>
              </div>
              <div className="flex-1 rounded-t-none rounded-b-3xs bg-white flex flex-col items-start justify-end p-[0.94rem] text-[0.5rem] text-black font-poppins border-r-[1px] border-solid border-lightgray-100 border-b-[1px] border-l-[1px]">
                <div className="flex-1 rounded-3xs box-border w-[26.94rem] flex flex-row flex-wrap items-end justify-start pt-[1.25rem] px-[1.25rem] pb-[1.88rem] relative gap-[1.88rem] border-[1px] border-solid border-darkslategray-400">
                  <div className="flex-1 h-[23.25rem] flex flex-row items-end justify-start gap-[0.94rem] z-[0] text-[0.63rem]">
                    <div className="self-stretch flex flex-col items-start justify-end gap-[0.63rem]">
                      <div className="relative">350</div>
                      <div className="relative">350</div>
                      <div className="relative">350</div>
                      <div className="relative">350</div>
                      <div className="relative">350</div>
                      <div className="relative">350</div>
                      <div className="relative">350</div>
                      <div className="relative">350</div>
                      <div className="relative">350</div>
                      <div className="relative">350</div>
                      <div className="relative">350</div>
                      <div className="relative">300</div>
                      <div className="relative">200</div>
                      <div className="relative">150</div>
                      <div className="relative">100</div>
                    </div>
                    <div className="self-stretch flex-1 flex flex-row items-end justify-start">
                      <div className="self-stretch flex-1 flex flex-row items-end justify-start gap-[0.63rem]">
                        <div className="flex-1 relative rounded-8xs bg-royalblue-200 h-[21.94rem] opacity-[0.2]" />
                        <div className="flex-1 relative rounded-8xs bg-royalblue-200 h-[10.31rem] opacity-[0.2]" />
                        <div className="flex-1 relative rounded-8xs bg-royalblue-200 h-[22.44rem] opacity-[0.2]" />
                        <div className="flex-1 relative rounded-8xs bg-royalblue-200 h-[14.63rem] opacity-[0.2]" />
                        <div className="flex-1 relative rounded-8xs bg-royalblue-200 h-[16.75rem]" />
                        <div className="flex-1 relative rounded-8xs bg-royalblue-200 h-[8.31rem] opacity-[0.2]" />
                        <div className="flex-1 relative rounded-8xs bg-royalblue-200 h-[12.31rem] opacity-[0.2]" />
                        <div className="flex-1 relative rounded-8xs bg-royalblue-200 h-[4.88rem] opacity-[0.2]" />
                        <div className="flex-1 relative rounded-8xs bg-royalblue-200 h-[14.06rem] opacity-[0.2]" />
                        <div className="flex-1 relative rounded-8xs bg-royalblue-200 h-[9.56rem] opacity-[0.2]" />
                        <div className="flex-1 relative rounded-8xs bg-royalblue-200 h-[18.88rem] opacity-[0.2]" />
                        <div className="flex-1 relative rounded-8xs bg-royalblue-200 h-[21.25rem] opacity-[0.2]" />
                      </div>
                    </div>
                  </div>
                  <div className="absolute my-0 mx-[!important] top-[24.38rem] left-[3.5rem] z-[1]">Jan</div>
                  <div className="absolute my-0 mx-[!important] top-[24.38rem] left-[5.44rem] z-[2]">Feb</div>
                  <div className="absolute my-0 mx-[!important] top-[24.38rem] left-[9.38rem] z-[3]">Apr</div>
                  <div className="absolute my-0 mx-[!important] top-[24.38rem] left-[11.25rem] z-[4]">May</div>
                  <div className="absolute my-0 mx-[!important] top-[24.38rem] left-[13.06rem] z-[5]">Jun</div>
                  <div className="absolute my-0 mx-[!important] top-[24.38rem] left-[15.13rem] z-[6]">Jul</div>
                  <div className="absolute my-0 mx-[!important] top-[24.38rem] left-[16.88rem] z-[7]">Aug</div>
                  <div className="absolute my-0 mx-[!important] top-[24.38rem] left-[18.81rem] z-[8]">Sep</div>
                  <div className="absolute my-0 mx-[!important] top-[24.38rem] left-[20.75rem] z-[9]">Oct</div>
                  <div className="absolute my-0 mx-[!important] top-[24.38rem] left-[22.63rem] z-[10]">Nov</div>
                  <div className="absolute my-0 mx-[!important] top-[24.38rem] left-[24.56rem] z-[11]">Dec</div>
                  <div className="absolute my-0 mx-[!important] top-[24.38rem] left-[7.38rem] z-[12]">Mar</div>
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start">
              <div className="self-stretch rounded-t-3xs rounded-b-none bg-darkslategray-500 flex flex-row items-center justify-start py-[0.63rem] px-[1.25rem] gap-[1.69rem] border-[1px] border-solid border-lightgray-100">
                <div className="flex-1 relative font-medium">Recent Payments</div>
                <div className="rounded-6xs bg-white flex flex-row items-center justify-start py-[0.44rem] px-[0.63rem] text-[0.75rem] border-[1px] border-solid border-lightgray-100">
                  <div className="flex flex-row items-center justify-start gap-[0.44rem] opacity-[0.8]">
                    <div className="flex flex-row items-center justify-start gap-[0.44rem]">
                      <img className="relative w-[1.25rem] h-[1.25rem]" alt="" src="/sort.svg" />
                      <div className="relative">Sort by</div>
                    </div>
                    <img className="relative w-[0.88rem] h-[0.94rem]" alt="" src="/arrowdownblack.svg" />
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start text-center text-[0.88rem] text-darkslategray-200">
                <div className="self-stretch bg-darkslategray-600 box-border h-[3.13rem] flex flex-row items-start justify-between py-[0.94rem] px-[1.25rem] text-[1rem] text-darkslategray-100 border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
                  <div className="self-stretch flex-1 flex flex-row items-start justify-between">
                    <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">
                      Referee Name
                    </div>
                  </div>
                  <div className="self-stretch flex-1 flex flex-row items-start justify-between">
                    <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">
                      Date
                    </div>
                  </div>
                  <div className="self-stretch flex-1 flex flex-row items-start justify-between">
                    <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">
                      Game ID
                    </div>
                  </div>
                  <div className="self-stretch flex-1 flex flex-row items-start justify-between">
                    <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">
                      Total Pay
                    </div>
                  </div>
                  <div className="self-stretch flex-1 flex flex-row items-start justify-between">
                    <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">
                      Status
                    </div>
                  </div>
                </div>
                <div className="self-stretch bg-gray-600 box-border h-[3.94rem] flex flex-row items-center justify-between p-[1.25rem] border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
                  <div className="self-stretch flex-1 flex flex-row items-center justify-between">
                    <div className="self-stretch flex-1 relative flex items-center justify-center">Ayoub</div>
                  </div>
                  <div className="self-stretch flex-1 flex flex-row items-center justify-between">
                    <div className="self-stretch flex-1 relative flex items-center justify-center">9/08/2023</div>
                  </div>
                  <div className="self-stretch flex-1 flex flex-row items-center justify-between">
                    <div className="self-stretch flex-1 relative flex items-center justify-center">127900</div>
                  </div>
                  <div className="self-stretch flex-1 flex flex-row items-center justify-between">
                    <div className="self-stretch flex-1 relative flex items-center justify-center">$189</div>
                  </div>
                  <div className="self-stretch flex-1 flex flex-col items-center justify-between text-[0.63rem] text-orange-100">
                    <div className="rounded-xl bg-orange-200 flex flex-row items-start justify-start py-[0.31rem] px-[0.63rem]">
                      <div className="relative">Settled</div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch rounded-t-none rounded-b-3xs bg-gray-600 flex flex-row items-center justify-start py-[0.63rem] px-[1.25rem] text-left border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
                  <div className="flex-1 flex flex-row items-center justify-start gap-[0.63rem]">
                    <div className="relative">Show</div>
                    <div className="rounded-8xs flex flex-col items-start justify-start py-[0.31rem] px-[1.25rem] border-[1px] border-solid border-darkslategray-400">
                      <div className="relative">7</div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-start gap-[0.5rem] text-[0.63rem]">
                    <div className="rounded-6xs overflow-hidden flex flex-col items-center justify-start p-[0.38rem] border-[1px] border-solid border-darkslategray-600">
                      <img className="relative w-[0.75rem] h-[0.75rem]" alt="" src="/arrowleft.svg" />
                    </div>
                    <div className="flex flex-row items-center justify-start gap-[0.19rem]">
                      <div className="rounded-6xs bg-darkslategray-600 overflow-hidden flex flex-col items-center justify-start py-[0.31rem] px-[0.63rem] text-darkslategray-100">
                        <div className="relative">1</div>
                      </div>
                      <div className="rounded-6xs overflow-hidden flex flex-col items-center justify-start py-[0.31rem] px-[0.63rem] border-[1px] border-solid border-darkslategray-400">
                        <div className="relative">2</div>
                      </div>
                      <div className="rounded-6xs overflow-hidden flex flex-col items-center justify-start py-[0.31rem] px-[0.63rem] border-[1px] border-solid border-darkslategray-400">
                        <div className="relative">3</div>
                      </div>
                    </div>
                    <div className="rounded-6xs overflow-hidden flex flex-col items-center justify-start p-[0.38rem] border-[1px] border-solid border-darkslategray-600">
                      <img className="relative w-[0.75rem] h-[0.75rem]" alt="" src="/arrowright.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ComingSoonOverlay noRoundedBorder={true} />
      </div>
    </>
  );
};

export default AssignerPay;
