import { Outlet } from "react-router-dom";

import GamesFilter from "../../../components/Modals/GamesFilter.js";
import { useAppContext } from "../../../context/MainContext/AppContext.js";

const SharedLayout = () => {
  const { gamesFilterModal } = useAppContext();

  return (
    <>
      <Outlet />
      {gamesFilterModal && <GamesFilter />}
    </>
  );
};

export default SharedLayout;
