export const SET_ROLE = "SWAP_ROLES";

export const SET_FIREBASE_USER = "LOGIN_SUCCESS";

export const SET_TOKEN = "SET_TOKEN";

export const LOGOUT = "LOGOUT";

export const TOGGLE_GAMES_FILTER_MODAL = "TOGGLE_GAMES_FILTER_MODAL";

export const SET_ORGANIZATION_DATA = "GET_ORGANIZATION";

export const SET_DATABASE_USER = "GET_USER";

export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION";

export const SET_GAMES_BY_OID = "GET_GAMES_BY_ORG_ID";

export const SET_GAMES_BY_UID = "GET_GAMES_BY_UID";

export const ALTER_POSITION_ASSIGNER = "ALTER_POSITION_ASSIGNER"

export const ALTER_POSITION_REFEREE = "ALTER_POSITION_REFEREE"

export const SET_FILTER_SETTINGS = "SET_FILTER_SETTINGS"

export const RESET_FAILED = "RESET_FAILED"
