import React from 'react';

const TableHeaderButton = ({ label, iconSrc, onClick, className = '' }) => {
    return (
        <div
            className={`self-stretch rounded-3xs bg-white overflow-hidden flex flex-row items-center justify-start py-[0.38rem] px-[0.63rem] text-darkslategray-100 gap-[0.44rem] text-center text-[0.75rem] border-[1px] border-solid border-lightgray-100 cursor-pointer active:scale-95 active:bg-darkslategray-600 transition duration-75 ease-in-out ${className}`}
            onClick={onClick}
        >
            {iconSrc && <img className="relative w-[1.13rem] h-[1.13rem]" alt="" src={iconSrc} />}
            <div className="relative font-light">{label}</div>
        </div>
    );
};

export default TableHeaderButton;
