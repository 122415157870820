const SavedPayments = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start text-left text-[1.25rem] text-darkslategray-100 font-outfit">
      <div className="self-stretch rounded-t-3xs rounded-b-none bg-darkslategray-500 flex flex-row items-center justify-start py-[0.63rem] px-[1.25rem] gap-[1.69rem] border-[1px] border-solid border-lightgray-100">
        <div className="flex-1 relative font-medium">Saved Payment Method</div>
        <div className="rounded-6xs bg-white flex flex-row items-center justify-start py-[0.44rem] px-[0.63rem] gap-[0.31rem] text-[0.75rem] border-[1px] border-solid border-lightgray-100">
          <img className="relative w-[1rem] h-[1rem]" alt="" src="/addcircle.svg" />
          <div className="flex flex-row items-center justify-start opacity-[0.8]">
            <div className="flex flex-row items-center justify-start">
              <div className="relative">Add New Payment Method</div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch mb-[1rem] flex flex-col items-start justify-start text-center text-[1rem]">
        <div className="self-stretch bg-darkslategray-600 flex flex-row items-start justify-between py-[0.94rem] px-[1.25rem] border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
          <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">Type</div>
          <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">Account</div>
          <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">Name</div>
          <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">Primary</div>
          <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">
            Created Date
          </div>
          <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">Modify</div>
        </div>
        <div className="self-stretch flex-1 bg-gray-600 shadow-[-10px_-10px_20px_rgba(84,_183,_49,_0.1)_inset,_10px_10px_20px_rgba(84,_183,_49,_0.1)_inset] flex flex-row items-center justify-between p-[1.25rem] text-[0.88rem] text-darkslategray-200 border-[1px] border-solid border-limegreen-100">
          <div className="self-stretch flex-1 relative flex items-center justify-center">Checking</div>
          <div className="self-stretch flex-1 relative flex items-center justify-center">xxxx2341</div>
          <div className="self-stretch flex-1 relative flex items-center justify-center">CHASE BANK</div>
          <div className="self-stretch flex-1 relative flex items-center justify-center">YES</div>
          <div className="self-stretch flex-1 relative flex items-center justify-center">1/2/2020</div>
          <div className="flex-1 rounded-6xs flex flex-row items-start justify-center py-[0.44rem] px-[0.63rem] text-[0.75rem] text-firebrick-100">
            <div className="rounded-3xs bg-mistyrose-100 flex flex-row items-center justify-start py-[0.63rem] px-[1.25rem]">
              <div className="relative">{`Disable `}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedPayments;
