import React from "react";
import RefereeOverviewButtons from "./RefereeModifyGameButtons";

const RefereeOverviewGameRow = ({ apidata, bgColor, textcolor, handleButtonClick, UID }) => {
  const refereeDataArray = Object.values(apidata.Positions);

  return (
    <div className="self-stretch rounded-6xs flex flex-col sm:flex-row items-center gap-[1rem] md:gap-[2rem] justify-between p-[0.63rem] text-left text-[0.69rem] text-darkslategray-100 font-outfit border-[1px] drop-shadow-sm	 border-solid border-lightgray-100">
      <div className="flex-1 flex flex-row justify-between gap-[1rem]">
        <div className="flex flex-row items-center justify-start gap-[0.13rem]">
          <img className="relative w-[1rem] h-[1rem]" alt="" src="/timer.svg" />

          <div className="flex flex-col items-center justify-center gap-[0.13rem]">
            <div className="flex flex-row items-center justify-center">
              <div className="relative">{apidata.StartTime}</div>
            </div>
          </div>
        </div>

        <div
          className={`rounded-3xs ${bgColor} flex flex-row items-center justify-start p-[0.31rem] gap-[0.63rem] text-center ${textcolor}`}
        >
          <div className="relative leading-[137.3%]">
            <p className="m-0">{apidata.HalfDuration}</p>
            <p className="m-0 text-[0.63rem] font-light">{apidata.Address}</p>
          </div>
        </div>
      </div>

      <div className="flex-1 flex flex-row justify-between">
        <div className="flex flex-col items-start justify-start">
          {refereeDataArray.map((refData, index) => (
            <div key={index} className="flex ">
              {/* {refData.UID === UID && <img className="w-[1rem] h-[1rem] mr-[0.31rem]" alt="Flag" src="/flagicon.svg" />} */}
              <p className={`m-0 ${refData.UID === UID ? "font-bold" : ""}`}>
                {refData.User ? `${refData.Name} - ${refData.User.Name}` : `${refData.Name} - ${refData.Status}`}
              </p>
            </div>
          ))}
        </div>

        <div className="flex flex-col justify-center items-center">
          {refereeDataArray.map((refData, index) => (
            <div key={index}>
              {refData.UID === UID ? (
                <RefereeOverviewButtons refData={refData} gameData={apidata} handleButtonClick={handleButtonClick} />
              ) : (
                <div className="placeholder-for-button" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default RefereeOverviewGameRow;
