import React from "react";

const RefereeRow = ({ user, openHandleModifyRefereeModal, openHandleRemoveRefereeModal, type }) => {
  return (
    <div className="self-stretch bg-gray-600 flex flex-row items-center justify-center p-[1.25rem] border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
      <div className="flex-1 flex flex-row items-center justify-between">
        <div className="relative justify-center items-center">{type}</div>
      </div>
      <div className="flex-1 flex flex-row items-center justify-between">
        <div className="relative justify-center items-center">{user.Name}</div>
      </div>
      <div className="flex-1 flex flex-row items-center justify-between">
        <div className="relative justify-center items-center">{user.Email}</div>
      </div>
      <div className="flex-1 flex flex-row items-center justify-between">
        <div className="relative justify-center items-center">{user.PermissionLevel}</div>
      </div>
      <div className="flex-1 flex flex-row items-center justify-center gap-[0.69rem]">
        <div
          className={`rounded-8xs bg-darkslategray-1400 flex flex-row items-center justify-center p-[0.44rem] cursor-pointer transition-transform duration-150`}
          onClick={() => openHandleModifyRefereeModal(user)}
        >
          <img className="relative w-[1.25rem] h-[1.25rem]" alt="" src="/editiconblack.svg" />
        </div>
        <div
          className={`rounded-8xs bg-firebrick-500 flex flex-row items-center justify-center p-[0.44rem] cursor-pointer transition-transform duration-150`}
          onClick={() => openHandleRemoveRefereeModal(user)}
        >
          <img className="relative w-[1.25rem] h-[1.25rem]" alt="" src="/closecirclered.svg" />
        </div>
      </div>
    </div>
  );
};

export default RefereeRow;
