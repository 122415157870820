import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useAppContext } from "../context/MainContext/AppContext";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import { useState, useEffect } from "react";
import GameRowDetails from "./Modals/GameRowDetails";

const RefereeCalendarComp = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const { RefereeGameData } = useAppContext();

  const [calendarHeight, setCalendarHeight] = useState(
    window.innerHeight - 225
  );

  useEffect(() => {
    const handleResize = () => {
      setCalendarHeight(window.innerHeight - 225);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  const handleEventClick = (clickInfo) => {
    setIsModalOpen(true);

    setSelectedEvent(clickInfo.event);
  };

  const gameEvents = Object.values(RefereeGameData ? RefereeGameData : []).map((game) => {
    let endTime = null;
    const startTimeStr =
      game && game.StartTime ? game.StartTime.replace(" ", "T") : null;
    const startTime = startTimeStr ? new Date(startTimeStr) : null;

    console.log("StartTime string:", startTimeStr, "Parsed Date:", startTime);

    if (startTime && !isNaN(startTime.getTime()) && game.HalfDuration) {
      const durationMatch = game.HalfDuration.match(/(\d+)/);
      if (durationMatch) {
        const halfDurationMinutes = parseInt(durationMatch[1], 10);
        const fullDurationMinutes = halfDurationMinutes * 2;
        endTime = new Date(startTime.getTime() + fullDurationMinutes * 60000);
      }
    }

    console.log("EndTime:", endTime);

    return {
      start:
        startTime && !isNaN(startTime.getTime())
          ? startTime.toISOString()
          : null,
      end: endTime && !isNaN(endTime.getTime()) ? endTime.toISOString() : null,
      title: game.FieldName,
      extendedProps: { ...game },
    };
  });

  return (
    <div className="font-outfit text-darkslategray mt-4">
      <FullCalendar
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          googleCalendarPlugin,
        ]}
        initialView="timeGridWeek"
        scrollTime="07:00:00"
        contentHeight={calendarHeight}
        headerToolbar={{
          start: "title",
          center: "",
          end: "prev,next today",
        }}
        eventClick={handleEventClick}
        timeZone="local"
        events={gameEvents}
      />
      {isModalOpen && (
        <GameRowDetails
          isOpen={isModalOpen}
          onClose={handleClose}
          game={selectedEvent._def.extendedProps}
        />
      )}
    </div>
  );
};

export default RefereeCalendarComp;
