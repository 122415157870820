import React from "react";
import ComingSoonOverlay from "./ComingSoonOverlay";

const RefereeRefalignPay = () => {
  return (
    <div className="hidden xl:block posistion: relative rounded-xl bg-darkslategray-500 flex flex-col items-center justify-center py-[1.25rem] px-[0.63rem] text-left text-[0.88rem] text-black font-outfit border-[1px] border-solid border-lavender">
      <div className="flex flex-col items-start justify-start">
        <div className="rounded-3xs w-[23.94rem] flex flex-row items-start justify-start">
          <div className="self-stretch flex-1 rounded-tl-xl rounded-tr-none rounded-br-none rounded-bl-xl flex flex-col items-center justify-center pt-[0.94rem] px-[1.56rem] pb-[1.56rem] bg-[url('/public/frame-160@3x.png')] bg-cover bg-no-repeat bg-[top]">
            <div className="self-stretch flex-1 flex flex-col items-start justify-start">
              <div className="flex flex-row items-start justify-start">
                <div className="relative font-medium">RefAlign Pay</div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start text-center text-[2.19rem] text-gray-200">
                <b className="relative">$223.15</b>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-center gap-[0.63rem] text-[0.63rem] text-white">
              <div className="flex-1 flex flex-col items-start justify-end">
                <div className="rounded-xl bg-gray-100 flex flex-row items-center justify-center py-[0.31rem] px-[0.63rem] gap-[0.19rem]">
                  <img
                    className="relative w-[0.75rem] h-[0.75rem]"
                    alt=""
                    src="/trendup.svg"
                  />
                  <div className="relative font-medium">+$122.00 This Week</div>
                </div>
              </div>
              <div className="self-stretch flex-1 flex flex-row items-end justify-end text-black">
                <div className="relative">Updated 9:43PM</div>
              </div>
            </div>
          </div>
          <div className="rounded-tl-none rounded-tr-xl rounded-br-xl rounded-bl-none w-[5.75rem] h-[12.06rem] flex flex-col items-center justify-start pt-[1.25rem] px-[0.94rem] pb-[0.94rem] box-border bg-[url('/public/frame-162@3x.png')] bg-cover bg-no-repeat bg-[top] text-center text-[0.5rem] text-gray-300">
            <div className="flex-1 flex flex-col items-center justify-start">
              <img
                className="relative w-[2rem] h-[0.94rem] object-cover"
                alt=""
                src="/mpmpmp-1@2x.png"
              />
            </div>
            <div className="self-stretch rounded-6xs bg-goldenrod-200 box-border h-[2.56rem] flex flex-col items-center justify-center py-[0.31rem] px-[0.69rem] gap-[0.13rem] border-[1px] border-solid border-gray-700">
              <img
                className="relative w-[0.75rem] h-[0.75rem]"
                alt=""
                src="/import.svg"
              />
              <div className="relative font-medium">Deposit</div>
            </div>
          </div>
        </div>
      </div>
      <ComingSoonOverlay />
    </div>
  );
};

export default RefereeRefalignPay;
