import React, {useState, useEffect, useRef} from "react";

const SearchInput = ({FilteredData}) => {
    const [inputValue, setInputValue] = useState(FilteredData?.Value || '');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const wrapperRef = useRef(null); // Updated ref name for clarity

    const setInput = (value) => {
        setInputValue(value);
        FilteredData.Value = value;
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInput(value);

        if (value.trim() === "") {
            setShowDropdown(false); // Hide dropdown if input is empty
        } else {
            const filtered = FilteredData.Items.filter((option) =>
                option.toLowerCase().includes(value)
            );
            setFilteredOptions(filtered);
            setShowDropdown(filtered.length > 0);
        }
    };


    const selectOption = (option) => {
        setInput(option);
        setShowDropdown(false);
    };

    const handleClickOutside = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div ref={wrapperRef} className="relative w-[133px] mr-[1.65rem]">
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Search"
                className="w-full rounded-3xs text-xs text-black border-[1px] border-solid border-lightgray-100 focus:border-[1px] focus:border-solid focus:border-lightgray-100 placeholder:text-darkslategray-100"
            />
            {showDropdown && (
                <div
                    className="absolute w-full bg-white border-[1px] border-solid border-lightgray-100 rounded-3xs max-h-48 overflow-y-auto z-50">
                    {filteredOptions.map((option) => (
                        <div
                            key={option}
                            className="w-full cursor-pointer text-xs py-2 hover:bg-lightgray-100"
                            onClick={() => selectOption(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchInput;
