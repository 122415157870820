import React from "react";

const TableColumns = ({ fieldNames }) => {
    return (
        <div className="self-stretch bg-darkslategray-600 flex flex-row items-center justify-start py-[0.94rem] px-[1.25rem] text-[1rem] text-darkslategray-100 border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
            {fieldNames.map((fieldName, index) => (
                <div key={index} className="self-stretch flex-1 flex flex-row items-center justify-between">
                    <div className="relative font-semibold">{fieldName}</div>
                </div>
            ))}
        </div>
    );
};

export default TableColumns;
