import React from "react";

const CustomerTestimonial = ({imgSource, name, title, quote}) => {
    return (
        <div className="flex flex-col p-2.5 gap-[10px]">
            <div className="flex flex-row gap-[45px]">
                <div className="flex flex-col items-center justify-center">
                    <img className="w-[78px] h-[78px] object-cover" alt=""
                         src={imgSource}/>
                </div>
                <div
                    className="flex flex-col py-[13px] pr-[26px] pl-0">
                    <div className="font-light">{name}</div>
                    <div className="text-xl font-thin">{title}</div>
                </div>
            </div>
            <div
                className="flex md:max-w-[340px] p-2.5 text-lg font-light text-slategray">
                {quote}
            </div>
        </div>
    )
};

export default CustomerTestimonial;
