import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function PaginationRounded({count, setCurrentPage}) {
    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <Stack
            spacing={2}
            style={{
                justifySelf: "end",
                marginLeft: "1rem",
            }}
            className="mb-2"
        >
            <Pagination count={count} variant="outlined" shape="rounded" onChange={handleChange}/>
        </Stack>
    );
}
