import React from "react";

const RefereeModifyGameButtons = ({refData, handleButtonClick, gameData, isColumnLayout}) => {
    const handleClick = (buttonType) => {
        let newStatus = {};
        switch (buttonType) {
            case "accept":
                newStatus = {Status: "Report", PID: refData.PID, UID: refData.UID};
                handleButtonClick.handleAssignedGames(newStatus);
                break;
            case "deny":
                newStatus = {Status: "Pending", PID: refData.PID, UID: refData.UID};
                handleButtonClick.handleAssignedGames(newStatus);
                break;
            case "report":
                newStatus = {refData: refData, gameData: gameData};
                handleButtonClick.handleReportGames(newStatus);
                break;
            case "pending":
                newStatus = {refData: refData, gameData: gameData};
                handleButtonClick.handleReportGames(newStatus);
                break;
            case "turnback":
                newStatus = {Status: "Report", PID: refData.PID, UID: refData.UID};
                handleButtonClick.handleUpcomingGames(newStatus);

                break;
            default:
                // Optional: Handle unexpected button types
                break;
        }
    };

    const renderButton = () => {
        switch (refData.Status) {
            case "Report": {
                const width = isColumnLayout ? "px-[1.17rem]" : "";
                const textWidth = isColumnLayout ? "px-[.5rem]" : "px-[1.25rem]";

                return (
                    <div
                        className={`flex flex-col items-center justify-center gap-[0rem] ml-[.5rem] text-center ${width}`}>
                        <div
                            className={`rounded-6xs bg-salmon-100 hover:bg-salmon-200 flex items-center justify-center py-[0.44rem] ${textWidth} text-white cursor-pointer transition-transform duration-150`}
                            onClick={() => handleClick("report")}
                        >
                            <div className="relative font-medium text-white">Report Score</div>
                        </div>
                    </div>
                );
            }
            case "SentToRef": {
                const layoutClass = isColumnLayout ? "flex flex-col" : "flex flex-row";
                const width = isColumnLayout ? "px-[2rem]" : "";
                return (
                    <div
                        className={`${layoutClass} items-center justify-center gap-[0.94rem] ml-[.5rem] text-limegreen-100 font-outfit ${width}`}
                    >
                        <div
                            className="rounded-8xs bg-limegreen-100 hover:bg-limegreen-200 flex items-center justify-center py-[0.44rem] px-[1rem] text-white cursor-pointer transition-transform duration-150"
                            onClick={() => handleClick("accept")}
                        >
                            <div className="relative font-medium">Accept</div>
                        </div>
                        <div
                            className="rounded-6xs bg-salmon-100 hover:bg-salmon-200 flex items-center justify-center py-[0.44rem] px-[1rem] text-white cursor-pointer transition-transform duration-150"
                            onClick={() => handleClick("deny")}
                        >
                            <div className="relative font-medium">Deny</div>
                        </div>
                    </div>
                );
            }

            case "Accepted": {
                const width = isColumnLayout ? "px-[.5rem]" : "";
                const textWidth = isColumnLayout ? "px-[.5rem]" : "px-[1.25rem]";
                return (
                    <div
                        className={`flex flex-col items-center justify-center gap-[0.25rem] ml-[.5rem] text-center ${width}`}>
                        <div
                            className={`rounded-6xs bg-royalblue-100 hover:bg-royalblue-200 flex items-center justify-center py-[0.44rem] ${textWidth} text-white cursor-pointer transition-transform duration-150"`}
                            onClick={() => handleClick("turnback")}
                        >
                            <div className="relative font-medium">Turnback Game</div>
                        </div>
                    </div>
                );
            }
            case "Referee_Reported": {
                const width = isColumnLayout ? "px-[.72rem]" : "";
                return (
                    <div
                        className={`flex flex-col items-center justify-center gap-[0.25rem] ml-[.5rem] text-center ${width}`}>
                        <div>
                            <div className="relative font-medium">Report submitted</div>
                        </div>
                    </div>
                );
            }
            case "Pending": {
                return <div>Pending Assignment</div>
            }

            default:
                return <div>ERROR</div>;
        }
    };

    return <div>{renderButton()}</div>;
};

export default RefereeModifyGameButtons;
