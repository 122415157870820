import RecentPaymentsRow from "./RefereeRecentPaymentsRow";

const RecentPayments = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start text-left text-[1.25rem] text-darkslategray-100 font-outfit">
      <div className="self-stretch rounded-t-3xs rounded-b-none bg-darkslategray-500 flex flex-row items-center justify-start py-[0.63rem] px-[1.25rem] gap-[1.69rem] border-[1px] border-solid border-lightgray-100">
        <div className="flex-1 relative font-medium">Recent Payments</div>
        <div className="rounded-6xs bg-white flex flex-row items-center justify-start py-[0.44rem] px-[0.63rem] text-[0.75rem] border-[1px] border-solid border-lightgray-100">
          <div className="flex flex-row items-center justify-start opacity-[0.8]">
            <div className="flex flex-row items-center justify-start">
              <div className="relative">See more</div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start text-center text-[0.88rem] text-darkslategray-200">
        <div className="self-stretch bg-darkslategray-600 flex flex-row items-start justify-between py-[0.94rem] px-[1.25rem] text-[1rem] text-darkslategray-100 border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
          <div className="flex-1 h-[1.25rem] flex flex-row items-start justify-between">
            <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">Date</div>
          </div>
          <div className="flex-1 h-[1.25rem] flex flex-row items-start justify-between">
            <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">Posted</div>
          </div>
          <div className="flex-1 h-[1.25rem] flex flex-row items-start justify-between">
            <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">Group</div>
          </div>
          <div className="flex-1 h-[1.25rem] flex flex-row items-start justify-between">
            <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">
              Description
            </div>
          </div>
          <div className="flex-1 h-[1.25rem] flex flex-row items-start justify-between">
            <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">Status</div>
          </div>
          <div className="flex-1 h-[1.25rem] flex flex-row items-start justify-between">
            <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">Amount</div>
          </div>
          <div className="flex-1 h-[1.25rem] flex flex-row items-start justify-between">
            <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">Fee</div>
          </div>
          <div className="flex-1 h-[1.25rem] flex flex-row items-start justify-between">
            <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">ID</div>
          </div>
          <div className="flex-1 h-[1.25rem] flex flex-row items-start justify-between">
            <div className="self-stretch flex-1 relative font-semibold flex items-center justify-center">Balance</div>
          </div>
        </div>
        <RecentPaymentsRow />
        <div className="self-stretch bg-gray-600 flex flex-row items-center justify-start p-[1.25rem] border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
          <div className="self-stretch flex-1 relative flex items-center justify-center">9/08/2023</div>
          <div className="self-stretch flex-1 relative flex items-center justify-center">9/08/2023</div>
          <div className="self-stretch flex-1 relative flex items-center justify-center">-</div>
          <div className="self-stretch flex-1 relative flex items-center justify-center">
            Checking Account: xxxx2341
          </div>
          <div className="self-stretch flex-1 relative flex items-center justify-center">-$362.45</div>
          <div className="self-stretch flex-1 relative flex items-center justify-center">$0</div>
          <div className="self-stretch flex-1 relative flex items-center justify-center">$0</div>
          <div className="self-stretch flex-1 relative flex items-center justify-center">235123</div>
          <div className="self-stretch flex-1 flex flex-col items-center justify-between text-[0.63rem] text-orange-100">
            <div className="rounded-xl bg-orange-200 flex flex-row items-start justify-start py-[0.31rem] px-[0.63rem]">
              <div className="relative">Settled</div>
            </div>
          </div>
        </div>
        <div className="self-stretch rounded-t-none rounded-b-3xs bg-gray-600 flex flex-row items-center justify-start py-[0.63rem] px-[1.25rem] text-left border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
          <div className="flex-1 flex flex-row items-center justify-start gap-[0.63rem]">
            <div className="relative">Show</div>
            <div className="rounded-8xs flex flex-col items-start justify-start py-[0.31rem] px-[1.25rem] border-[1px] border-solid border-darkslategray-400">
              <div className="relative">3</div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[0.5rem] text-[0.63rem]">
            <div className="rounded-6xs overflow-hidden flex flex-col items-center justify-start p-[0.38rem] border-[1px] border-solid border-darkslategray-600">
              <img className="relative w-[0.75rem] h-[0.75rem]" alt="" src="/arrowleft.svg" />
            </div>
            <div className="flex flex-row items-center justify-start gap-[0.19rem]">
              <div className="rounded-6xs bg-darkslategray-600 overflow-hidden flex flex-col items-center justify-start py-[0.31rem] px-[0.63rem] text-darkslategray-100">
                <div className="relative">1</div>
              </div>
              <div className="rounded-6xs overflow-hidden flex flex-col items-center justify-start py-[0.31rem] px-[0.63rem] border-[1px] border-solid border-darkslategray-400">
                <div className="relative">2</div>
              </div>
              <div className="rounded-6xs overflow-hidden flex flex-col items-center justify-start py-[0.31rem] px-[0.63rem] border-[1px] border-solid border-darkslategray-400">
                <div className="relative">3</div>
              </div>
            </div>
            <div className="rounded-6xs overflow-hidden flex flex-col items-center justify-start p-[0.38rem] border-[1px] border-solid border-darkslategray-600">
              <img className="relative w-[0.75rem] h-[0.75rem]" alt="" src="/arrowright.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentPayments;
