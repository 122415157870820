import React from "react";
import PaginationRounded from "./Pagination";
import RowsPerPageSelector from "./RowsPerPageSelector";

const TablePaginatedFooter = ({setCurrentPage, totalPages, rowCount, setRowCount}) => {
    return (
        <div
            className="self-stretch rounded-t-none rounded-b-3xs bg-darkslategray-600 flex flex-row items-center justify-between py-[0.63rem] px-[1.25rem] text-left border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
            <PaginationRounded count={totalPages} setCurrentPage={setCurrentPage}/>
            <RowsPerPageSelector
                rowCount={rowCount}
                setRowCount={setRowCount}
            />
        </div>
    );
};

export default TablePaginatedFooter;
