import React from "react"

const LoadingSpinner = () => {
    return(
    <div className="self-stretch flex flex-row items-center h-[15rem] justify-center border-solid border-darkslategray-400 border-r-[1px] border-l-[1px]">
        <l-dot-pulse
            size="43"
            speed="1.3"
            color="#2d556c"
        ></l-dot-pulse>
    </div>
)}

export default LoadingSpinner;