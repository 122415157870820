import LandingPageFooter from "../../../components/login/LandingPageFooter";
import LandingPageHeader from "../../../components/login/LandingPageHeader";
import React, { useEffect, useRef, useState } from "react";
import { Role } from "../../../context/MainContext/Constants";
import { useAppContext } from "../../../context/MainContext/AppContext";
import useRouter from "../../../hooks/router";
import LoginInputField from "../../../components/login/LoginInputField";
import {mapFirebaseErrorToMessage, validateCredentials} from "../../../utils/Utils";
import ModalButton from "../../../components/Modals/ModalButton";

const LoginPage = () => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const { role, handleRegularLogin, handleGoogleRegister } = useAppContext();
  const { handleNavigation } = useRouter();
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    setLoginError("");
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    const error = validateCredentials(undefined, email, password);

    if (error) {
      setLoginError(error);
      setLoading(false);
      return;
    }

    try {
      await handleRegularLogin(email, password);
    } catch (error) {
      const errorMessage = mapFirebaseErrorToMessage(error.message);
      setLoginError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    setLoginError("");

    try {
      await handleGoogleRegister();
    } catch (error) {
      const errorMessage = mapFirebaseErrorToMessage(error.message);
      setLoginError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (role) {
      switch (role) {
        case Role.Referee:
          handleNavigation(`/referee/`);
          break;
        case Role.Assigner:
          handleNavigation(`/assigner/`);
          break;
      }
    }
  }, [role, handleGoogleRegister]);

  return (
    <div className="flex flex-col items-center font-outfit">
      <LandingPageHeader />
      <div className="flex flex-col items-center justify-start pt-[7rem] md:justify-center md:pt-0 min-h-screen text-darkslategray-100">
        <div className="rounded-3xs flex flex-col items-center w-[19rem] py-[1.25rem] px-[2rem] gap-[0.81rem] border-[1px] border-solid border-lightgray">
          <div className="flex flex-row text-[1.5rem]">
            <div>Login</div>
          </div>

          {loginError && <div className="text-red-500">{loginError}</div>}

          <LoginInputField
            ref={emailRef}
            label="Email"
            type="email"
            placeholder="Enter your email"
          />
          <LoginInputField
            ref={passwordRef}
            label="Password"
            type="password"
            placeholder="Enter your password"
          />
          <div
            className="self-stretch flex flex-row text-royalblue-100 font-light cursor-pointer"
            onClick={() => handleNavigation("/reset")}
          >
            <div>Forgot Password?</div>
          </div>
          <ModalButton
            onClick={handleLogin}
            text="Login"
            theme="DARK"
            width="275px"
            state={loading ? "LOADING" : "ACTIVE"}
          />
          <ModalButton
            onClick={handleGoogleLogin}
            text="Sign in with Google"
            theme="GOOGLE_SIGN_IN"
            state={loading ? "LOADING" : "ACTIVE"}
            icon="/googleicon.svg"
            width="275px"
          />

          <div className="flex flex-row gap-[0.63rem] text-center">
            <div className="flex flex-row items-start py-[0.31rem] relative font-light">
              New User?
            </div>
            <div
              className="self-stretch flex flex-row items-center justify-center text-left text-royalblue-100 cursor-pointer"
              onClick={() => handleNavigation("/signup")}
            >
              <div className="relative [text-decoration:underline] font-light">
                Create Account Here
              </div>
            </div>
          </div>
        </div>
      </div>
      <LandingPageFooter />
    </div>
  );
};

export default LoginPage;
