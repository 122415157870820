import {initialState} from "./AppContext";

import {
  SET_GAMES_BY_OID,
  SET_GAMES_BY_UID,
  SET_ORGANIZATION_DATA,
  SET_FIREBASE_USER,
  LOGOUT,
  SET_CURRENT_ORGANIZATION,
  SET_TOKEN,
  SET_DATABASE_USER,
  SET_ROLE,
  TOGGLE_GAMES_FILTER_MODAL,
  ALTER_POSITION_ASSIGNER,
  ALTER_POSITION_REFEREE,
  SET_FILTER_SETTINGS,
  RESET_FAILED,
} from "../actions";
import {addToLocalStorage, clearStorage, removeFromLocalStorage, StorageKeys} from "./LocalStorage";

const updateState = (state, updates) => ({
  ...state,
  ...updates,
});

function updatePosition(games, org, update) {
  games.forEach(game => {
    // Ensure game.Positions exists and is an array
    if (!game.Positions || !Array.isArray(game.Positions)) {
      console.warn("Positions not found or not an array in game:", game);
      return; // Skip this game and continue with the next one
    }

    // Find the position with the matching PID in each game
    const positionToUpdate = game.Positions.find(pos => pos.PID === update.PID);

    // If a matching position is found, update its fields
    if (positionToUpdate) {
      // Update position fields from update object
      Object.keys(update).forEach(key => {
        positionToUpdate[key] = update[key];
      });

      // If update.UID exists, ensure org.Users is defined and is an array
      if (update.UID) {
        if (!org || !org.Users || !Array.isArray(org.Users)) {
          console.warn("Users not found or not an array in org:", org);
          return; // Skip user update
        }

        const userToUpdate = org.Users.find(user => user.UID === update.UID);

        // If a matching user is found, update the User field of the position
        if (userToUpdate) {
          positionToUpdate.User = userToUpdate;
        }
      }
    }
  });
}


const reducer = (state, action) => {
  switch (action.type) {
    case SET_ROLE:
      addToLocalStorage(StorageKeys.ROLE, action.payload);
      removeFromLocalStorage(StorageKeys.ACTIVE_TAB)
      return updateState(state, { header: 0, role: action.payload });

    case SET_FIREBASE_USER:
      addToLocalStorage(StorageKeys.FIREBASE_USER, action.payload);
      return updateState(state, { firebase_user: action.payload});

    case SET_DATABASE_USER:
      addToLocalStorage(StorageKeys.DATABASE_USER, action.payload)
      return updateState(state, { database_user: action.payload });

    case SET_TOKEN:
      return updateState(state, { access_token: action.payload });

    case LOGOUT:
      clearStorage();
      return { ...initialState, firebase_user: null, database_user: null, role: null, access_token: null };

    case SET_CURRENT_ORGANIZATION:
      addToLocalStorage(StorageKeys.CURRENT_ORG, action.payload);
      return updateState(state, { currentOrganization: action.payload });

    case SET_GAMES_BY_UID:
      return updateState(state, { RefereeGameData: action.payload });

    case TOGGLE_GAMES_FILTER_MODAL:
      return updateState(state, { gamesFilterModal: !action.payload });

    case SET_ORGANIZATION_DATA:
      return updateState(state, { OrganizationData: action.payload });

    case SET_GAMES_BY_OID:
      return updateState(state, { AssignerGameData: action.payload });

    case ALTER_POSITION_REFEREE:
      updatePosition(state.RefereeGameData, state.OrganizationData, action.payload)
      return updateState(state, { RefereeGameData: state.RefereeGameData });

    case ALTER_POSITION_ASSIGNER:
      updatePosition(state.AssignerGameData, state.OrganizationData, action.payload)
      return updateState(state, { AssignerGameData: state.AssignerGameData });

    case SET_FILTER_SETTINGS:
      return updateState(state, { FilterSettings: action.payload });

    case RESET_FAILED:
      return updateState(state, { ResetFailed: action.payload });

    default:
      throw new Error(`No matching ${action.type} - action type`);
  }
};

export default reducer;
