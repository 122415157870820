import React from "react";
import FilterModalRow from "../FilterModalRow";
import {useAppContext} from "../../context/MainContext/AppContext";
import {getUniqueFieldNames, getUniqueValuesForField} from "../../utils/Utils";
import TitleModalWrapper from "./TitleModalWrapper";
import ModuleButtonGroup from "./ModalButtonsGroup";

function createFilterObjects(games, currentFilterSettings) {
    console.log(currentFilterSettings);
    const uniqueFields = getUniqueFieldNames(games);
    const result = [];

    uniqueFields.forEach((fieldName) => {
        const selected = true;
        let type;
        let items = [];

        // Find the existing setting if it exists
        const existingSettingIndex = currentFilterSettings.findIndex(setting => setting.Name === fieldName);

        // Determine the 'Type' based on the field name (modify this switch statement as needed)
        switch (fieldName) {
            // Dropdown
            case "League":
            case "HomeTeam":
            case "AwayTeam":
            case "LevelOfPlay":
                type = "Dropdown";
                items = getUniqueValuesForField(games, fieldName);
                break;

            // DatePicker
            case "StartDate":
                type = "DatePicker";
                break;

            // Skip these entries
            case "GID":
            case "RefereePattern":
            case "Positions":
            case "OID":
                return;

            // Default is SearchBox
            default:
                type = "SearchBox";
                break;
        }

        if (existingSettingIndex !== -1) {
            // Deep copy the existing setting to avoid modifying the original
            const copiedSetting = JSON.parse(JSON.stringify(currentFilterSettings[existingSettingIndex]));

            // Update the 'Items' field of the copied entry
            copiedSetting.Items = items;

            // Push the updated setting into result
            result.push(copiedSetting);
        } else {
            // Create the dropdown object if it does not exist
            const dropdownObject = {
                Name: fieldName,
                Selected: selected,
                Type: type,
                Items: items,
            };
            result.push(dropdownObject);
        }
    });

    return result;
}

function GamesFilter({games, isOpen, onClose}) {
    const { FilterSettings, setFilterSettings } = useAppContext();
    const filterRowItems = createFilterObjects(games, FilterSettings);

    const handleClose = () => {
        onClose();
    };

    const handleRemoveFilter = () => {
        setFilterSettings([]);
        onClose();
    };

    const handleFilter = () => {
        setFilterSettings(filterRowItems);
        onClose();
    }

    const middleButtons = [
        {
            onClick: handleFilter,
            text: "Save Filter",
            theme: "DARK",
        },
        {
            onClick: handleRemoveFilter,
            text: "Remove Filter",
            theme: "SALMON",
        },
    ];


    return (
        <TitleModalWrapper isOpen={isOpen} onClose={handleClose} title={"Filter"} width={"w-[300px] md:w-[640px]"}>
            {/*MAIN CONTENT*/}
            <div className="self-stretch flex flex-col items-center justify-center p-2.5 text-xs text-darkslategray-200">
                {filterRowItems.map((item, index) => (
                    <FilterModalRow key={index} FilteredData={item} />
                ))}
            </div>

            {/*FOOTER*/}
            <ModuleButtonGroup
                middleButtons={middleButtons}
            />
        </TitleModalWrapper>
    );
}

export default GamesFilter;
