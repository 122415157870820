import React from "react";
import ComingSoonOverlay from "./placeholder/ComingSoonOverlay";

const AssignerRefAlignPay = () => {
  return (
    <div className="relative self-stretch flex-1 flex flex-col items-start justify-start">
      <ComingSoonOverlay />
      <div className="self-stretch rounded-t-3xs rounded-b-none bg-darkslategray-500 flex flex-row items-center justify-between py-[0.63rem] px-[1.25rem] border-[1px] border-solid border-lightgray-100">
        <div className="relative font-medium">RefAlign Pay</div>
        <div className="relative text-[0.63rem] italic">Updated 9:43PM</div>
      </div>
      <div className="self-stretch flex-1 rounded-t-none rounded-b-3xs flex flex-col items-start justify-start p-[0.94rem] gap-[0.69rem] text-[0.88rem] text-darkslategray-200 border-r-[1px] border-solid border-lightgray-100 border-b-[1px] border-l-[1px]"></div>
    </div>
  );
};

export default AssignerRefAlignPay;
