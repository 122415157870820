import React from 'react';

// Enum for button themes
const ButtonThemes = {
    DARK: { bg: "bg-darkslategray-100", text: "text-white" },
    SALMON: { bg: "bg-salmon-200", text: "text-white" },
    GOOGLE: {
        bg: 'bg-white',
        text: 'text-darkslategray-100',
        border: 'border-[1px] border-solid border-lightgray'
    }
};

const ButtonState = {
    ACTIVE: 'ACTIVE',
    LOADING: 'LOADING',
    DISABLED: 'DISABLED',
};

const ModalButton = ({
                         onClick,
                         downloadUrl,
                         text,
                         theme = 'DARK',
                         state = ButtonState.ACTIVE,
                         width,
                         icon,
                     }) => {
    // Destructure for cleaner access to theme properties.
    const { bg, text: textColor, border } = ButtonThemes[theme] || ButtonThemes.DARK;

    // Simplify class building with a base set and conditional additions.
    let baseClasses = `${bg} ${textColor} ${border} rounded-3xs flex flex-row items-center justify-center py-[5px] px-[15px] gap-[0.63rem] transition-transform duration-300 whitespace-nowrap`;
    let stateClasses = '';

    switch (state) {
        case ButtonState.ACTIVE:
            stateClasses = 'cursor-pointer';
            break;
        case ButtonState.LOADING:
            stateClasses = 'cursor-wait opacity-75';
            break;
        case ButtonState.DISABLED:
            stateClasses = 'opacity-50 cursor-not-allowed';
            break;
        default:
            stateClasses = 'cursor-pointer';
    }

    // Combine base classes with state-dependent classes.
    const buttonClasses = `${baseClasses} ${stateClasses}`;

    const customStyle = { textDecoration: 'none', ...(width && { width }) };

    if (state === ButtonState.LOADING) {
        return (
            <div className={`${buttonClasses} self-stretch rounded-2xl bg-darkslategray-600 flex flex-row items-center justify-start py-[0rem] pr-[0rem] pl-[0.31rem] gap-[0.25rem]`} style={customStyle}>
                <div className="flex-1 rounded-xl bg-lightblue-200 flex flex-row items-center justify-center py-[0.31rem] px-[0.63rem] gap-[0.44rem] text-center text-white">
                    <l-dot-pulse size="43" speed="1.3" color="#2d556c"></l-dot-pulse>
                </div>
            </div>
        );
    }

    const content = (
        <>
            {icon && <img className="w-[1.25rem] h-[1.25rem] overflow-hidden shrink-0" alt="" src={icon} />}
            <div>{text}</div>
        </>
    );

    const handleButtonClick = (e) => {
        if (state !== ButtonState.ACTIVE) {
            e.preventDefault();
            return;
        }
        onClick?.();
    };

    if (downloadUrl && state === ButtonState.ACTIVE) {
        return (
            <a href={downloadUrl} download className={buttonClasses + ' min-w-[100px]'} style={customStyle} onClick={handleButtonClick}>
                {content}
            </a>
        );
    }

    return (
        <div className={buttonClasses + (downloadUrl ? ' min-w-[100px]' : '')} onClick={handleButtonClick} style={customStyle}>
            {content}
        </div>
    );
};



export default ModalButton;