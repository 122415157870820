import Header from "../Global/Header";
import RefereeCalendarComp from "../../components/RefereeCalendarComp";

const RefereeCalendar = () => {
  return (
    <div className="bg-white">
      <Header />
      <RefereeCalendarComp />
    </div>
  );
};

export default RefereeCalendar;
