import React from "react";

const OrgStatsBar = ({ name, data }) => {

    const getStyles = (dataValue) => {
        const percentage = parseInt(dataValue);
        if (percentage < 25) {
            return "bg-red-200 text-red-100"; // Adjust the text color if needed
        } else if (percentage >= 25 && percentage <= 50) {
            return "bg-peachpuff text-sienna"; // Adjust the text color if needed
        } else {
            return "bg-limegreen-200 text-limegreen-100"; // Adjust the text color if needed
        }
    };

    const classNames = getStyles(data);


    return (
        <div
            className={`self-stretch rounded-3xs flex flex-row items-center justify-start py-[0.44rem] px-[0.63rem] gap-[1.56rem] ${classNames}`}>
            <div className="flex-1 relative font-semibold">{name}</div>
            <div className="relative font-semibold">{data}%</div>
    </div>)
};
export default OrgStatsBar;