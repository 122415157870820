import React from "react";
import AssignGameRefereeRow from "./AssignGameRefereeRow";

const GameRowCard = ({ game, openGameAssignModal }) => {
    const refereePositions = game.Positions || {};

  return (
    <div className="self-stretch rounded-6xs flex flex-row items-center justify-between py-[0.63rem] px-[1.25rem] border-[1px] border-solid border-lightgray-100">
      <div className="flex flex-row items-center justify-start gap-[0.13rem] text-left">
        <div className="flex flex-col items-center justify-center gap-[0.13rem]">
          <div className="flex flex-row items-center justify-center gap-[0.25rem]">
          <div className="flex flex-row items-center justify-start gap-[.5rem]">
            <img
              className="relative w-[1rem] h-[1rem]"
              alt=""
              src="/timer.svg"
            />
            <div className="flex flex-col items-center justify-center gap-[.25rem]">
              <div className="relative">
                {new Date(game.StartTime).toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                })}
              </div>
              <div className="relative">
                {new Date(game.StartTime).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </div>
            </div>
          </div>
          </div>
          <div className="flex flex-row items-center justify-center">
          </div>
        </div>
      </div>
      <div className="rounded-3xs flex flex-row items-center justify-start p-[0.31rem] text-[0.75rem] ">
        <div className="relative leading-[137.3%] flex items-center w-[7rem] shrink-0">
          <span className="[line-break:anywhere] w-full">
            <p className="m-0">{game.Address}</p>
            <p className="m-0">{game.FieldName}</p>
          </span>
        </div>
      </div>
      <div className="relative text-[0.75rem] leading-[137.3%]">
        <p className="m-0">
          {game.Gender} {game.AgeGroup}
        </p>
        <p className="m-0">{game.League}</p>
      </div>
      <div className="self-stretch flex flex-col gap-[0.31rem]">
          {Object.entries(refereePositions).map(([, ref]) => (
              <AssignGameRefereeRow
                  key={ref.PID}
                  game={game}
                  refData={ref}
                  openGameAssignModal={openGameAssignModal}
                  positionID={ref.PID}
              />
          ))}
      </div>
    </div>
  );
};

export default GameRowCard;
