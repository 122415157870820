const refereeLinks = [
  { to: "/referee", value: 0, name: "Overview", img: "/category.svg" },
  { to: "/referee/calendar", value: 1, name: "Calendar", img: "/calendar.svg" },
  { to: "/referee/games", value: 2, name: "My Games", img: "/soccerballicon.svg" },
  { to: "/referee/pay", value: 3, name: "Pay", img: "/moneys.svg" },
  { to: "/referee/findgames", value: 4, name: "Find Games", img: "/searchnormal.svg" },
];

const assignerLinks = [
  { to: "/assigner", value: 0, name: "Overview", img: "/category.svg" },
  //{ to: "/assigner/assignercalendar", value: 2, name: "Calendar", img: "/calendar.svg" },
  { to: "/assigner/assigngames", value: 3, name: "Assign", img: "/soccerballicon.svg" },
  { to: "/assigner/referees", value: 4, name: "Referees", img: "/flagicon.svg" },
  { to: "/assigner/pay", value: 5, name: "Pay", img: "/moneys.svg" },
];

export { refereeLinks, assignerLinks };
