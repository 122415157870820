import { useState } from "react";
import TitleModalWrapper from "./TitleModalWrapper";

const ReportGameModal = ({
  isOpen,
  onClose,
  handleButtonClick,
  reportGameData,
  reportGameReferees,
}) => {
  const [homeTeamScore, setHomeTeamScore] = useState(0);
  const [awayTeamScore, setAwayTeamScore] = useState(0);
  const [notes, setNotes] = useState();
  const [refereeStatuses, setRefereeStatuses] = useState({});

  const handleRefereeStatusChange = (uid, status) => {
    setRefereeStatuses((prevStatuses) => ({ ...prevStatuses, [uid]: status }));
  };

  const handleFormSubmit = () => {
    const GameReport = {
      homeTeamScore,
      awayTeamScore,
      notes,
      refereeStatuses,
    };

    const data = {
      PID: reportGameData.refData.PID,
      UID: reportGameData.refData.UID,
      GameReport,
      Status: "Referee_Reported",
    };

    handleButtonClick.handleSubmitReportGameModal(data);
    setHomeTeamScore(0);
    setAwayTeamScore(0);
    setNotes("");
  };

  return (
    <TitleModalWrapper isOpen={isOpen} onClose={onClose} title={"Game Report"}>
      <div className="text-center text-sm text-darkslategray-200 font-outfit">
        <div className="self-stretch flex flex-col md:flex-row p-2.5 gap-[20px]">
          <div className="self-stretch flex flex-col items-center p-2.5 gap-[20px]">
            {reportGameReferees &&
              reportGameReferees.map((referee) => (
                <PositionRow
                  key={referee.UID}
                  referee={referee}
                  onStatusChange={handleRefereeStatusChange}
                />
              ))}
          </div>
          <div className="flex flex-col justify-center gap-[2rem]">
            <div className="flex flex-row items-center justify-between py-[5px] px-5 gap-[10px]">
              <div className="self-stretch flex-1 flex flex-row items-center justify-between">
                <div className="flex-1 relative">Home Team Score</div>
                <img
                  className="relative w-1 h-1"
                  alt=""
                  src="/RequiredIcon.png"
                />
              </div>
              <div className="flex-1 flex flex-row items-end justify-center">
                <input
                  type="number"
                  className="rounded-3xs text-xs border-[1px] border-solid border-lightgray-100 cursor-pointer"
                  value={homeTeamScore}
                  onChange={(e) => setHomeTeamScore(Number(e.target.value))}
                />
              </div>
            </div>
            <div className="flex flex-row items-center justify-between py-[5px] px-5  gap-[10px]">
              <div className="self-stretch flex-1 flex flex-row items-center justify-between">
                <div className="flex-1 relative">Away Team Score</div>
                <img
                  className="relative w-1 h-1"
                  alt=""
                  src="/RequiredIcon.png"
                />
              </div>
              <div className="flex-1 flex flex-row items-end justify-center">
                <input
                  type="number"
                  className="rounded-3xs text-xs border-[1px] border-solid border-lightgray-100 cursor-pointer"
                  value={awayTeamScore}
                  onChange={(e) => setAwayTeamScore(Number(e.target.value))}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start p-2.5 gap-[20px]">
          <div className="relative flex items-center justify-center w-16 h-16 shrink-0">
            Notes:
          </div>
          <textarea
            className="rounded-3xs flex-1 border-[1px] border-solid border-lightgray-100 cursor-pointer placeholder-lightgray-100 "
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Enter notes here..."
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between py-0 px-[30px] pt-2	text-sm text-khaki-200">
          <div className="flex-1 relative h-3 overflow-hidden" />
          <div className="self-stretch rounded-3xs bg-darkslategray-100 overflow-hidden flex flex-row items-center justify-start py-[5px] px-[20px] cursor-pointer">
            <div
              className="items-center justify-center"
              onClick={handleFormSubmit}
            >
              Submit Report
            </div>
          </div>
        </div>
      </div>
    </TitleModalWrapper>
  );
};

export default ReportGameModal;

const PositionRow = ({ referee, onStatusChange }) => {
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    onStatusChange(referee.UID, e.target.value);
  };

  return (
    <div className="self-stretch bg-gray-600 box-border gap-[1rem] flex flex-row py-[5px] px-5 text-sm">
      <div className="flex-1 flex items-center flex-row relative gap-[5px]">
        {referee.User && referee.User.Name ? referee.User.Name : referee.Name}
        <img className="relative w-1 h-1" alt="" src="/RequiredIcon.png" />
      </div>
      <div className="flex flex-col items-center">
        <select
          className="rounded-3xs text-xs border-[1px] border-solid border-lightgray-100 cursor-pointer"
          value={selectedStatus}
          onChange={handleStatusChange}
        >
          <option value="" disabled hidden>
            Select
          </option>
          <option value="On Time">On-Time</option>
          <option value="Late">Late</option>
          <option value="No Show">No Show</option>
        </select>
      </div>
    </div>
  );
};
