import React from "react";
import { useAppContext } from "../../context/MainContext/AppContext";
import { Link } from "react-router-dom";

const HeaderLink = ({ to, value, name, img }) => {
  const { setIsActiveTab } = useAppContext();

  const changeNav = (val) => {
    setIsActiveTab(val);
  };

  const activeTab = +localStorage.getItem("active_tab");

  return (
    <Link
      to={to}
      className={
        activeTab === value
          ? "flex items-center gap-x-2 cursor-pointer [border:none] py-[0.44rem] px-[0.63rem] bg-[transparent] rounded-3xs w-[9.38rem] overflow-hidden shrink-0 flex flex-row items-start justify-center box-border bg-midblue-200  active:bg-midblue-300 active:scale-95"
          : "flex items-center gap-x-2 cursor-pointer [border:none] py-[0.44rem] px-[0.63rem] bg-[transparent] rounded-3xs w-[9.38rem] overflow-hidden shrink-0 flex flex-row items-start justify-center box-border hover:bg-midblue-100 active:bg-midblue-300 active:scale-95"
      }
      onClick={() => {
        changeNav(value);
      }}
    >
      <img className="relative w-[1rem] h-[1rem]" alt="" src={img} />
      <div className="relative text-[1rem] font-outfit text-darkslategray-100 text-center">{name}</div>
    </Link>
  );
};

export default HeaderLink;
