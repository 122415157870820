import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";

import MenuIcon from "@mui/icons-material/Menu";
import { useAppContext } from "../context/MainContext/AppContext";
import { refereeLinks, assignerLinks } from "../utils/LinkData";
import HeaderLink from "../pages/Global/HeaderLink";

export default function Sidebar() {
  const { role } = useAppContext();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ left: open });
  };

  const list = () => (
    <Box
      sx={{ width: 180 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {role === "Assigner"
          ? assignerLinks.map((link, index) => (
              <ListItem key={link.name} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {<HeaderLink key={index} {...link} />}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))
          : refereeLinks.map((link, index) => (
              <ListItem key={link.name} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {<HeaderLink key={index} {...link} />}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)} style={{ color: "#2D556C" }}>
        <MenuIcon />
      </Button>
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}
