const RecentPaymentsRow = () => {
  return (
    <div className="self-stretch bg-gray-600 flex flex-row items-center justify-start p-[1.25rem] text-center text-[0.88rem] text-darkslategray-200 font-outfit border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
      <div className="self-stretch flex-1 relative flex items-center justify-center">9/08/2023</div>
      <div className="self-stretch flex-1 relative flex items-center justify-center">9/08/2023</div>
      <div className="self-stretch flex-1 relative flex items-center justify-center">-</div>
      <div className="self-stretch flex-1 relative flex items-center justify-center">Checking Account: xxxx2341</div>
      <div className="self-stretch flex-1 relative flex items-center justify-center">-$362.45</div>
      <div className="self-stretch flex-1 relative flex items-center justify-center">$0</div>
      <div className="self-stretch flex-1 relative flex items-center justify-center">$0</div>
      <div className="self-stretch flex-1 relative flex items-center justify-center">235123</div>
      <div className="self-stretch flex-1 flex flex-col items-center justify-between text-[0.63rem] text-orange-100">
        <div className="rounded-xl bg-orange-200 flex flex-row items-start justify-start py-[0.31rem] px-[0.63rem]">
          <div className="relative">Settled</div>
        </div>
      </div>
    </div>
  );
};

export default RecentPaymentsRow;
