const formatDateString = (inputString) => {
  const val = inputString.split(" ");
  const timeComponents = val[1].split(":");
  let hours = parseInt(timeComponents[0], 10);
  const minutes = timeComponents[1];

  // Convert military time to standard time
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Handle midnight (0) as 12 AM

  const formattedTime = `${hours}:${minutes} ${ampm}`;

  return [formattedTime, val[0]];
};

export default formatDateString;
