import React from "react";
import TableHeaderButton from "./TableHeaderButton";

const TableHeader = ({ title, buttons = [] }) => {
    return (
        <div
            className="self-stretch rounded-t-3xs bg-darkslategray-500 flex flex-row items-center justify-start py-[0.63rem] px-[1.25rem] gap-[1.69rem] border-[1px] border-solid border-lightgray-100">
            <div className="font-medium text-[1.25rem] text-darkslategray-100">{title}</div>
            {/* Spacer div to push the following elements to the right */}
            <div className="flex-grow"></div>
            <div className="flex flex-row items-center justify-end gap-[0.63rem] text-[0.88rem] text-slategray-200">
                {buttons.map((button, index) => (
                    <TableHeaderButton
                        key={index}
                        label={button.label}
                        iconSrc={button.iconSrc}
                        onClick={button.onClick}
                    />
                ))}
            </div>
        </div>
    );
};

export default TableHeader;
