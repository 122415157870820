import React from "react";
import Header from "../Global/Header";
import ComingSoonOverlay from "../../components/placeholder/ComingSoonOverlay";

const RefereeFindGames = () => {
  return (
    <>
      <Header />

      <div className="relative bg-white w-full h-[52.63rem] overflow-hidden flex flex-col items-center justify-start text-center">
        <div className="w-full flex-1 flex flex-row items-start justify-center pt-[1.25rem] px-[0.63rem] pb-[0.63rem] box-border max-w-[93.75rem] text-left text-darkslategray-200">
          <div className="flex flex-col items-center justify-start">
            <div className="rounded-[25px] box-border w-[20.19rem] overflow-hidden flex flex-col items-center justify-start pt-[0.63rem] px-[2.56rem] pb-[2.13rem] gap-[0.63rem] border-[1px] border-solid border-darkslategray-200">
              <div className="w-[19.38rem] overflow-hidden flex flex-row items-center justify-start py-[0rem] px-[0.63rem] box-border text-[1.38rem]">
                <b className="flex-1 relative flex items-center h-[2.25rem]">Find games near you</b>
              </div>
              <div className="self-stretch rounded-xl overflow-hidden flex flex-row items-center justify-between py-[0.63rem] px-[1.88rem] border-[1px] border-solid border-lightgray">
                <div className="relative w-[8.13rem] h-[1.56rem]">
                  <div className="justify-start top-[0rem] left-[0rem] flex items-center w-[8.13rem] h-[1.56rem]">
                    Gender
                  </div>
                </div>
                <img
                  className="relative w-[1.5rem] h-[1.5rem] border-darkslategray-200"
                  alt=""
                  src="/arrowdownblack.svg"
                />
              </div>
              <div className="self-stretch rounded-xl overflow-hidden flex flex-row items-center justify-between py-[0.63rem] px-[1.88rem] border-[1px] border-solid border-lightgray">
                <div className="relative w-[8.13rem] h-[1.56rem]">
                  <div className="top-[0rem] left-[0rem] flex items-center w-[8.13rem] h-[1.56rem]">Organization</div>
                </div>
                <img
                  className="relative w-[1.5rem] h-[1.5rem] border-darkslategray-200"
                  alt=""
                  src="/arrowdownblack.svg"
                />{" "}
              </div>
              <div className="self-stretch rounded-xl overflow-hidden flex flex-row items-center justify-between py-[0.63rem] px-[1.88rem] border-[1px] border-solid border-lightgray">
                <div className="relative w-[8.13rem] h-[1.56rem]">
                  <div className="top-[0rem] left-[0rem] flex items-center w-[8.13rem] h-[1.56rem]">Age Group</div>
                </div>
                <img
                  className="relative w-[1.5rem] h-[1.5rem] border-darkslategray-200"
                  alt=""
                  src="/arrowdownblack.svg"
                />{" "}
              </div>
              <div className="self-stretch rounded-xl overflow-hidden flex flex-row items-center justify-between py-[0.63rem] px-[1.88rem] border-[1px] border-solid border-lightgray">
                <div className="relative w-[8.13rem] h-[1.56rem]">
                  <div className="top-[0rem] left-[0rem] flex items-center w-[8.13rem] h-[1.56rem]">Ref type</div>
                </div>
                <img
                  className="relative w-[1.5rem] h-[1.5rem] border-darkslategray-200"
                  alt=""
                  src="/arrowdownblack.svg"
                />{" "}
              </div>
              <div className="self-stretch rounded-xl overflow-hidden flex flex-row items-center justify-between py-[0.63rem] px-[1.88rem] border-[1px] border-solid border-lightgray">
                <div className="relative w-[8.13rem] h-[1.56rem]">
                  <div className="top-[0rem] left-[0rem] flex items-center w-[8.13rem] h-[1.56rem]">Half Duration</div>
                </div>
                <img
                  className="relative w-[1.5rem] h-[1.5rem] border-darkslategray-200"
                  alt=""
                  src="/arrowdownblack.svg"
                />{" "}
              </div>
              <div className="self-stretch rounded-xl box-border h-[4.81rem] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[0.63rem] px-[1.88rem] border-[1px] border-solid border-lightgray">
                <div className="relative w-[8.13rem] h-[1.56rem]">
                  <div className="top-[0rem] left-[0rem] flex items-center w-[8.13rem] h-[1.56rem]">{`Pay Range `}</div>
                </div>
              </div>
              <div className="self-stretch rounded-xl box-border h-[4.81rem] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[0.63rem] px-[1.88rem] border-[1px] border-solid border-lightgray">
                <div className="relative w-[8.13rem] h-[1.56rem]">
                  <div className="top-[0rem] left-[0rem] flex items-center w-[8.13rem] h-[1.56rem]">Location</div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden flex flex-col items-center justify-start py-[0rem] px-[0.31rem]">
            <img className="relative w-[46.38rem] h-[35rem] object-cover" alt="" src="/FindGamesPhoto.png" />
          </div>
        </div>
        <ComingSoonOverlay noRoundedBorder={true} />
      </div>
    </>
  );
};

export default RefereeFindGames;
