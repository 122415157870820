import React, { useEffect, useRef } from "react";
import { useAppContext } from "../../context/MainContext/AppContext";
import useRouter from "../../hooks/router";
import { Role } from "../../context/MainContext/Constants";

const ProfileDropdown = ({ setShowProfileModal }) => {
  const { role, setRole, showSwapRoles, logout } = useAppContext();
  const { handleNavigation } = useRouter();
  const dropdownRef = useRef(null);

  const swap = () => {
    setShowProfileModal(false);
    if (showSwapRoles()) {
      let newRole;
      // Determine the new role based on the current role
      if (role === Role.Referee) {
        newRole = Role.Assigner;
      } else if (role === Role.Assigner) {
        newRole = Role.Referee;
      }
      // Set the new role
      setRole(newRole);

      // Navigate based on the new role
      if (newRole === Role.Referee) {
        handleNavigation("/referee");
      } else if (newRole === Role.Assigner) {
        handleNavigation("/assigner");
      }
    }
  };

  const handleLogout = () => {
    handleNavigation("/");
    logout();
  };

  /*
  const handleSettingsPage = () => {
    handleNavigation("/referee/settings")
  }
   */

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowProfileModal(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /*
   * Settings code:
   *  <div className="self-stretch h-[41px] shrink-0 flex flex-row items-start justify-start rounded-3xs hover:bg-darkslategray-800">
        <div className="self-stretch flex-1 relative flex items-center justify-center cursor-pointer">Profile</div>
      </div>
      <div className="self-stretch h-[41px] shrink-0 flex flex-row items-start justify-start rounded-3xs hover:bg-darkslategray-800">
        <div className="self-stretch flex-1 relative flex items-center justify-center cursor-pointer"  onClick={handleSettingsPage}>Settings</div>
      </div>
   */

  return (
    <div
      className="absolute top-16 rounded-3xs bg-darkslategray-700 w-[140px] flex flex-col items-start justify-start p-2.5 box-border gap-[10px] text-center text-xl text-khaki-200 font-outfit z-50"
      ref={dropdownRef}
    >
      {showSwapRoles() && (
        <div className="self-stretch h-[41px] shrink-0 flex flex-row items-start justify-start rounded-3xs hover:bg-darkslategray-800">
          <div
            className="self-stretch flex-1 relative flex items-center justify-center cursor-pointer"
            onClick={swap}
          >
            Swap role
          </div>
        </div>
      )}
      <div className="self-stretch h-[41px] shrink-0 flex flex-row items-start justify-start rounded-3xs hover:bg-darkslategray-800">
        <div
          className="self-stretch flex-1 relative flex items-center justify-center cursor-pointer"
          onClick={() => {
            handleLogout();
          }}
        >
          Logout
        </div>
      </div>
    </div>
  );
};

export default ProfileDropdown;
