import React from "react";
import GameRowCard from "./GameRowCard";
import TableHeader from "./table/TableHeader";

const RefereeRequests = ({ apidata }) => {
  const requestsArray = apidata ? Object.values(apidata).slice(0, 3) : [];

  const isDataEmpty = requestsArray.length === 0 || Object.keys(apidata).length === 0;

  return (
    <div className="self-stretch flex-1 flex flex-col items-start justify-start">
        <TableHeader
            title={"Referee Requests"}
        />
      <div className="self-stretch rounded-t-none rounded-b-3xs flex flex-col items-start justify-start p-[0.94rem] gap-[0.69rem] text-center text-[0.69rem] border-r-[1px] border-solid border-lightgray-100 border-b-[1px] border-l-[1px]">
        {isDataEmpty ? (
          <div className="text-[.8rem]">No referees currently requesting games</div>
        ) : (
          requestsArray.map((data, index) => <GameRowCard key={index} apidata={data} />)
        )}
      </div>
    </div>
  );
};

export default RefereeRequests;
