import React from "react";
import RefereeOverviewGameRow from "./RefereeOverviewGameRow";
import TableHeader from "./table/TableHeader";

const RefereeUpcomingGames = ({ upcomingGames, UID, handleButtonClick }) => {
  const upcomingGamesArray = upcomingGames ? Object.values(upcomingGames).slice(0, 4) : null;

  return (
    <div className="self-stretch flex-1 flex flex-col items-start justify-start text-left text-[1.25rem] text-darkslategray-100 font-outfit">
        <TableHeader title={"Upcoming Games"} />
      <div className="self-stretch flex rounded-t-none rounded-b-3xs flex flex-col items-center justify-between p-[0.94rem] text-[0.69rem] gap-[0.56rem] border-[1px] border-solid border-lightgray-100">
        {upcomingGamesArray === null ? (
          <div className="flex flex-1 items-center justify-center">
          <l-dot-pulse size="43" speed="1.3" color="#2d556c"></l-dot-pulse>
        </div>
        ) : (
          upcomingGamesArray.map((game, index) => (
            <RefereeOverviewGameRow
              key={index}
              UID={UID}
              handleButtonClick={handleButtonClick}
              apidata={game}
              bgColor={"bg-royalblue-100"}
              textcolor={"text-white"} 
            />
          ))
        )}
      </div>
    </div>
  );
};

export default RefereeUpcomingGames;
