import React, {useState} from 'react';

const RowsPerPageSelector = ({rowCount, setRowCount}) => {
    const [value, setValue] = useState(rowCount);

    const options = [5, 10, 25]

    const handleChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setValue(newValue);
        setRowCount(newValue);
    };

    return (
        <div
            className="flex flex-row justify-center items-center gap[1rem] text-[1rem] text-darkslategray-100 font-outfit">
            <div className="mr-4 flex flex-row">Rows per page:</div>
            <select
                id="rows-per-page"
                className="p-1 items-center justify-center text-center border-[1px] border-lightgray-100 bg-darkslategray-500 rounded-3xs"
                value={value}
                onChange={handleChange}
            >
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default RowsPerPageSelector;
