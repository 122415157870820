import React, {useEffect, useRef} from "react";

const HeaderDropdown = ({options, selectedOption, setSelectedOption}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const dropdownRef = useRef(null); // Create a ref

    const getOptionName = (payload) => {
        const option = options.find((opt) => opt.payload === payload);
        return option ? option.name : "";
    };

    useEffect(() => {
        if (!selectedOption && options.length > 0) {
            setSelectedOption(options[0].payload);
        }
    }, [selectedOption, options]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isOpen]);

    return (
        <div className="relative" ref={dropdownRef}>
            <div
                className="flex-1 rounded-3xs bg-white box-border w-[135px] overflow-hidden flex flex-row items-center justify-between py-[7px] px-2.5 border-[1px] border-solid border-lightgray-100 cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="flex flex-row items-center justify-start text-darkslategray-100 opacity-[0.8]">
                    <div className="relative">{getOptionName(selectedOption)}</div>
                </div>
                <img className="relative w-[15px] h-[15px]  text-darkslategray-100" alt="" src="/arrowdownblack.svg"/>
            </div>
            {isOpen && (
                <div
                    className="absolute top-full left-0 mt-1 w-[135px] rounded-3xs bg-white shadow text-darkslategray-100 border border-lightgray-100 z-50 max-h-[20rem] overflow-y-auto">
                    {options.map((option) => (
                        <div
                            key={option.payload}
                            className="py-[7px] px-2.5 hover:bg-lightgray-200 cursor-pointer"
                            onClick={() => {
                                setSelectedOption(option.payload);
                                setIsOpen(false);
                            }}
                        >
                            {option.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default HeaderDropdown;
