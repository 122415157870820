import React, { useReducer, useContext } from "react";
import reducer from "./Reducer";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail
} from "firebase/auth";
import {
  LOGOUT,
  SET_ROLE,
  SET_FIREBASE_USER,
  SET_GAMES_BY_UID,
  TOGGLE_GAMES_FILTER_MODAL,
  SET_ORGANIZATION_DATA,
  SET_DATABASE_USER,
  SET_CURRENT_ORGANIZATION,
  SET_GAMES_BY_OID, ALTER_POSITION_ASSIGNER, ALTER_POSITION_REFEREE, SET_FILTER_SETTINGS, RESET_FAILED,
} from "../actions";
import {queryEndpointInternal} from "./Endpoints";
import { getFromLocalStorage, StorageKeys } from "./LocalStorage";
import { Role } from "./Constants";

const currentOrganization = getFromLocalStorage(StorageKeys.CURRENT_ORG);

const initialState = {
  header: 0,
  firebase_user: getFromLocalStorage(StorageKeys.FIREBASE_USER),
  database_user: getFromLocalStorage(StorageKeys.DATABASE_USER),
  role: getFromLocalStorage(StorageKeys.ROLE),
  access_token: getFromLocalStorage(StorageKeys.ACCESS_TOKEN),
  isFilteredDataShown: false,
  isLoading: false,
  gamesFilterModal: false,
  currentOrganization: currentOrganization || null,
  OrganizationData: null,
  AssignerGameData: null,
  RefereeGameData: null,
  FilterSettings: []
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getDatabaseUser = async () => {
    // Save database user
    const maxAttempts = 20;
    const delayInSeconds = 200; // 200 milliseconds, which is 0.2 seconds
    let database_user;
    for (let attempts = 0; attempts < maxAttempts; attempts++) {
      try {
        database_user = await queryEndpoint("getUser");
        dispatch({ type: SET_DATABASE_USER, payload: database_user });
        break; // Exit the loop on successful response
      } catch (error) {
        if (attempts === maxAttempts - 1) {
          console.log("Maximum attempts reached, unable to fetch user data.");
          return; // Or throw new Error("Timeout reached")
        }
        await delay(delayInSeconds);
      }
    }

    // Set role and organization
    let highestPermissionRole = Role.Referee;
    let highestPermissionOID = "";

    // Iterate over the Organizations array to find the highest permission role and its OID
    database_user.Organizations.forEach((org) => {
      if (
        Object.keys(Role).indexOf(org.PermissionLevel) >
        Object.keys(Role).indexOf(highestPermissionRole)
      ) {
        highestPermissionRole = org.PermissionLevel;
        highestPermissionOID = org.OID; // Set the OID for the highest permission
      }
    });

    // Set role to Assigner if it was Owner
    if (highestPermissionRole === Role.Owner) {
      highestPermissionRole = Role.Assigner;
    }

    // Set the current organization and role
    setRole(highestPermissionRole);
    if (highestPermissionOID) {
      await setCurrentOrganization(highestPermissionOID);
    }
  };

  const handleRegularLogin = async (email, password) => {
    const auth = getAuth();

    // Sign in the user
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    dispatch({ type: SET_FIREBASE_USER, payload: user });

    // Perform additional actions after login if necessary
    await getDatabaseUser();
  };

  const handleRegularRegister = async (name, email, password) => {
    const auth = getAuth();

    // Save firebase user
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    dispatch({ type: SET_FIREBASE_USER, payload: user });

    await getDatabaseUser();
    await alterUser({ Name: name });
  };

  const handleGoogleRegister = async () => {
    const auth = getAuth();

    // Save firebase user
    const googleProvider = new GoogleAuthProvider();
    const userCredential = await signInWithPopup(auth, googleProvider);
    const user = userCredential.user;
    dispatch({ type: SET_FIREBASE_USER, payload: user });

    await getDatabaseUser();
  };

  const handlePasswordReset = async (email) => {
    const auth = getAuth();
    dispatch({ type: RESET_FAILED, payload: false });
    try {
      await sendPasswordResetEmail(auth, email);
      console.log("Password reset email sent successfully");
    } catch (error) {
      dispatch({ type: RESET_FAILED, payload: true });
      console.log("Password reset failed");
    }
  };

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const setRole = (role) => {
    dispatch({ type: SET_ROLE, payload: role });
  };

  const showSwapRoles = () => {
    // Check if user has at least Role.Assigner in any organization
    for (let org of state.database_user.Organizations) {
      if (
        Object.keys(Role).indexOf(org.PermissionLevel) >=
        Object.keys(Role).indexOf(Role.Assigner)
      ) {
        return true; // User has Role.Assigner or higher in an organization
      }
    }
    return false; // User does not have the required role in any organization
  };

  const setCurrentOrganization = async (oid) => {
    dispatch({ type: SET_CURRENT_ORGANIZATION, payload: oid });

    // Update all cached data
    switch (state.role) {
      case Role.Assigner:
        dispatch({ type: SET_ORGANIZATION_DATA, payload: null });
        dispatch({ type: SET_GAMES_BY_OID, payload: null });
        break;
    }
  };

  const logout = () => {
    dispatch({ type: LOGOUT });
  };

/*  const isOwnerCurrentOrg = () => {
    return state.currentOrganization
  }*/

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // API Endpoints
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const queryEndpoint = async (endpoint, payload = null) => {
    return await queryEndpointInternal(state, dispatch, logout,endpoint, payload);
  }

  const alterUser = async (requestData) => {
    await queryEndpoint("alterUser", requestData);
    await getDatabaseUser();
  };

  const getOrganizationData = async (currentOrganization = state.currentOrganization) => {
    const data = await queryEndpoint(
      "getOrganization",
      `OID=${currentOrganization}`
    );
    dispatch({ type: SET_ORGANIZATION_DATA, payload: data });
  };

  const getOrganizationGames = async (currentOrganization = state.currentOrganization) => {
    if (state.currentOrganization == null) {
      return;
    }
    const data = await queryEndpoint(
      "getGamesByOid",
      `OID=${currentOrganization}`
    );
    console.trace('Current stack trace:');
    dispatch({ type: SET_GAMES_BY_OID, payload: data });
  };

  const getGamesByUid = async () => {
    const data = await queryEndpoint("getGamesByUid");
    dispatch({ type: SET_GAMES_BY_UID, payload: data });
  };

  const getAvailableReferees = async (gid) => {
    // Start with all referees being available
    const availableReferees = state.OrganizationData && state.OrganizationData.Users
        ? JSON.parse(JSON.stringify(state.OrganizationData.Users))
        : [];

    // Get unavailable refs
    const unavailableReferees = await queryEndpoint(
        "getUnavailableReferees",
        `GID=${gid}`
    );

    // Return all refs - unavailable refs
    return availableReferees.filter(ar =>
        !unavailableReferees.includes(ar.UID)
    );
  };

  const alterGame = async (requestData) => {
    await queryEndpoint(
        "alterGame",
        requestData
    );
    await getOrganizationGames();
  };

  const alterPosition = async (requestData) => {
    await queryEndpoint(
        "alterPosition",
        requestData
    );

    // Purposefully don't wait for getOrganizationGames or getGamesByUid
    if (state.role === Role.Assigner) {
      dispatch({ type: ALTER_POSITION_ASSIGNER, payload: requestData });
      getOrganizationGames();
    } else if (state.role === Role.Referee) {
      dispatch({ type: ALTER_POSITION_REFEREE, payload: requestData });
      getGamesByUid();
    }
  };

  const inviteUsers = async (requestData) => {
    await queryEndpoint("inviteUsers", requestData);
    await getOrganizationData();
  };

  const alterPermission = async (requestData) => {
    await queryEndpoint("alterPermission", requestData);
    await getOrganizationData();
  };

  const revokePermission = async (requestData) => {
    await queryEndpoint(
        "revokePermission",
        requestData
    );
    await getOrganizationData();
  };

  const sendGamesToRef = async () => {
    await queryEndpoint(
        "sendGamesToRef",
        `OID=${state.currentOrganization}`
    );
    await getOrganizationGames();
  };

  const createGames = async (requestData) => {
    await queryEndpoint("createGames", requestData);
    await getOrganizationGames();
  };

  const createOrganization = async () => {
    await queryEndpoint(
        "createOrganization",
        `Name=TestOrg`
    );
    await getDatabaseUser();
  };

  const sendFeedback = async (requestData) => {
    await queryEndpoint("sendFeedback", requestData);
  };

  const deleteGame = async (requestData) => {
    await queryEndpoint("deleteGame", requestData);
    await getOrganizationGames();
  };

  const setIsActiveTab = (value) => {
    localStorage.setItem("active_tab", value);
  };

  const toggleGameFilterModal = () => {
    dispatch({
      type: TOGGLE_GAMES_FILTER_MODAL,
      payload: state.gamesFilterModal,
    });
  };

  const setFilterSettings = (filterSettings) => {
    dispatch({
      type: SET_FILTER_SETTINGS,
      payload: filterSettings,
    });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        setIsActiveTab,
        setRole,
        showSwapRoles,
        toggleGameFilterModal,
        inviteUsers,
        removeUser: revokePermission,
        getOrganizationData,
        getOrganizationGames,
        getAvailableReferees,
        alterGameInfo: alterGame,
        createGames,
        getGamesByUid,
        handleRegularLogin,
        handleRegularRegister,
        handleGoogleRegister,
        sendGamesToRef,
        createOrganization,
        modifyUser: alterPermission,
        alterPosition,
        setCurrentOrganization,
        setFilterSettings,
        logout,
        sendFeedback,
        deleteGame,
        handlePasswordReset,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
const useAppContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider, useAppContext, initialState };
