import React from "react";

const ComingSoonOverlay = ({ noRoundedBorder = false }) => {
  const roundedClass = !noRoundedBorder ? "rounded-xl rounded-t-3xs rounded-b-3xs" : "";

  return (
    <div className={`absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center ${roundedClass}`}>
      <span className="text-white text-xl md:text-3xl lg:text-4xl font-bold">Coming Soon...</span>
    </div>
  );
};

export default ComingSoonOverlay;
