import React, {useState} from "react";
import Header from "../Global/Header";
import GamesWithoutRefs from "../../components/GamesWithoutRefs";
import AssignerOrgStats from "../../components/AssignerOrgStats";
import AssignerRefAlignPay from "../../components/AssignerRefAlignPay";
import RefereeRequests from "../../components/RefereeRequests";
import { useAppContext } from "../../context/MainContext/AppContext";
import { filterOrgStats, filterGamesWithoutRefs } from "../../utils/filterGamesData";
import GameAssignModal from "../../components/Modals/GameAssignModal";


const AssignerDashboard = () => {
  const { AssignerGameData, alterPosition, getAvailableReferees } = useAppContext();
  const [isAssignRefereeModalOpen, setIsAssignRefereeModalOpen] = useState(false);
  const [availableReferees, setAvailableReferees] = useState([]);
  const [currentGameData, setCurrentGameData] = useState();

  const openGameAssignModal = async ({ game, positionID }) => {
    setAvailableReferees(await getAvailableReferees(game.GID));
    setCurrentGameData({ ...game, positionID });
    setIsAssignRefereeModalOpen(true);
  };

  const assignRefereeToGame = ({ referee, currentGameData }) => {
    alterPosition({
      PID: currentGameData.positionID,
      UID: referee.UID,
      Status: "Pending",
    });
    setIsAssignRefereeModalOpen(false);
  };

  let filteredOrgStats = [];
  let filteredGamesWithoutRefs = [];

  if (AssignerGameData) {
    filteredOrgStats = filterOrgStats(AssignerGameData);
    filteredGamesWithoutRefs = filterGamesWithoutRefs(AssignerGameData)
  }

  return (
    <div className="relative bg-white w-full h-auto overflow-hidden flex flex-col items-center justify-start text-center text-[1rem] text-khaki-200 font-outfit ">
      <Header />
      <div className="self-stretch flex flex-col items-center justify-start p-[1rem] sm:p-[1.25rem] gap-[1rem] sm:gap-[1.25rem] text-left text-[1rem] sm:text-[1.25rem] text-darkslategray-100">
        <div className="self-stretch flex flex-col xl:flex-row items-start justify-between gap-[1rem] xl:gap-[0.69rem]">
          <AssignerOrgStats apidata={filteredOrgStats} className="flex-shrink" />
          <GamesWithoutRefs apidata={filteredGamesWithoutRefs} openGameAssignModal={openGameAssignModal} className="flex-shrink" />

        </div>

        <div className="self-stretch flex flex-col xl:flex-row items-start justify-between gap-[1rem] xl:gap-[0.69rem]">
          <AssignerRefAlignPay className="flex-shrink" />
          <RefereeRequests className="flex-shrink" />
        </div>
      </div>
      <GameAssignModal
          isOpen={isAssignRefereeModalOpen}
          onClose={() => setIsAssignRefereeModalOpen(false)}
          assignRefereeToGame={assignRefereeToGame}
          availableReferees={availableReferees}
          currentGameData={currentGameData}
      />
    </div>
  );
};

export default AssignerDashboard;
