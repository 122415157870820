import React from "react";
import AssignGameRefereeRow from "./AssignGameRefereeRow";

const AssignGamesGameRow = ({ game, openGameAssignModal, openModifyGameModal }) => {
    const refereePositions = game.Positions || {};
    const blacklistedFields = new Set(["GID", "Positions", "RefereePattern"]); // Blacklisted fields

    // Filter out blacklisted fields and special fields that require custom rendering
    const standardFields = Object.entries(game).filter(([key]) => !blacklistedFields.has(key));

    return (
        <div className="self-stretch bg-gray-600 flex flex-row items-center justify-between py-[0.63rem] px-[1.25rem] border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
            <div className="cursor-pointer" onClick={() => openModifyGameModal(game)}>
                <img className="relative w-[1.25rem] h-[1.25rem]" alt="" src="/editiconblack.svg" />
            </div>
            {standardFields.map(([key, value]) => (
                <div key={key} className="self-stretch flex-1 flex items-center justify-center">
                    {typeof value === 'string' || typeof value === 'number' ? value : null}
                </div>
            ))}
            <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[0.31rem] text-[0.75rem] text-darkslategray-100">
                {Object.entries(refereePositions).map(([, ref]) => (
                    <AssignGameRefereeRow
                        key={ref.PID}
                        game={game}
                        refData={ref}
                        openGameAssignModal={openGameAssignModal}
                        positionID={ref.PID}
                    />
                ))}
            </div>
        </div>
    );
};

export default AssignGamesGameRow;
