import React, { useState, useEffect } from "react";
import ConfirmationModal from "./ConfirmationModal";
import {useAppContext} from "../../context/MainContext/AppContext";
import TitleModalWrapper from "./TitleModalWrapper";
import ModuleButtonGroup from "./ModalButtonsGroup";

const ModifyGameModal = ({ isOpen, onClose, currentGameData, handleModifyGame }) => {
  const [isDeleteGameModalOpen, setIsDeleteGameModalOpen] = useState(false);
  const [modifyGameModalFormData, setModifyGameModalFormData] = useState({});
  const [modifyGameData, setModifyGameData] = useState({});
  const {deleteGame} = useAppContext();

  useEffect(() => {
    if (isOpen && currentGameData) {
      const { ...filteredData } = currentGameData;
      delete filteredData.GID;
      delete filteredData.Positions;
      delete filteredData.RefereePattern;
      setModifyGameData(filteredData);
      setModifyGameModalFormData(filteredData);
    }
  }, [isOpen, currentGameData]);

  const handleInputChange = (e, field) => {
    setModifyGameModalFormData((prevData) => ({
      ...prevData,
      [field]: e.target.value,
    }));
  };

  const modifyGameSubmit = () => {
    // Create an object to hold the changes
    const changes = {};

    // Always include GID in the changes
    changes.GID = currentGameData.GID;

    // Compare each key in modifyGameModalFormData with currentGameData
    Object.keys(modifyGameModalFormData).forEach((key) => {
      if (modifyGameModalFormData[key] !== currentGameData[key]) {
        changes[key] = modifyGameModalFormData[key];
      }
    });

    // Check if there are changes other than just GID
    if (Object.keys(changes).length > 1) {
      handleModifyGame(changes); // Pass the changes to the handler
    }

    onClose();
    setModifyGameModalFormData({});
  };

  const deleteGameSubmit = (item) => {
    deleteGame(item);
    setIsDeleteGameModalOpen(false);
    onClose();
  }

  const middleButtons = [
    {
      onClick: modifyGameSubmit,
      text: "Modify Game",
      theme: "DARK",
    },
    {
      onClick: () => setIsDeleteGameModalOpen(true),
      text: "Delete Game",
      theme: "SALMON",
    },
  ];

  return (
    <TitleModalWrapper isOpen={isOpen} onClose={onClose} title={"Modify Game"}>
      {/*MAIN CONTENT*/}
      <div className="rounded-3xs flex flex-col items-start justify-start max-h-[700px] overflow-y-auto">
      <div className="self-stretch rounded-t-3xs rounded-b-none bg-darkslategray-600 flex flex-row items-center justify-start py-[15px] px-5 text-sm text-darkslategray-100 border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
          <div className="flex-1 relative font-semibold">Input</div>
          <div className="flex-1 relative font-semibold">Field</div>
        </div>
        {isOpen &&
          modifyGameData &&
          Object.entries(modifyGameData).map(([key, value]) => (
            <div
              key={key}
              className="bg-gray-600 box-border w-[369.5px] h-[38px] flex flex-row items-center justify-between py-[5px] px-5 border-[1px] border-solid border-darkslategray-400 text-sm"
            >
              <div className="self-stretch flex-1 flex flex-row items-center justify-start">
                <div className="flex-1 relative">{key}</div>
              </div>
              <div className="flex-1 h-5 flex flex-row items-end justify-end">
                <input
                  type="text"
                  placeholder={value}
                  value={modifyGameModalFormData[key] || ""}
                  onChange={(e) => handleInputChange(e, key)}
                  className="relative box-border w-64 h-px border-b-[1px] border-solid border-lightgray-100 focus:outline-none bg-transparent text-center text-sm text-darkslategray-200 font-outfit"
                />
              </div>
            </div>
          ))}
      </div>

      <ConfirmationModal
          isOpen={isDeleteGameModalOpen}
          onClose={() => setIsDeleteGameModalOpen(false)}
          item={currentGameData && currentGameData.GID ? { GID: currentGameData.GID } : {}}
          handleConfirm={deleteGameSubmit}
          message={"Are you sure you want to delete this game?"}
      />

      {/*FOOTER*/}
      <ModuleButtonGroup
          middleButtons={middleButtons}
      />

    </TitleModalWrapper>
  );
};

export default ModifyGameModal;
