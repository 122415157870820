// Base function for filtering games
const filterGamesByStatus = (gamesData, UID, status) => {
  // Return early if gamesData is not an array or is empty
  if (!Array.isArray(gamesData) || gamesData.length === 0) {
    return [];
  }

  return gamesData
      .filter(gameData =>
          Object.values(gameData.Positions).some(position =>
              position.UID === UID && position.Status === status)
      );
};

// Refactored functions using the base function
export const filterPendingGames = (gamesData, UID) => {
  return filterGamesByStatus(gamesData, UID, "SentToRef");
};

export const filterUpcomingGames = (gamesData, UID) => {
  return filterGamesByStatus(gamesData, UID, "Accepted");
};

export const filterReportGames = (gamesData, UID) => {
  return filterGamesByStatus(gamesData, UID, "Report");
};

export const filterGamesWithoutRefs = (gamesData) => {
  if (!gamesData || Object.keys(gamesData).length === 0) {
    return null;
  }

  const filteredGames = [];

  for (const gameId in gamesData) {
    if (filteredGames.length >= 3) {
      break; // Stop after finding 3 games
    }

    const game = gamesData[gameId];
    let includeGame = false;

    for (const positionId in game.Positions) {
      const position = game.Positions[positionId];
      if (!["Accepted", "SentToRef", "Report", "Completed", "Referee_Reported"].includes(position.Status)) {
        includeGame = true;
        break; // Include this game and stop checking other positions
      }
    }

    if (includeGame) {
      filteredGames.push(game);
    }
  }

  return filteredGames;
};


export const filterOrgStats = (gamesData) => {
  if (!gamesData || Object.keys(gamesData).length === 0) {
    return null;
  }

  const calculateStatsForDay = (games, day) => {
    let gameCount = 0;
    let totalPositions = 0;
    let acceptedPositions = 0;

    games.forEach((game) => {
      const gameDate = new Date(game.StartTime);
      if (gameDate.toDateString() === day.toDateString()) {
        gameCount++;
        Object.values(game.Positions).forEach((position) => {
          totalPositions++;
          if (position.Status === "Accepted") {
            acceptedPositions++;
          }
        });
      }
    });

    const staffedPercentage = totalPositions === 0 ? 0 : (acceptedPositions / totalPositions);
    return {
      gameCount,
      staffedPercentage,
      totalPositions,
      acceptedPositions,
    };
  };

  const calculateStatsForNextDays = (games, numberOfDays) => {
    const today = new Date();
    let totalGameCount = 0;
    let totalPositions = 0;
    let totalAcceptedPositions = 0;

    for (let i = 0; i < numberOfDays; i++) {
      const day = new Date(today);
      day.setDate(today.getDate() + i);
      const dailyStats = calculateStatsForDay(games, day);
      totalGameCount += dailyStats.gameCount;
      totalPositions += dailyStats.totalPositions;
      totalAcceptedPositions += dailyStats.acceptedPositions;
    }

    const staffedPercentage = totalPositions === 0 ? 0 : (totalAcceptedPositions / totalPositions);
    return {
      totalGameCount,
      staffedPercentage,
    };
  };

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const todayStats = calculateStatsForDay(Object.values(gamesData), today);
  const tomorrowStats = calculateStatsForDay(Object.values(gamesData), tomorrow);
  const next30DaysStats = calculateStatsForNextDays(Object.values(gamesData), 30);
  const next7DaysStats = calculateStatsForNextDays(Object.values(gamesData), 7);

  const filteredData = {
    TodaysGameCount: todayStats.gameCount,
    TodaysGamesStaffedPercentage: Math.round(todayStats.staffedPercentage * 100), // As integer
    TomorrowsGameCount: tomorrowStats.gameCount,
    TomorrowsGamesStaffedPercentage: Math.round(tomorrowStats.staffedPercentage * 100), // As integer
    Next30DaysGameCount: next30DaysStats.totalGameCount,
    Next30DaysGamesStaffedPercentage: parseFloat((next30DaysStats.staffedPercentage * 100).toFixed(2)), // As floating-point number
    Next7DaysGameCount: next7DaysStats.totalGameCount,
    Next7DaysGamesStaffedPercentage: parseFloat((next7DaysStats.staffedPercentage * 100).toFixed(2)), // As floating-point number
  };

  console.log(filteredData);

  return filteredData;
};

