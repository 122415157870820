import GameRowDetails from "./Modals/GameRowDetails";

import RefereeOverviewButtons from "./RefereeModifyGameButtons";
import { useState } from "react";

const MyGamesGameRow = ({ game, UID, handleButtonClick, isColumnLayout }) => {
  const refereeDataArray = Object.values(game.Positions);
  const [isGameModalOpen, setIsGameModalOpen] = useState(false);

  const handleModal = () => {
    setIsGameModalOpen(!isGameModalOpen);
  };

  return (
    <div className="self-stretch rounded-3xs flex flex-col md:flex-row items-center justify-start p-[0.63rem] gap-[0.94rem] text-darkslategray-100">
      <div
        className="flex-1 flex flex-col items-start justify-center py-[0rem] px-[0.63rem] max-w-[75rem] cursor-pointer"
        onClick={handleModal}
      >
        <div className="self-stretch rounded-t-3xs rounded-b-none bg-darkslategray-600 flex flex-row items-center justify-start py-[0rem] pr-[0rem] pl-[0.63rem] gap-[0.25rem] ">
          <div className="relative leading-[137.3%] text-[0.75rem]">AR 1</div>
          <div className="flex-1 rounded-tl-xl rounded-tr-3xs rounded-br-none rounded-bl-xl bg-cornflowerblue-100 flex flex-row items-center justify-between p-[0.63rem] gap-[1rem] text-[0.75rem] text-white">
            <div className="flex flex-row items-center justify-start gap-[1rem]">
              <div className="flex flex-row items-center justify-start gap-[.5rem]">
                <img
                  className="relative rounded-[50%] w-[0.88rem] h-[0.88rem] object-cover"
                  alt=""
                  src="/ellipse-2@2x.png"
                />
                <div className="relative">{game.HomeTeam}</div>
              </div>
              <b className="relative items-center">vs</b>
              <div className="flex flex-row items-center justify-end gap-[.5rem]">
                <img
                  className="relative rounded-[50%] w-[0.88rem] h-[0.88rem] object-cover"
                  alt=""
                  src="/ellipse-21@2x.png"
                />
                <div className="relative">{game.AwayTeam}</div>
              </div>
            </div>
            <div className="flex flex-row gap[1rem]">
              <div>{game.HalfDuration} minute half duration</div>
            </div>
          </div>
        </div>
        <div className="self-stretch rounded-t-none rounded-b-3xs bg-white flex flex-col items-center justify-between py-[0.63rem] px-[1.56rem] text-[0.75rem] font-outfit border-[1px] border-solid border-lightgray-100 sm:flex-row gap-[.5rem]">
          <div className="flex flex-row items-center justify-start gap-[.5rem]">
            <img
              className="relative w-[1rem] h-[1rem]"
              alt=""
              src="/timer.svg"
            />
            <div className="flex flex-col items-center justify-center gap-[.25rem]">
              <div className="relative">
                {new Date(game.StartTime).toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                })}
              </div>
              <div className="relative">
                {new Date(game.StartTime).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[0.5rem] text-center">
            <img
              className="relative w-[1.18rem] h-[0.69rem]"
              alt=""
              src="/soccerfieldicon.svg"
            />
            <div className="flex flex-col items-center justify-center">
              <div className="relative leading-[137.3%]">{game.FieldName}</div>
              <div className="relative leading-[137.3%]">{game.Address}</div>
            </div>
          </div>
          <div className="flex flex-col items-end justify-end">
            {refereeDataArray.map((refData, index) => (
              <div key={index} className="flex ">
                {refData.UID === UID && (
                  <img
                    className="w-[1rem] h-[1rem] mr-[0.31rem]"
                    alt="Flag"
                    src="/flagicon.svg"
                  />
                )}
                <p className={`m-0 ${refData.UID === UID ? "font-bold" : ""}`}>
                  {refData.UID === UID
                    ? `${refData.Name} - ${refData.User.Name}`
                    : `${refData.Name} - ${refData.Status}`}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        {refereeDataArray.map((refData, index) => (
          <div key={index}>
            {refData.UID === UID ? (
              <RefereeOverviewButtons
                refData={refData}
                gameData={game}
                handleButtonClick={handleButtonClick}
                isColumnLayout={isColumnLayout}
              />
            ) : (
              <div className="placeholder-for-button" />
            )}
          </div>
        ))}
      </div>
      <GameRowDetails
        isOpen={isGameModalOpen}
        onClose={handleModal}
        game={game}
      />
    </div>
  );
};

export default MyGamesGameRow;
