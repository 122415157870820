import React from 'react';
import ModalWrapper from './ModalWrapper';

const TitleModalWrapper = ({ isOpen, onClose, title, children, width }) => {
    // Conditionally apply styles based on the presence of the 'width' prop
    const dynamicStyles = width ? {
        maxWidth: '100%', // Ensures the modal does not exceed the viewport width
        width: width, // Applies the specified width if provided
    } : {};

    // Apply responsive design for smaller screens
    const baseClasses = "rounded-xl bg-white shadow-[15px_15px_30px_rgba(0,0,0,0.1)] flex flex-col w-[300px] md:w-[640px] items-start justify-center p-[25px] gap-[15px] text-center text-xl text-darkslategray-100 font-outfit";
    // Remove 'md:w-auto' to avoid enforcing a specific width at the 'md' breakpoint
    const responsiveClasses = "w-full sm:w-11/12"; // Ensures full width on small screens, with a slight margin on 'sm' screens

    const contentWithHeader = (
        <div
            className={`${baseClasses} ${responsiveClasses}`}
            style={dynamicStyles} // Conditionally apply dynamic styles for width control
        >
            <div className="self-stretch flex flex-row items-center justify-start gap-[22px] text-left">
                <div className="flex-1 relative font-medium">{title}</div>
                <div className="cursor-pointer" onClick={onClose}>
                    <img className="relative w-6 h-6" alt="Close" src="/close-button.svg"/>
                </div>
            </div>
            {children}
        </div>
    );

    return (
        <ModalWrapper isOpen={isOpen} onClose={onClose}>
            {contentWithHeader}
        </ModalWrapper>
    );
};

export default TitleModalWrapper;
