import React from "react";
import GameRowCard from "./GameRowCard";
import { dotPulse } from "ldrs";
import TableHeader from "./table/TableHeader";

dotPulse.register()

const GamesWithoutRefs = ({ apidata, openGameAssignModal }) => {
    return (
        <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <TableHeader
                title={"Games without Refs"}
            />
            {!apidata || apidata.length === 0 ? (
                <div className="self-stretch flex-1 rounded-t-none rounded-b-3xs flex flex-col items-center justify-center p-[0.94rem] gap-[0.69rem] text-[0.88rem] text-darkslategray-200 border-r-[1px] border-solid border-lightgray-100 border-b-[1px] border-l-[1px]">
                    <l-dot-pulse size="43" speed="1.3" color="#2d556c"></l-dot-pulse>
                </div>
            ) : (
                <div className="self-stretch rounded-t-none rounded-b-3xs flex flex-col items-start justify-start p-[0.94rem] gap-[0.69rem] text-center text-[0.69rem] border-r-[1px] border-solid border-lightgray-100 border-b-[1px] border-l-[1px]">
                    {apidata.map((game, index) => (
                        <GameRowCard key={index} game={game} openGameAssignModal={openGameAssignModal} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default GamesWithoutRefs;
