import React, { useState, useEffect } from "react";
import {useAppContext} from "../../context/MainContext/AppContext";
import {Role} from "../../context/MainContext/Constants";
import TitleModalWrapper from "./TitleModalWrapper";
import ModuleButtonGroup from "./ModalButtonsGroup";

const ModifyRefereeinOrganization = ({
  isOpen,
  onClose,
  refereeToModify,
  handleModifyReferee,
  handleReinviteClick,
}) => {
  const [createModifyRefereeformData, setCreateModifyRefereeformData] = useState({});
  const {role} = useAppContext();

  useEffect(() => {
    if (refereeToModify) {
      setCreateModifyRefereeformData({
        Name: refereeToModify.Name,
        Email: refereeToModify.Email,
        PermissionLevel: refereeToModify.PermissionLevel,
      });
    }
  }, [refereeToModify]);

  const handlePermissionLevelChange = (e) => {
    setCreateModifyRefereeformData((prevData) => ({
      ...prevData,
      PermissionLevel: e.target.value,
    }));
    console.log(createModifyRefereeformData);
  };

  const middleButtons = [
    {
      onClick: handleReinviteClick,
      text: "Re-Invite",
      theme: "SALMON",
    },
    {
      onClick: () => handleModifyReferee(createModifyRefereeformData),
      text: "Save Changes",
      theme: "DARK",
    },
  ];

  return (
    <TitleModalWrapper isOpen={isOpen} onClose={onClose} title={"Modify User"}>
      <div className="self-stretch flex flex-row items-center justify-center p-2.5 text-xs text-darkslategray-200">
        <div className="rounded-3xs flex flex-col items-start justify-start">
          <div className="bg-gray-600 box-border w-[369.5px] h-[45px] flex flex-row items-center justify-between py-[5px] px-5 border-[1px] border-solid border-darkslategray-400">
            <div className="self-stretch flex-1 flex flex-row items-center justify-between">
              <div className="flex-1 relative">Edit Permissions</div>
            </div>
            <div className="flex-1 h-5 flex flex-row items-center justify-center">
              <select
                  className="rounded-3xs bg-white border-[1px] border-solid border-lightgray-100 cursor-pointer"
                  value={createModifyRefereeformData.PermissionLevel}
                  onChange={handlePermissionLevelChange}
              >
                {role === Role.Owner && <option value="Owner">Owner</option>}
                {role !== Role.Referee && <option value="Assigner">Assigner</option>}
                <option value="Referee">Referee</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {/*FOOTER*/}
      <ModuleButtonGroup
          middleButtons={middleButtons}
      />
    </TitleModalWrapper>
  );
};

export default ModifyRefereeinOrganization;
