export {StorageKeys, addToLocalStorage, getFromLocalStorage, removeFromLocalStorage, clearStorage}

const StorageKeys = Object.freeze({
    FIREBASE_USER: "firebase_user",
    DATABASE_USER: "database_user",
    ACCESS_TOKEN: "access_token",
    ROLE: "role",
    CURRENT_ORG: "current_organization",
    ACTIVE_TAB: "active_tab"
});

const clearStorage = () => {
    localStorage.clear();
};

const removeFromLocalStorage = (key) => {
    if (!Object.values(StorageKeys).includes(key)) {
        throw new Error(`Invalid storage key: ${key}`);
    }

    localStorage.removeItem(key);
}

const addToLocalStorage = (key, value) => {
    if (!Object.values(StorageKeys).includes(key)) {
        throw new Error(`Invalid storage key: ${key}`);
    }

    if (typeof value === 'object') {
        // Stringify if the value is an object (including arrays)
        localStorage.setItem(key, JSON.stringify(value));
    } else {
        // Store the value as is for non-object types
        localStorage.setItem(key, value);
    }
};

const getFromLocalStorage = (key) => {
    if (!Object.values(StorageKeys).includes(key)) {
        throw new Error(`Invalid storage key: ${key}`);
    }

    const item = localStorage.getItem(key);
    if (!item) {
        // If the item doesn't exist, return null or undefined
        return null;
    }

    try {
        // Try to parse the item. If it's an object or array, this will succeed
        return JSON.parse(item);
    } catch (error) {
        // If JSON parsing fails, return the item as is (it's a string)
        return item;
    }
};
