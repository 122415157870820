import React, { useEffect } from "react";

const ModalWrapper = ({ children, isOpen, onClose }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-60"
      onClick={onClose}
      id="nothing"
    >
      <form
        className="flex justify-center relative rounded-xl shadow-[15px_15px_30px_rgba(0,0,0,0.1)]"
        id="border"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </form>
    </div>
  );
};

export default ModalWrapper;
