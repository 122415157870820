import React from "react";
import useRouter from "../../hooks/router";

const LandingPageHeader = () => {
    const {handleNavigation} = useRouter();

    return (
        <div
            className="fixed top-0 left-0 w-full z-50 flex flex-col items-start justify-between text-center text-[1rem] text-khaki-200 font-outfit">
            <div
                className="self-stretch bg-darkslategray-100 flex flex-row items-center justify-between py-[0.94rem] px-[1.25rem]">
                <img
                    className="relative w-[6.44rem] h-[3.06rem] overflow-hidden shrink-0 cursor-pointer"
                    alt="fasdf"
                    src="/logo.svg"
                    onClick={() => handleNavigation("/")}
                />
                <div className="flex flex-row gap-[1rem]">
                    <div
                        className="rounded-3xs bg-khaki-300 hover:bg-khaki-400 active:bg-khaki-500 box-border w-[5rem] flex flex-row items-center justify-center py-[0.31rem] px-[0.63rem] border-[1px] border-solid border-gray-700 cursor-pointer transition duration-200 ease-in-out transform hover:shadow-lg active:scale-95"
                        onClick={() => handleNavigation("/login")}
                    >
                        <div className="relative">Login</div>
                    </div>
                    <div
                        className="rounded-3xs bg-khaki-300 hover:bg-khaki-400 active:bg-khaki-500 box-border w-[6.7rem] flex flex-row items-center justify-center py-[0.31rem] px-[0.63rem] border-[1px] border-solid border-gray-700 cursor-pointer transition duration-200 ease-in-out transform hover:shadow-lg active:scale-95"
                        onClick={() => handleNavigation("/signup")}
                    >
                        <div className="relative">Get Started</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPageHeader;
