import React from "react";

const InputField = ({
  field,
  manuallyCreateformData,
  handleInputChange,
  error,
}) => {
  const renderInput = () => {
    switch (field.type) {
      case "text":
        return (
          <input
            type="text"
            value={manuallyCreateformData?.[field.key]}
            onChange={(e) => handleInputChange(e, field.key)}
            className={`box-border ${
              error
                ? "border-b-[1px] border-red-500"
                : "border-b-[1px] border-lightgray-100"
            } w-28 h-px border-solid focus:outline-none bg-transparent text-center text-sm text-darkslategray-200 font-outfit`}
          />
        );
      case "number":
        return (
          <input
            type="number"
            value={manuallyCreateformData?.[field.key]}
            onChange={(e) => handleInputChange(e, field.key)}
            className={`relative box-border ${
              error
                ? "border-b-[1px] border-red-500"
                : "border-b-[1px] border-lightgray-100"
            } w-28 h-px border-solid focus:outline-none bg-transparent text-center text-sm text-darkslategray-200 font-outfit`}
          />
        );
      case "datePicker":
        return (
          <input
            type="date"
            value={manuallyCreateformData?.[field.key]}
            onChange={(e) => handleInputChange(e, field.key)}
            className={`relative box-border ${
              error
                ? "border-b-[1px] border-red-500"
                : "border-b-[1px] border-lightgray-100"
            } w-33 h-px border-solid focus:outline-none bg-transparent text-center text-sm text-darkslategray-200 font-outfit`}
          />
        );
      case "timePicker":
        return (
          <input
            type="time"
            value={manuallyCreateformData?.[field.key]}
            onChange={(e) => handleInputChange(e, field.key)}
            className={`relative box-border ${
              error
                ? "border-b-[1px] border-red-500"
                : "border-b-[1px] border-lightgray-100"
            } w-39 h-px border-solid focus:outline-none bg-transparent text-center text-sm text-darkslategray-200 font-outfit`}
          />
        );
      default:
        return <div>Unsupported field type</div>;
    }
  };

  return (
    <div
      key={field.key}
      className="bg-gray-600 box-border w-[369.5px] h-[38px] flex flex-row py-[5px] px-5 border-[1px] border-solid border-darkslategray-400 text-sm"
    >
      <div className="self-stretch flex-1 flex flex-row items-center gap-[5px]">
        <div>{field.label}</div>
        {field.required === true && (
          <img className="relative w-1 h-1" alt="" src="/RequiredIcon.png" />
        )}
      </div>
      <div className="flex-1 h-5 flex flex-row">{renderInput()}</div>
    </div>
  );
};

export default InputField;
