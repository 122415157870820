import RefCard from "../RefCard";
import formatDateString from "../../utils/FormatDateString";
import { getPhotoFromDbUser } from "../../utils/Utils";
import TitleModalWrapper from "./TitleModalWrapper";

const GameRowDetails = ({ isOpen, onClose, game }) => {
  let [startTime, startDate] = formatDateString(game.StartTime);
  return (
    <TitleModalWrapper isOpen={isOpen} onClose={onClose} title={"Game Details"}>
      <div className="self-stretch flex flex-col items-start justify-start gap-[21px] text-center text-base text-darkslategray-200">
        <div className="self-stretch rounded-t-3xs rounded-b-none bg-darkslategray-600 flex flex-row items-start justify-center p- gap-[41px] text-sm text-darkslategray-100">
          <div className="w-[248px] flex flex-row items-center justify-start gap-[2px] text-left">
            <img className="relative w-4 h-4" alt="" src="/timer.svg" />
            <div className="flex-1 flex flex-row items-center justify-start gap-[2px]">
              <div className="relative">{`Tuesday `}</div>
              <div className="relative rounded-[50%] bg-gainsboro-200 w-1 h-1" />
              <div className="relative">{startDate}</div>
              <div className="relative rounded-[50%] bg-gainsboro-200 w-1 h-1" />
              <div className="flex-1 flex flex-row items-center justify-start gap-[10px]">
                <div className="relative">{startTime}</div>
              </div>
            </div>
          </div>
          <div className="hidden sm:flex flex-row items-center justify-start gap-[2px]">
            <img
              className="relative w-[18.94px] h-[11.1px]"
              alt=""
              src="/soccerfieldicon.svg"
            />
            <div className="flex flex-col items-start justify-start">
              <div className="relative leading-[137.3%]">{game?.FieldName}</div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[9px] text-left text-xs">
          <RefCard
            id="1"
            name={game.Positions[0].User?.Name}
            email={game.Positions[0].User?.Email}
            photo={getPhotoFromDbUser(game.Positions[0].User)}
          />
          <RefCard
            id="2"
            name={game.Positions[1].User?.Name}
            email={game.Positions[1].User?.Email}
            photo={getPhotoFromDbUser(game.Positions[1].User)}
          />
          <RefCard
            id="3"
            name={game.Positions[2].User?.Name}
            email={game.Positions[2].User?.Email}
            photo={getPhotoFromDbUser(game.Positions[2].User)}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start p-2.5 gap-[8px]">
          <div className="flex flex-row items-center justify-start gap-[6px]">
            <img
              className="relative w-[18px] h-[18px]"
              alt=""
              src="/soccerfieldicon.svg"
            />
            <div className="relative font-medium">Location</div>
          </div>
          <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gray-400" />
          <div className="self-stretch flex flex-col items-end justify-start gap-[15px] text-left text-sm text-darkslategray-200">
            <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
              <div className="self-stretch flex flex-row items-start justify-start gap-[210px]">
                <div className="flex-1 relative ">{game?.FieldName}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start gap-[210px]">
                <div className="flex-1 relative">{game?.Address}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start gap-[281px]"></div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[6px]">
            <img
              className="relative w-[18px] h-[18px]"
              alt=""
              src="/soccerballicon.svg"
            />
            <div className="relative font-medium">Game Info</div>
          </div>
          <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gray-400" />
          <div className="self-stretch flex flex-col items-end justify-start gap-[15px] text-left text-sm">
            <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
              <div className="flex flex-col gap-[210px] sm:flex-row md:self-stretch items-start justify-start gap-[6px]">
                <div className="flex-1 relative">League: {game?.League}</div>
                <div className="flex-1 relative">Age: {game?.AgeGroup}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start gap-[281px]"></div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[6px]">
            <img
              className="relative w-[18px] h-[18px]"
              alt=""
              src="/flagicon.svg"
            />
            <div className="relative font-medium">Teams</div>
          </div>
          <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gray-400" />
          {/* ADD INFO */}
          <div className="self-stretch flex flex-row items-end justify-start gap-[15px] text-left text-sm">
            <div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
              <div className="flex flex-col sm:flex-row md: items-start justify-start gap-[6px]">
                <div className="flex-2 relative">
                  Home Team: {game?.HomeTeam}
                </div>
                <div className="flex-2 relative md:ml-[100px]">
                  Away Team: {game?.AwayTeam}
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start gap-[281px]"></div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[6px]">
            <img
              className="relative w-[18px] h-[18px]"
              alt=""
              src="/timer.svg"
            />
            <div className="relative font-medium">Timing</div>
          </div>
          <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gray-400" />
          <div className="self-stretch flex flex-col items-end justify-start gap-[15px] text-left text-sm">
            <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
              <div className="flex flex-col gap-[210px] sm:flex-row md:self-stretch items-start justify-start gap-[6px]">
                <div className="flex-1 relative">
                  Half Duration: {game?.HalfDuration}
                </div>
                <div className="flex-1 relative">Start Time: {startTime}</div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start gap-[281px]"></div>
            </div>
          </div>
        </div>
      </div>
    </TitleModalWrapper>
  );
};

export default GameRowDetails;
