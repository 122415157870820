import React from "react";
import TableHeader from "./table/TableHeader";

const RefereeAnnouncements = () => {
    return (
        <div
            className="self-stretch flex-1 flex flex-col items-center justify-center text-left text-[1.25rem] text-darkslategray-100 font-outfit">
            <TableHeader
                title={"Announcements for Referees"}
            />
            <div
                className="self-stretch flex-1 flex rounded-b-3xs flex-col items-start justify-between gap[2rem] p-[0.94rem] text-[0.69rem] border-[1px] border-solid border-lightgray-100 overflow-auto">

                <div className="self-stretch bg-darkslategray-300 p-[0.63rem] rounded-3xs shadow-md">
                    <strong>Matchday Preparation:</strong> Reminder to review the latest
                    matchday conduct guidelines before the weekend games.
                </div>
              

            </div>
        </div>
    );
};

export default RefereeAnnouncements;
