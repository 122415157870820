export {filterGameData,
        getUniqueValuesForField,
        getUniqueFieldNames,
        getPhotoFromDbUser,
        handleImageError,
        isValidEmail,
        isValidPassword,
        validateCredentials,
        mapFirebaseErrorToMessage}

function filterGameData(gameData, filterSettings) {
    // Treat gameData as an empty array if it's undefined, null, or not an array
    gameData = Array.isArray(gameData) ? gameData : [];
    let filteredGameData = [];

    // Filter the game data based on filter settings
    if (filterSettings && filterSettings.length > 0) {
        filteredGameData = gameData.filter(game => {
            return filterSettings.every(setting => {
                // If the setting is selected and the game has the field, check the value
                if (Object.prototype.hasOwnProperty.call(game, setting.Name)) {
                    return setting.Value ? game[setting.Name] === setting.Value : true;
                }
                // If the setting is not selected, the game passes this setting's filter
                return true;
            });
        });
    } else {
        // If no filter settings, include all games
        filteredGameData = gameData;
    }

    // Remove fields from games where setting.Selected is false
    const processedGameData = filteredGameData.map(game => {
        const newGame = { ...game };
        filterSettings.forEach(setting => {
            if (!setting.Selected && Object.prototype.hasOwnProperty.call(game, setting.Name)) {
                delete newGame[setting.Name];
            }
        });
        return newGame;
    });

    // Get unique field names
    const uniqueFields = getUniqueFieldNames(processedGameData);
    const filteredUniqueFields = uniqueFields.filter(fieldName => fieldName !== "GID" && fieldName !== "RefereePattern" && fieldName !== "OID");

    // Reorder filtered game data
    const filteredGamesData = reorderGameData(processedGameData, uniqueFields);

    // Return all the processed data
    return {
        filteredGamesData,
        filteredUniqueFields
    };
}

function reorderGameData(data, orderedFieldNames) {
    return data.map(game => {
        const orderedGame = {};
        orderedFieldNames.forEach(fieldName => {
            if (Object.prototype.hasOwnProperty.call(game, fieldName)) {
                orderedGame[fieldName] = game[fieldName];
            }
        });
        return orderedGame;
    });
}

function getUniqueValuesForField(array, fieldName) {
    const uniqueValues = new Set();

    array.forEach((game) => {
        if (Object.prototype.hasOwnProperty.call(game, fieldName)) {
            uniqueValues.add(game[fieldName]);
        }
    });

    return Array.from(uniqueValues);
}

function getUniqueFieldNames(games) {
    const uniqueFieldNames = new Set();

    // Ensure games is an array before calling forEach
    if (Array.isArray(games)) {
        games.forEach((game) => {
            // Assuming game is an object with fields
            for (const fieldName in game) {
                if (Object.prototype.hasOwnProperty.call(game, fieldName)) {
                    uniqueFieldNames.add(fieldName);
                }
            }
        });
    }

    return Array.from(uniqueFieldNames); // Convert the Set to an array
}

const getPhotoFromDbUser = (database_user) => {
    // Check if database_user is truthy and has a Photo property that's also truthy
    return (database_user && database_user.Photo) ? database_user.Photo : "/RefereeDefaultProfilePicture.svg";
}

function handleImageError(e) {
    e.target.src = "/RefereeDefaultProfilePicture.svg"; // Path to your stock image
}

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$/;
    return passwordRegex.test(password);
};

const validateCredentials = (name, email, password) => {
    if (name !== undefined && !name.trim()) {
        return 'Please enter your name.';
    }

    if (!isValidEmail(email)) {
        return 'Please enter a valid email address.';
    }

    if (!isValidPassword(password)) {
        return 'Password must be at least 7 characters long, contain at least one number, one capital letter, and one symbol.';
    }

    return '';
};

const mapFirebaseErrorToMessage = (firebaseErrorMessage) => {
    if (firebaseErrorMessage.includes("auth/user-not-found")) {
        return "User not found, please press Create Account Here and create an account";
    } else if (firebaseErrorMessage.includes("auth/popup-closed-by-user")) {
        return "Google pop-up closed, please try again.";
    } else if (firebaseErrorMessage.includes("auth/popup-blocked")) {
        return "Popups enabled, press Sign in with Google again";
    }
    return firebaseErrorMessage;
};