import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCsUIyw5GDR2U7M7sjBnvq4hSfIWNfN0VY",
  authDomain: "refalign-54cbf.firebaseapp.com",
  projectId: "refalign-54cbf",
  storageBucket: "refalign-54cbf.appspot.com",
  messagingSenderId: "979019365964",
  appId: "1:979019365964:web:0435ffb6784ec6a494bee9",
  measurementId: "G-7W6R1D36W4",
};

const app = initializeApp(firebaseConfig);

export default app;
