import React, {useEffect} from "react";
import {useAppContext} from "../../../context/MainContext/AppContext";
import useRouter from "../../../hooks/router";
import {Role} from "../../../context/MainContext/Constants";
import LandingPageHeader from "../../../components/login/LandingPageHeader";
import LandingPageFooter from "../../../components/login/LandingPageFooter";

const PrivacyPolicy = () => {
    const {role, handleGoogleRegister} = useAppContext();
    const {handleNavigation} = useRouter();

    useEffect(() => {
        if (role) {
            switch (role) {
                case Role.Referee:
                    handleNavigation(`/referee/`);
                    break;
                case Role.Assigner:
                    handleNavigation(`/assigner/`);
                    break;
            }
        }
    }, [role, handleGoogleRegister]);

    return (
        <div
            className="bg-white overflow-hidden flex flex-col items-start justify-center text-base text-black font-outfit">
            <LandingPageHeader/>
            <div className="px-10  pt-16">
                <h1 className="text-3xl font-semibold mb-4">PRIVACY POLICY</h1>
                <p className="mb-4">Last updated December 06, 2023</p>
                <p className="mb-4">
                    This privacy notice for RefAign Inc. (&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;), describes
                    how and
                    why we might collect, store, use, and/or share (&quot;process&quot;) your information when you use
                    our
                    services (&quot;Services&quot;), such as when you:
                </p>

                <ul className="list-disc pl-6 mb-4">
                    <li>
                        Visit our website at http://www.refalign.com, or any website of ours that links to this privacy
                        notice
                    </li>
                    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                </ul>
                <p className="mb-4">
                    Questions or concerns? Reading this privacy notice will help you understand your privacy rights and
                    choices.
                    If you do not agree with our policies and practices, please do not use our Services. If you still
                    have any
                    questions or concerns, please contact us at sales@refaign.com.
                </p>

                <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
                <h3>Personal information you disclose to us</h3>

                <p>
                    We collect personal information that you voluntarily provide to us when you register on the
                    Services, express
                    an interest in obtaining information about us or our products and Services, when you participate in
                    activities
                    on the Services, or otherwise when you contact us.
                </p>
                <h4>
                    Personal Information Provided by You. The personal information that we collect depends on the
                    context of your
                    interactions with us and the Services, the choices you make, and the products and features you use.
                    The
                    personal information we collect may include the following:
                </h4>
                <ul>
                    <li>names</li>
                    <li>phone numbers</li>
                    <li>email addresses</li>
                    <li>usernames</li>
                    <li>passwords</li>
                    <li>contact or authentication data</li>
                    <li>debit/credit card numbers</li>
                </ul>
                <p>Sensitive Information. We do not process sensitive information.</p>
                <h4>
                    Payment Data. We may collect data necessary to process your payment if you make purchases, such as
                    your
                    payment instrument number, and the security code associated with your payment instrument. All
                    payment data is
                    stored by Stripe. You may find their privacy notice link(s) here:{}
                    <a href=";https://stripe.com/privacy">https://stripe.com/privacy</a>.
                </h4>
                <h4>
                    Social Media Login Data. We may provide you with the option to register with us using your existing
                    social
                    media account details, like your Facebook, Twitter, or other social media account. If you choose to
                    register
                    in this way, we will collect the information described in the section called &quot;HOW DO WE HANDLE
                    YOUR
                    SOCIAL LOGINS?&quot; below.
                </h4>
                <p>
                    All personal information that you provide to us must be true, complete, and accurate, and you must
                    notify us
                    of any changes to such personal information.
                </p>

                <h2>Information automatically collected</h2>
                <p>
                    We automatically collect certain information when you visit, use, or navigate the Services. This
                    information
                    does not reveal your specific identity (like your name or contact information) but may include
                    device and
                    usage information, such as your IP address, browser and device characteristics, operating system,
                    language
                    preferences, referring URLs, device name, country, location, information about how and when you use
                    our
                    Services, and other technical information. This information is primarily needed to maintain the
                    security and
                    operation of our Services, and for our internal analytics and reporting purposes.
                </p>
                <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                <p>
                    <strong>The information we collect includes:</strong>
                </p>
                <ul>
                    <li>
                        <strong>Log and Usage Data.</strong> Log and usage data is service-related, diagnostic, usage,
                        and
                        performance information our servers automatically collect when you access or use our Services
                        and which we
                        record in log files. Depending on how you interact with us, this log data may include your IP
                        address,
                        device information, browser type, and settings and information about your activity in the
                        Services (such as
                        the date/time stamps associated with your usage, pages and files viewed, searches, and other
                        actions you
                        take such as which features you use), device event information (such as system activity, error
                        reports
                        (sometimes called &quot;crash dumps&quot;), and hardware settings).
                    </li>
                    <li>
                        <strong>Device Data.</strong> We collect device data such as information about your computer,
                        phone, tablet,
                        or other device you use to access the Services. Depending on the device used, this device data
                        may include
                        information such as your IP address (or proxy server), device and application identification
                        numbers,
                        location, browser type, hardware model, Internet service provider and/or mobile carrier,
                        operating system,
                        and system configuration information.
                    </li>
                    <li>
                        <strong>Location Data.</strong> We collect location data such as information about your
                        device&apos;s
                        location, which can be either precise or imprecise. How much information we collect depends on
                        the type and
                        settings of the device you use to access the Services. For example, we may use GPS and other
                        technologies to
                        collect geolocation data that tells us your current location (based on your IP address). You can
                        opt out of
                        allowing us to collect this information either by refusing access to the information or by
                        disabling your
                        Location setting on your device. However, if you choose to opt out, you may not be able to use
                        certain
                        aspects of the Services.
                    </li>
                </ul>
                <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                <p>
                    We process your personal information for a variety of reasons, depending on how you interact with
                    our
                    Services, including:
                </p>
                <ul>
                    <li>
                        To facilitate account creation and authentication and otherwise manage user accounts. We may
                        process your
                        information so you can create and log in to your account, as well as keep your account in
                        working order.
                    </li>
                    <li>
                        To deliver and facilitate delivery of services to the user. We may process your information to
                        provide you
                        with the requested service.
                    </li>
                    <li>
                        To respond to user inquiries/offer support to users. We may process your information to respond
                        to your
                        inquiries and solve any potential issues you might have with the requested service.
                    </li>
                    <li>
                        To send administrative information to you. We may process your information to send you details
                        about our
                        products and services, changes to our terms and policies, and other similar information.
                    </li>
                    <li>
                        To fulfill and manage your orders. We may process your information to fulfill and manage your
                        orders,
                        payments, returns, and exchanges made through the Services.
                    </li>
                    <li>
                        To enable user-to-user communications. We may process your information if you choose to use any
                        of our
                        offerings that allow for communication with another user.
                    </li>
                    <li>
                        To request feedback. We may process your information when necessary to request feedback and to
                        contact you
                        about your use of our Services.
                    </li>
                    <li>
                        To send you marketing and promotional communications. We may process the personal information
                        you send to us
                        for our marketing purposes, if this is in accordance with your marketing preferences. You can
                        opt out of our
                        marketing emails at any time. For more information, see &quot;WHAT ARE YOUR PRIVACY
                        RIGHTS?&quot; below.
                    </li>
                    <li>
                        To protect our Services. We may process your information as part of our efforts to keep our
                        Services safe
                        and secure, including fraud monitoring and prevention.
                    </li>
                    <li>
                        To comply with our legal obligations. We may process your information to comply with our legal
                        obligations,
                        respond to legal requests, and exercise, establish, or defend our legal rights.
                    </li>
                </ul>
                <h2>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
                <p>
                    <strong>If you are located in Canada, this section applies to you.</strong>
                </p>
                <p>
                    We may process your information if you have given us specific permission (i.e., express consent) to
                    use your
                    personal information for a specific purpose, or in situations where your permission can be inferred
                    (i.e.,
                    implied consent). You can withdraw your consent at any time.
                </p>
                <p>
                    In some exceptional cases, we may be legally permitted under applicable law to process your
                    information
                    without your consent, including, for example:
                </p>
                <ul>
                    <li>
                        If collection is clearly in the interests of an individual and consent cannot be obtained in a
                        timely way
                    </li>
                    <li>For investigations and fraud detection and prevention</li>
                    <li>For business transactions provided certain conditions are met</li>
                    <li>
                        If it is contained in a witness statement and the collection is necessary to assess, process, or
                        settle an
                        insurance claim
                    </li>
                    <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                    <li>
                        If we have reasonable grounds to believe an individual has been, is, or may be victim of
                        financial abuse
                    </li>
                    <li>
                        If it is reasonable to expect collection and use with consent would compromise the availability
                        or the
                        accuracy of the information and the collection is reasonable for purposes related to
                        investigating a breach
                        of an agreement or a contravention of the laws of Canada or a province
                    </li>
                    <li>
                        If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court
                        relating to
                        the production of records
                    </li>
                    <li>
                        If it was produced by an individual in the course of their employment, business, or profession
                        and the
                        collection is consistent with the purposes for which the information was produced
                    </li>
                    <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                    <li>If the information is publicly available and is specified by the regulations</li>
                </ul>
                <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                <p>We may need to share your personal information in the following situations:</p>
                <ul>
                    <li>
                        <strong>Business Transfers.</strong> We may share or transfer your information in connection
                        with, or during
                        negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                        portion of our
                        business to another company.
                    </li>
                    <li>
                        <strong>When we use Google Maps Platform APIs.</strong> We may share your information with
                        certain Google
                        Maps Platform APIs (e.g., Google Maps API, Places API). We use certain Google Maps Platform APIs
                        to retrieve
                        certain information when you make location-specific requests. This includes: While we currently
                        do not have
                        any features of the Google Maps API implemented, we may in the future, implement features of the
                        Google Maps
                        API pertaining to the locations the user has refereed soccer games; and other similar
                        information. A full
                        list of what we use information for can be found in this section and in the previous section
                        titled
                        &quot;HOW DO WE PROCESS YOUR INFORMATION?&quot; We obtain and store on your device
                        (&quot;cache&quot;) your
                        location for one (1) month. You may revoke your consent anytime by contacting us at the contact
                        details
                        provided at the end of this document. The Google Maps Platform APIs that we use store and access
                        cookies and
                        other information on your devices. If you are a user currently in the European Economic Area (EU
                        countries,
                        Iceland, Liechtenstein, and Norway) or the United Kingdom, please take a look at our Cookie
                        Notice.
                    </li>
                    <li>
                        <strong>Affiliates.</strong> We may share your information with our affiliates, in which case we
                        will
                        require those affiliates to honor this privacy notice. Affiliates include our parent company and
                        any
                        subsidiaries, joint venture partners, or other companies that we control or that are under
                        common control
                        with us.
                    </li>
                    <li>
                        <strong>Business Partners.</strong> We may share your information with our business partners to
                        offer you
                        certain products, services, or promotions.
                    </li>
                    <li>
                        <strong>Other Users.</strong> When you share personal information (for example, by posting
                        comments,
                        contributions, or other content to the Services) or otherwise interact with public areas of the
                        Services,
                        such personal information may be viewed by all users and may be publicly made available outside
                        the Services
                        in perpetuity. If you interact with other users of our Services and register for our Services
                        through a
                        social network (such as Facebook), your contacts on the social network will see your name,
                        profile photo,
                        and descriptions of your activity. Similarly, other users will be able to view descriptions of
                        your
                        activity, communicate with you within our Services, and view your profile.
                    </li>
                </ul>

                <h2>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                <p>
                    We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
                    store
                    information. Specific information about how we use such technologies and how you can refuse certain
                    cookies is
                    set out in our Cookie Notice.
                </p>
                <h2>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
                <p>
                    Our Services offer you the ability to register and log in using your third-party social media
                    account details
                    (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile
                    information about you from your social media provider. The profile information we receive may vary
                    depending
                    on the social media provider concerned, but will often include your name, email address, friends
                    list, and
                    profile picture, as well as other information you choose to make public on such a social media
                    platform.
                </p>
                <p>
                    We will use the information we receive only for the purposes that are described in this privacy
                    notice or that
                    are otherwise made clear to you on the relevant Services. Please note that we do not control, and
                    are not
                    responsible for, other uses of your personal information by your third-party social media provider.
                    We
                    recommend that you review their privacy notice to understand how they collect, use, and share your
                    personal
                    information, and how you can set your privacy preferences on their sites and apps.
                </p>

                <h2>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                <p>
                    We will only keep your personal information for as long as it is necessary for the purposes set out
                    in this
                    privacy notice, unless a longer retention period is required or permitted by law (such as tax,
                    accounting, or
                    other legal requirements). No purpose in this notice will require us keeping your personal
                    information for
                    longer than the period of time in which users have an account with us.
                </p>
                <p>
                    When we have no ongoing legitimate business need to process your personal information, we will
                    either delete
                    or anonymize such information, or, if this is not possible (for example, because your personal
                    information has
                    been stored in backup archives), then we will securely store your personal information and isolate
                    it from any
                    further processing until deletion is possible.
                </p>

                <h2>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                <p>
                    We have implemented appropriate and reasonable technical and organizational security measures
                    designed to
                    protect the security of any personal information we process. However, despite our safeguards and
                    efforts to
                    secure your information, no electronic transmission over the Internet or information storage
                    technology can be
                    guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or
                    other
                    unauthorized third parties will not be able to defeat our security and improperly collect, access,
                    steal, or
                    modify your information. Although we will do our best to protect your personal information,
                    transmission of
                    personal information to and from our Services is at your own risk. You should only access the
                    Services within
                    a secure environment.
                </p>

                <h2>9. DO WE COLLECT INFORMATION FROM MINORS?</h2>
                <p>
                    We do not knowingly solicit data from or market to children under 18 years of age. By using the
                    Services, you
                    represent that you are at least 18 or that you are the parent or guardian of such a minor and
                    consent to such
                    minor dependent’s use of the Services. If we learn that personal information from users less than 18
                    years of
                    age has been collected, we will deactivate the account and take reasonable measures to promptly
                    delete such
                    data from our records. If you become aware of any data we may have collected from children under age
                    18,
                    please contact us at sales@refalign.com.
                </p>
                <h2>10. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                <p>
                    In some regions (like Canada), you have certain rights under applicable data protection laws. These
                    may
                    include the right (i) to request access and obtain a copy of your personal information, (ii) to
                    request
                    rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if
                    applicable,
                    to data portability; and (v) not to be subject to automated decision-making. In certain
                    circumstances, you may
                    also have the right to object to the processing of your personal information. You can make such a
                    request by
                    contacting us by using the contact details provided in the section &quot;HOW CAN YOU CONTACT US
                    ABOUT THIS
                    NOTICE?&quot; below.
                </p>
                <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>

                <h3>Withdrawing your consent:</h3>
                <p>
                    If we are relying on your consent to process your personal information, which may be express and/or
                    implied
                    consent depending on the applicable law, you have the right to withdraw your consent at any time.
                    You can
                    withdraw your consent at any time by contacting us by using the contact details provided in the
                    section
                    &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot; below.
                </p>
                <p>
                    However, please note that this will not affect the lawfulness of the processing before its
                    withdrawal nor,
                    when applicable law allows, will it affect the processing of your personal information conducted in
                    reliance
                    on lawful processing grounds other than consent.
                </p>

                <h3>Opting out of marketing and promotional communications:</h3>
                <p>
                    You can unsubscribe from our marketing and promotional communications at any time by clicking on the
                    unsubscribe link in the emails that we send, replying &quot;STOP&quot; or &quot;UNSUBSCRIBE&quot; to
                    the SMS
                    messages that we send, or by contacting us using the details provided in the section &quot;HOW CAN
                    YOU CONTACT
                    US ABOUT THIS NOTICE?&quot; below. You will then be removed from the marketing lists. However, we
                    may still
                    communicate with you — for example, to send you service-related messages that are necessary for the
                    administration and use of your account, to respond to service requests, or for other non-marketing
                    purposes.
                </p>

                <h3>Account Information</h3>
                <p>
                    If you would at any time like to review or change the information in your account or terminate your
                    account,
                    you can:
                    <ul>
                        <li>Contact us using the contact information provided.</li>
                    </ul>
                </p>
                <p>
                    Upon your request to terminate your account, we will deactivate or delete your account and
                    information from
                    our active databases. However, we may retain some information in our files to prevent fraud,
                    troubleshoot
                    problems, assist with any investigations, enforce our legal terms and/or comply with applicable
                    legal
                    requirements.
                </p>

                <h3>Cookies and similar technologies:</h3>
                <p>
                    Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set
                    your
                    browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies,
                    this could
                    affect certain features or services of our Services.
                </p>
                <p>If you have questions or comments about your privacy rights, you may email us at
                    sales@refalign.com.</p>
                
                <h2>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                <p>
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                    (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have
                    data about
                    your online browsing activities monitored and collected. At this stage no uniform technology
                    standard for
                    recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to
                    DNT
                    browser signals or any other mechanism that automatically communicates your choice not to be tracked
                    online.
                    If a standard for online tracking is adopted that we must follow in the future, we will inform you
                    about that
                    practice in a revised version of this privacy notice.
                </p>

                <h2>12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
                <p>
                    <strong>What categories of personal information do we collect?</strong>
                </p>
                <p>We have collected the following categories of personal information in the past twelve (12)
                    months:</p>
                <table>
                    <thead>
                    <tr>
                        <th>Category</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    {[
                        {
                            category: "A. Identifiers",
                            description:
                                "Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, Internet Protocol address, email address, and account name",
                        },
                        {
                            category: "B. Personal information as defined in the California Customer Records statute",
                            description:
                                "Name, contact information, education, employment, employment history, and financial information",
                        },
                        {
                            category: "C. Protected classification characteristics under state or federal law",
                            description: "Gender and date of birth",
                        },
                        {category: "G. Geolocation data", description: "Device location"},
                        {
                            category: "I. Professional or employment-related information",
                            description:
                                "Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us",
                        },
                    ].map((item, index) => (
                        <tr key={index}>
                            <td>{item.category}</td>
                            <td>{item.description}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <p>We will use and retain the collected personal information as needed to provide the Services or
                    for:</p>
                <ul>
                    <li>Category A - As long as the user has an account with us</li>
                    <li>Category B - As long as the user has an account with us</li>
                    <li>Category C - As long as the user has an account with us</li>
                    <li>Category G - As long as the user has an account with us</li>
                    <li>Category I - As long as the user has an account with us</li>
                </ul>
                <p>
                    We may also collect other personal information outside of these categories through instances where
                    you
                    interact with us in person, online, or by phone or mail in the context of:
                </p>
                <ul>
                    <li>Receiving help through our customer support channels;</li>
                    <li>Participation in customer surveys or contests; and</li>
                    <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                </ul>
                <p>
                    Learn about how we use your personal information in the section, &quot;HOW DO WE PROCESS YOUR
                    INFORMATION?&quot;
                </p>
                <p>Will your information be shared with anyone else?</p>
                <p>
                    We may disclose your personal information with our service providers pursuant to a written contract
                    between us
                    and each service provider. Learn more about how we disclose personal information to in the
                    section, &quot;WHEN
                    AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?&quot;
                </p>
                <p>
                    We may use your personal information for our own business purposes, such as for undertaking internal
                    research
                    for technological development and demonstration. This is not considered to be &quot;selling&quot; of
                    your
                    personal information.
                </p>
                <p>
                    We have not disclosed, sold, or shared any personal information to third parties for a business or
                    commercial
                    purpose in the preceding twelve (12) months. We will not sell or share personal information in the
                    future
                    belonging to website visitors, users, and other consumers.
                </p>

                <h2>California Residents</h2>
                <p>
                    California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law permits our
                    users who
                    are California residents to request and obtain from us, once a year and free of charge, information
                    about
                    categories of personal information (if any) we disclosed to third parties for direct marketing
                    purposes and
                    the names and addresses of all third parties with which we shared personal information in the
                    immediately
                    preceding calendar year. If you are a California resident and would like to make such a request,
                    please submit
                    your request in writing to us using the contact information provided below.
                </p>
                <p>
                    If you are under 18 years of age, reside in California, and have a registered account with the
                    Services, you
                    have the right to request removal of unwanted data that you publicly post on the Services. To
                    request removal
                    of such data, please contact us using the contact information provided below, and include the email
                    address
                    associated with your account and a statement that you reside in California. We will make sure the
                    data is not
                    publicly displayed on the Services, but please be aware that the data may not be completely or
                    comprehensively
                    removed from all our systems (e.g., backups, etc.).
                </p>
                <h2>CCPA Privacy Notice</h2>
                <p>
                    This section applies only to California residents. Under the California Consumer Privacy Act (CCPA),
                    you have
                    the rights listed below.
                </p>
                <p>The California Code of Regulations defines a &quot;resident&quot; as:</p>
                <ol>
                    <li>
                        every individual who is in the State of California for other than a temporary or transitory
                        purpose and
                    </li>
                    <li>
                        every individual who is domiciled in the State of California who is outside the State of
                        California for a
                        temporary or transitory purpose
                    </li>
                </ol>
                <p>All other individuals are defined as &quot;non-residents.&quot;</p>
                <p>
                    If this definition of &quot;resident&quot; applies to you, you must adhere to certain rights and
                    obligations
                    regarding your personal information.
                </p>

                <h3>Your rights with respect to your personal information</h3>
                <h4>Right to request deletion of the data — Request to delete</h4>
                <p>
                    You can ask for the deletion of your personal information. If you ask us to delete your personal
                    information,
                    we will respect your request and delete your personal information, subject to certain exceptions
                    provided by
                    law, such as (but not limited to) the exercise by another consumer of his or her right to free
                    speech, our
                    compliance requirements resulting from a legal obligation, or any processing that may be required to
                    protect
                    against illegal activities.
                </p>

                <h4>Right to be informed — Request to know</h4>
                <p>Depending on the circumstances, you have a right to know:</p>
                <ul>
                    <li>whether we collect and use your personal information;</li>
                    <li>the categories of personal information that we collect;</li>
                    <li>the purposes for which the collected personal information is used;</li>
                    <li>whether we sell or share personal information to third parties;</li>
                    <li>the categories of personal information that we sold, shared, or disclosed for a business
                        purpose;
                    </li>
                    <li>
                        the categories of third parties to whom the personal information was sold, shared, or disclosed
                        for a
                        business purpose;
                    </li>
                    <li>the business or commercial purpose for collecting, selling, or sharing personal information;
                        and
                    </li>
                    <li>the specific pieces of personal information we collected about you.</li>
                </ul>
                <p>
                    In accordance with applicable law, we are not obligated to provide or delete consumer information
                    that is
                    de-identified in response to a consumer request or to re-identify individual data to verify a
                    consumer
                    request.
                </p>

                <h4>Right to Non-Discrimination for the Exercise of a Consumer&apos;s Privacy Rights</h4>
                <p>We will not discriminate against you if you exercise your privacy rights.</p>

                <h4>Right to Limit Use and Disclosure of Sensitive Personal Information</h4>
                <p>We do not process consumer&apos;s sensitive personal information.</p>
                <h2>Verification Process</h2>
                <p>
                    Upon receiving your request, we will need to verify your identity to determine you are the same
                    person about
                    whom we have the information in our system. This verification requires us to ask you to provide
                    information so
                    that we can match it with information we have previously provided us. We will avoid requesting
                    additional
                    information from you for the purposes of verification. However, if we cannot verify your identity
                    from the
                    information already maintained by us, we may request that you provide additional information for the
                    purposes
                    of verifying your identity and for security or fraud-prevention purposes. We will delete such
                    additionally
                    provided information as soon as we finish verifying you.
                </p>

                <h3>Other Privacy Rights</h3>
                <ul>
                    <li>You may object to the processing of your personal information.</li>
                    <li>
                        You may request correction of your personal data if it is incorrect or no longer relevant, or
                        ask to
                        restrict the processing of the information.
                    </li>
                    <li>You can designate an authorized agent to make a request under the CCPA on your behalf.</li>
                    <li>
                        You may request to opt out from future selling or sharing of your personal information to third
                        parties.
                    </li>
                </ul>
                <p>
                    To exercise these rights, you can contact us by visiting our settings page, by email, or by
                    referring to the
                    contact details at the bottom of this document.
                </p>

                <h2>Colorado Residents</h2>
                <p>
                    This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you have the
                    rights
                    listed below, which, however, are not absolute and, in certain cases, may be declined as permitted
                    by law:
                </p>
                <ul>
                    <li>Right to be informed whether or not we are processing your personal data</li>
                    <li>Right to access your personal data</li>
                    <li>Right to correct inaccuracies in your personal data</li>
                    <li>Right to request deletion of your personal data</li>
                    <li>Right to obtain a copy of the personal data you previously shared with us</li>
                    <li>
                        Right to opt out of the processing of your personal data for targeted advertising, the sale of
                        personal
                        data, or profiling in furtherance of decisions that produce legal or similarly significant
                        effects
                        (&quot;profiling&quot;)
                    </li>
                </ul>
                <p>
                    To submit a request to exercise these rights, please contact us at the provided email or visit our
                    settings
                    page.
                </p>
                <h2>Connecticut Residents</h2>
                <p>
                    This section applies only to Connecticut residents. Under the Connecticut Data Privacy Act (CTDPA),
                    you have
                    the rights listed below. However, these rights are not absolute, and in certain cases, we may
                    decline your
                    request as permitted by law.
                </p>
                <ul>
                    <li>Right to be informed whether or not we are processing your personal data</li>
                    <li>Right to access your personal data</li>
                    <li>Right to correct inaccuracies in your personal data</li>
                    <li>Right to request deletion of your personal data</li>
                    <li>Right to obtain a copy of the personal data you previously shared with us</li>
                    <li>
                        Right to opt out of the processing of your personal data if it is used for targeted advertising,
                        the sale of
                        personal data, or profiling in furtherance of decisions that produce legal or similarly
                        significant effects
                        (&quot;profiling&quot;)
                    </li>
                </ul>
                <p>
                    To submit a request to exercise these rights, please email sales@refalign.com or visit
                    refalign.com/settings.
                </p>
                <p>
                    If we decline to take action regarding your request and you wish to appeal our decision, please
                    email us at
                    sales@refalign.com. Within sixty (60) days of receipt of an appeal, we will inform you in writing of
                    any
                    action taken or not taken in response to the appeal, including a written explanation of the reasons
                    for the
                    decisions.
                </p>

                <h2>Utah Residents</h2>
                <p>
                    This section applies only to Utah residents. Under the Utah Consumer Privacy Act (UCPA), you have
                    the rights
                    listed below. However, these rights are not absolute, and in certain cases, we may decline your
                    request as
                    permitted by law.
                </p>
                <ul>
                    <li>Right to be informed whether or not we are processing your personal data</li>
                    <li>Right to access your personal data</li>
                    <li>Right to request deletion of your personal data</li>
                    <li>Right to obtain a copy of the personal data you previously shared with us</li>
                    <li>
                        Right to opt out of the processing of your personal data if it is used for targeted advertising
                        or the sale
                        of personal data
                    </li>
                </ul>
                <p>
                    To submit a request to exercise these rights described above, please email sales@refalign.com or
                    visit
                    refalign.com/settings.
                </p>
                <h2>Virginia Residents</h2>
                <p>Under the Virginia Consumer Data Protection Act (VCDPA):</p>
                <p>
                    &quot;Consumer&quot; means a natural person who is a resident of the Commonwealth acting only in an
                    individual
                    or household context. It does not include a natural person acting in a commercial or employment
                    context.
                </p>
                <p>
                    &quot;Personal data&quot; means any information that is linked or reasonably linkable to an
                    identified or
                    identifiable natural person. &quot;Personal data&quot; does not include de-identified data or
                    publicly
                    available information.
                </p>
                <p>&quot;Sale of personal data&quot; means the exchange of personal data for monetary consideration.</p>
                <p>
                    If this definition of &quot;consumer&quot; applies to you, you must adhere to certain rights and
                    obligations
                    regarding your personal data.
                </p>

                <h3>Your rights with respect to your personal data</h3>
                <ul>
                    <li>Right to be informed whether or not we are processing your personal data</li>
                    <li>Right to access your personal data</li>
                    <li>Right to correct inaccuracies in your personal data</li>
                    <li>Right to request deletion of your personal data</li>
                    <li>Right to obtain a copy of the personal data you previously shared with us</li>
                    <li>
                        Right to opt out of the processing of your personal data if it is used for targeted advertising,
                        the sale of
                        personal data, or profiling in furtherance of decisions that produce legal or similarly
                        significant effects
                        (&quot;profiling&quot;)
                    </li>
                </ul>
                <p>
                    To exercise your rights provided under the Virginia VCDPA, you may contact us by email at
                    sales@refalign.com
                    or visit refalign.com/settings.
                </p>

                <h3>Verification process</h3>
                <p>
                    We may request that you provide additional information necessary to verify you and your consumer’s
                    request. If
                    you submit the request through an authorized agent, we may need to collect additional information to
                    verify
                    your identity before processing the request.
                </p>
                <p>
                    Upon receiving your request, we will respond without undue delay, but in all cases, within
                    forty-five (45)
                    days of receipt. The response period may be extended once by forty-five (45) additional days when
                    reasonably
                    necessary. We will inform you of any such extension within the initial 45-day response period,
                    together with
                    the reason for the extension.
                </p>

                <h3>Right to appeal</h3>
                <p>
                    If we decline to take action regarding your request, we will inform you of our decision and
                    reasoning behind
                    it. If you wish to appeal our decision, please email us at sales@refalign.com. Within sixty (60)
                    days of
                    receipt of an appeal, we will inform you in writing of any action taken or not taken in response to
                    the
                    appeal, including a written explanation of the reasons for the decisions. If your appeal is denied,
                    you may
                    contact the Attorney General to submit a complaint.
                </p>
                <h2>13. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                <p>
                    We may update this privacy notice from time to time. The updated version will be indicated by an
                    updated
                    &quot;Revised&quot; date and the updated version will be effective as soon as it is accessible. If
                    we make
                    material changes to this privacy notice, we may notify you either by prominently posting a notice of
                    such
                    changes or by directly sending you a notification. We encourage you to review this privacy notice
                    frequently
                    to be informed of how we are protecting your information.
                </p>

                <h2>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                <p>
                    If you have questions or comments about this notice, you may email us at privacy@refalign.com or
                    contact us by
                    post at:
                </p>

                {/* REPLACE WHEN WE HAVE ADDRESSS
      <address>
        RefAlign, Inc
      </address> */}

                <h2>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                <p>
                    Based on the applicable laws of your country, you may have the right to request access to the
                    personal
                    information we collect from you, change that information, or delete it. To request to review,
                    update, or
                    delete your personal information, please visit refalign.com/settings.
                </p>
            </div>
            <LandingPageFooter/>
        </div>
    );
};

export default PrivacyPolicy;
