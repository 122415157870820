import React from "react";


const DescriptionText = ({title, body}) => {
    return (
        <div className="flex flex-col">
            <div className="font-medium">{title}</div>
            <b className="text-lg font-light text-slategray">{body}</b>
        </div>
    )
};
export default DescriptionText;