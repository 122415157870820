import React from 'react';
import {AppContext} from "./AppContext";

function withAppContext(Component) {
    return function WrapperComponent(props) {
        return (
            <AppContext.Consumer>
                {context => <Component {...props} context={context} />}
            </AppContext.Consumer>
        );
    };
}

class ErrorBoundaryBase extends React.Component {
    componentDidCatch(error, errorInfo) {
        console.error("Error caught by Error Boundary:", error, errorInfo);

        try {
            const { sendFeedback } = this.props.context;
            if (sendFeedback) {
                sendFeedback({ Stack: error.stack });
            }
        } catch (feedbackError) {
            console.error("Error sending feedback:", feedbackError);
        }
    }

    render() {
        return this.props.children;
    }
}

const ErrorBoundary = withAppContext(ErrorBoundaryBase);
export default ErrorBoundary;
