import Header from "../Global/Header";
import TableHeader from "../../components/table/TableHeader";
import MyGamesGameRow from "../../components/MyGamesGameRow";
import React, { useState } from "react";
import { useAppContext } from "../../context/MainContext/AppContext";
import ReportGameModal from "../../components/Modals/ReportGameModal";
import { filterGameData } from "../../utils/Utils";
import GamesFilter from "../../components/Modals/GamesFilter";
import TablePaginated from "../../components/table/TablePaginated";

const RefereeGames = () => {
  const { RefereeGameData, FilterSettings, database_user, alterPosition } =
    useAppContext();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isReportGameModalOpen, setIsReportGameModalOpen] = useState(false);
  const [reportGameData, setReportGameData] = useState();
  const [reportGameReferees, setReportGameReferees] = useState();

  const UID = database_user.UID;

  const handleButtonClick = {
    handleReportGames: (data) => {
      console.log(data);
      setReportGameData(data);

      const positionsArray = Object.entries(data.gameData.Positions).map(
        ([id, positionData]) => {
          return { id, ...positionData };
        }
      );

      setReportGameReferees(positionsArray);
      setIsReportGameModalOpen(true);
    },

    handleAssignedGames: (data) => {
      console.log(data);
      alterPosition(data);
    },

    handleUpcomingGames: () => {
      // alterPosition(data);
      alert("Error, please reach out to your assigner to turnback this game!");
    },

    handleSubmitReportGameModal: (data) => {
      alterPosition(data);
      console.log(data);
      setIsReportGameModalOpen(false);
    },
  };

  const { filteredGamesData } = filterGameData(RefereeGameData, FilterSettings);

  const headerButtons = [
    {
      label: "Filter",
      iconSrc: "/filter.svg",
      onClick: () => setIsFilterModalOpen((prev) => !prev),
    },
  ];

  // Construct row components
  const rowsComponents = filteredGamesData.map((game, index) => (
    <MyGamesGameRow
      key={index}
      game={game}
      UID={UID}
      handleButtonClick={handleButtonClick}
      isColumnLayout={true}
    />
  ));

  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start gap-[1.25rem] text-center text-[1rem] text-khaki-200 font-outfit">
      <Header />
      <div className="self-stretch flex flex-col items-center justify-start py-[0rem] px-[0.63rem]">
        <div className="self-stretch mx-auto mt-6 max-w-[89rem] w-11/12">
          <TableHeader title={"My Games"} buttons={headerButtons} />

          <GamesFilter
            games={RefereeGameData}
            isOpen={isFilterModalOpen}
            onClose={() => setIsFilterModalOpen(false)}
          />

          <ReportGameModal
            isOpen={isReportGameModalOpen}
            onClose={() => setIsReportGameModalOpen(false)}
            handleButtonClick={handleButtonClick}
            reportGameData={reportGameData}
            reportGameReferees={reportGameReferees}
          />

          <div className="self-stretch flex flex-col border-1px border-x-[1px] border-b-[1px] gap-[0.63rem] border-solid border-lightgray-100 rounded-b-lg">
            <TablePaginated rowsComponents={rowsComponents} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefereeGames;
