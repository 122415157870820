import React, {useState, useEffect} from "react";
import SearchInput from "./SearchInput";
import DateTimeFilter from "./DateTimeFilter";
import {useAppContext} from "../context/MainContext/AppContext";
import {Role} from "../context/MainContext/Constants";

const FilterModalRow = ({FilteredData}) => {
    // State for managing the FilteredData
    const [filteredData, setFilteredData] = useState(FilteredData);
    const [isChecked, setIsChecked] = useState(FilteredData.Selected);
    const { role } = useAppContext();

    useEffect(() => {
        setIsChecked(FilteredData.Selected);
    }, [FilteredData.Selected]);

    const handleDropdownChange = (newValue) => {
        // Update the filteredData state
        FilteredData.Value = newValue;
        setFilteredData(prevState => ({
            ...prevState,
            Value: newValue
        }));
    };

    const handleCheckboxChange = (event) => {
        FilteredData.Selected = event.target.checked;
        setIsChecked(event.target.checked);
    };

    const renderUIElement = () => {
        switch (FilteredData.Type) {
            case "SearchBox":
                return <SearchInput FilteredData={FilteredData}/>;
            case "Dropdown":
                return (
                    <select
                        className="rounded-3xs w-[160px] mr-[1.rem] bg-white text-xs border-[1px] border-solid border-lightgray-100 text-darkslategray-100 cursor-pointer"
                        value={filteredData?.Value || ""}
                        onChange={e => handleDropdownChange(e.target.value)}
                    >
                        <option value="" hidden>Select an option</option>
                        {filteredData.Items.map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))}
                    </select>
                );
            case "Buttons":
                return (
                    <div>
                        <button className="mr-2">Button 1</button>
                        <button>Button 2</button>
                    </div>
                );
            case "DatePicker":
                return (
                    <div>
                        <DateTimeFilter/>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div
            className="self-stretch flex flex-grow flex-row items-center justify-between py-[5px] px-5 border-[1px] border-solid border-darkslategray-400">
            <div className="flex flex-row items-center gap-[2rem]">
                {role === Role.Assigner && (
                    <input
                        type="checkbox"
                        className="border-black border-[1px] border-solid rounded-3xs"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                )}
                <div className="relative text-base">{FilteredData.Name}</div>
            </div>

            <div className="flex flex-row items-center justify-center">
                {renderUIElement()}
            </div>
        </div>
    );
};

export default FilterModalRow;
