import React from "react";
import useRouter from "../../hooks/router";

const LandingPageFooter = () => {
    const {handleNavigation} = useRouter();

    return (
        <div
            className="self-stretch bg-darkslategray-100 flex flex-col py-[0.63rem] px-[1.25rem] gap-[0.63rem] ">
            <div className="flex flex-col sm:flex-row justify-between">
                <div className="flex flex-col gap-[0.31rem]">
                    <img className="w-[3.13rem] h-[1.49rem]" alt="" src="/logo.svg"/>
                    <div
                        className="items-start font-light text-white py-[0.88rem] sm:px-[0.5rem] text-left max-w-[18.13rem]">
                        RefAlign is a soccer referee assignment platform redefinining the way people think about
                        assignment.
                    </div>
                </div>
                <div
                    className="w-[11.13rem] items-start flex flex-col gap-[0.63rem] text-[1.25rem] text-khaki-100">
                    <b>Legal</b>
                    <div className="flex flex-col gap-[0.31rem] text-[0.88rem]">
                        <div className="font-light cursor-pointer"
                             onClick={() => handleNavigation("/privacypolicy")}>
                            Privacy Policy
                        </div>
                        {/*<div className="font-light">Terms of Service</div>*/}
                    </div>
                </div>
            </div>
            <div className="font-light text-[0.75rem] text-left text-white">© 2024 RefAlign, Inc. All rights reserved.
            </div>
        </div>
    );
};

export default LandingPageFooter;
