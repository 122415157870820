import React from "react";
import ModalWrapper from "./ModalWrapper";

const ConfirmationModal = ({ isOpen, onClose, item, handleConfirm, message, confirmButtonText = 'Yes', cancelButtonText = 'No' }) => {
  return (
      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <div className="rounded-xl bg-white shadow-[15px_15px_30px_rgba(0,_0,_0,_0.1)] flex flex-col items-center justify-center p-[25px] gap-[15px] text-center text-xl text-darkslategray-100 font-outfit">
          <p className="mb-4">{message}</p>

          <div className="flex flex-row gap-4">
            <input
                type="button"
                value={confirmButtonText}
                className="bg-red-500 text-white px-4 py-2 rounded cursor-pointer"
                onClick={() => handleConfirm(item)}
            />
            <button className="bg-gray-300 text-white px-4 py-2 rounded cursor-pointer" onClick={onClose}>
              {cancelButtonText}
            </button>
          </div>
        </div>
      </ModalWrapper>
  );
};

export default ConfirmationModal;
