import React from "react";
import OrgStatsBar from "./OrgStatsBar";
import { dotPulse } from "ldrs";
import TableHeader from "./table/TableHeader";

dotPulse.register()

const AssignerOrgStats = ({ apidata }) => {
  if (!apidata) {
    return (
        <div className="self-stretch flex-1 flex flex-col items-start justify-start">
          <TableHeader
              title={"Organization stats"}
          />
          <div
              className="self-stretch flex-1 rounded-t-none rounded-b-3xs flex flex-col items-center justify-center p-[0.94rem] gap-[0.69rem] text-[0.88rem] text-darkslategray-200 border-r-[1px] border-solid border-lightgray-100 border-b-[1px] border-l-[1px]">
            <l-dot-pulse
                size="43"
                speed="1.3"
                color="#2d556c"
            ></l-dot-pulse>
          </div>
        </div>
    );
  }

  return (
      <div className="self-stretch flex-1 flex flex-col items-start justify-start">
        <TableHeader
            title={"Organization stats"}
        />
        <div
            className="self-stretch flex-1 rounded-t-none rounded-b-3xs flex flex-col items-start justify-start p-[0.94rem] gap-[0.69rem] text-[0.88rem] text-darkslategray-200 border-r-[1px] border-solid border-lightgray-100 border-b-[1px] border-l-[1px]">
          <div className="self-stretch flex flex-row items-start justify-start gap-[0.63rem]">
            <div
                className="flex-1 rounded-3xs bg-white flex flex-col items-start justify-end p-[0.94rem] gap-[0.63rem] border-[1px] border-solid border-darkslategray-400">
              <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-row items-center justify-between gap-[0.5rem]">
                  <div className="relative opacity-[0.8]">Today&rsquo;s Games</div>
                  <div className="relative text-[1.5rem] font-semibold text-darkslategray-100 mr-[1.25rem]">
                    {apidata?.TodaysGameCount}
                  </div>
                </div>
              </div>
              <OrgStatsBar
                  name="Accepted Positions"
                  data={apidata?.TodaysGamesStaffedPercentage}
              />
              <OrgStatsBar
                  name="Assigned Positions"
                  data={apidata?.TodaysGamesStaffedPercentage}
              />
            </div>
            <div
                className="flex-1 rounded-3xs bg-white flex flex-col items-start justify-end p-[0.94rem] gap-[0.63rem] border-[1px] border-solid border-darkslategray-400">
              <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-row items-center justify-between gap-[0.5rem]">
                  <div className="relative opacity-[0.8]">Tomorrow&rsquo;s Games</div>
                  <div className="relative text-[1.5rem] font-semibold text-darkslategray-100 mr-[1.25rem]">
                    {apidata?.TomorrowsGameCount}
                  </div>
                </div>
              </div>
              <OrgStatsBar
                  name="Accepted Positions"
                  data={apidata?.TomorrowsGamesStaffedPercentage}
              />
              <OrgStatsBar
                  name="Assigned Positions"
                  data={apidata?.TomorrowsGamesStaffedPercentage}
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[0.63rem]">
            <div
                className="flex-1 rounded-3xs bg-white flex flex-col items-start justify-end p-[0.94rem] gap-[1.31rem] border-[1px] border-solid border-darkslategray-400">
              <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-start">
                  <div className="relative opacity-[0.8]">Assigned Games</div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-end gap-[0.63rem] text-limegreen-100">
                <OrgStatsBar
                    name="Next 7 Days"
                    data={apidata?.Next7DaysGamesStaffedPercentage}
                />
                <OrgStatsBar
                    name="Next 30 Days"
                    data={apidata?.TomorrowsGamesStaffedPercentage}
                />
              </div>
            </div>
            <div
                className="flex-1 rounded-3xs bg-white flex flex-col items-start justify-end p-[0.94rem] gap-[1.31rem] border-[1px] border-solid border-darkslategray-400">
              <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-start">
                  <div className="relative opacity-[0.8]">Accepted Games</div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-end gap-[0.63rem] text-limegreen-100">
                <OrgStatsBar
                    name="Next 7 Days"
                    data={apidata?.Next7DaysGamesStaffedPercentage}
                />
                <OrgStatsBar
                    name="Next 30 Days"
                    data={apidata?.TomorrowsGamesStaffedPercentage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default AssignerOrgStats;
