import { useNavigate } from "react-router-dom";

const useRouter = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return { handleNavigation };
};

export default useRouter;
