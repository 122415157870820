import React, {useState} from "react";
import {dotPulse} from "ldrs";

dotPulse.register()

const AssignGameRefereeRow = ({game, refData, openGameAssignModal, positionID}) => {
    const [isLoading, setIsLoading] = useState(false);
    let bgColor;

    switch (refData.Status) {
        case "Unassigned":
            bgColor = "bg-salmon-200";
            break;
        case "Pending":
            bgColor = "bg-goldenrod-200";
            break;
        case "SentToRef":
            bgColor = "bg-limegreen-100";
            break;
        case "Report":
            bgColor = "bg-limegreen-100";
            break;
        case "Referee_Reported":
            bgColor = "bg-limegreen-100";
            break;
        default:
            bgColor = "bg-salmon-200";
    }

    const iconType = refData.Status === "Assigned" ? "edit" : "search";

    // Determine the display text based on Status
    const displayText =
        refData.User && refData.User.Name
            ? refData.User.Name
            : refData.Status === "Unassigned"
                ? refData.Status
                : refData.Name;

    const handleButtonClick = async () => {
        setIsLoading(true); // Set loading to true when the button is clicked
        await openGameAssignModal({game, positionID});
        setIsLoading(false); // Set loading to false once modal action is completed
    };

    if (isLoading) {
        return (
            <div
                className="self-stretch rounded-2xl bg-darkslategray-600 flex flex-row items-center justify-start py-[0rem] pr-[0rem] pl-[0.31rem] gap-[0.25rem]">
                <div
                    className="flex-1 rounded-xl bg-lightblue-200 flex flex-row items-center justify-center py-[0.31rem] px-[0.63rem] gap-[0.44rem] text-center text-white">
                    <l-dot-pulse
                        size="43"
                        speed="1.3"
                        color="#2d556c"
                    ></l-dot-pulse>
                </div>
            </div>
        );
    }


    return (
        <div
            className="self-stretch rounded-2xl bg-darkslategray-600 flex flex-row items-center justify-start py-[0rem] pr-[0rem] pl-[0.31rem] gap-[0.25rem]">
            <div className="relative leading-[137.3%] flex items-center justify-center w-[2.31rem] shrink-0">
                {refData.Name}
            </div>
            <div
                className={`flex-1 rounded-xl ${bgColor} flex flex-row items-center justify-start py-[0.31rem] px-[0.63rem] gap-[0.44rem] text-left text-white`}
            >
                <div
                    className="flex-1 flex flex-row items-center justify-start cursor-pointer"
                    onClick={handleButtonClick}
                >
                    <div className="relative">{displayText}</div>
                </div>
                <div
                    className="rounded-8xs bg-darkslategray-300 flex flex-row items-start justify-start cursor-pointer">
                    <img
                        className="relative w-[0.63rem] h-[0.63rem]"
                        alt={iconType === "edit" ? "Edit icon" : "Search icon"}
                        src={iconType === "edit" ? "/editiconwhite.svg" : "/searchnormal2.svg"}
                    />
                </div>
            </div>
        </div>
    );
};

export default AssignGameRefereeRow;
