import React, { useState } from "react";
import Header from "../Global/Header";
import TableHeader from "../../components/table/TableHeader";
import { useAppContext } from "../../context/MainContext/AppContext";
import ModifyRefereeinOrganization from "../../components/Modals/ModifyRefereeinOrganization";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import ManuallyCreateModal from "../../components/Modals/ManuallyCreateModal";
import ImportModal from "../../components/Modals/ImportModal";
import TablePaginated from "../../components/table/TablePaginated";
import RefereeRow from "../../components/AssignerManageRefereeRow";
import { IMPORT_USER_EXAMPLE } from "../../utils/DownloadLinks";

const AssignerReferees = () => {
  const [isManuallyCreateModalOpen, setIsManuallyCreateModalOpen] =
    useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isModifyRefereeModalOpen, setIsModifyRefereeModalOpen] =
    useState(false);
  const [isRemoveRefereeModalOpen, setIsRemoveRefereeModalOpen] =
    useState(false);
  const {
    OrganizationData,
    currentOrganization,
    inviteUsers,
    removeUser,
    modifyUser,
  } = useAppContext();
  const [refereeToModify, setRefereeToModify] = useState();

  const requiredModalFields = ["Name", "Email"];

  const modalInputFields = [
    { label: "Name", key: "Name", type: "text" },
    { label: "Email", key: "Email", type: "text" },
  ];

  const handleCSVImport = (adjustedData) => {
    const OID = currentOrganization;
    const formattedData = {
      OID: OID,
      Invites: adjustedData.map((row) => ({
        Email: row.Email,
        Name: row.Name,
      })),
    };

    console.log(formattedData);
    inviteUsers(formattedData);
  };

  const handleManualImport = (data) => {
    const OID = currentOrganization;
    const formattedData = {
      OID: OID,
      Invites: [
        {
          Email: data.Email,
          Name: data.Name,
        },
      ],
    };

    console.log(formattedData);
    inviteUsers(formattedData);
  };

  const validateRow = (row) => {
    let errors = [];
    // Example validation logic for email
    if (!row["Email"] || !/\S+@\S+\.\S+/.test(row["Email"])) {
      errors.push("Email is invalid or missing");
    }
    // Example validation logic for name
    if (!row["Name"] || row["Name"].trim() === "") {
      errors.push("Name is missing");
    }
    return errors;
  };

  const handleRemoveReferee = (removeUserData) => {
    setIsRemoveRefereeModalOpen(false);

    const dataToRemoveUser = {
      OID: currentOrganization,
      Email: removeUserData.Email,
    };

    removeUser(dataToRemoveUser);
    console.log(dataToRemoveUser);
  };

  const handleModifyReferee = (modifyRefereeData) => {
    const dataToModifyUser = {
      OID: currentOrganization,
      Email: modifyRefereeData.Email,
      ...modifyRefereeData,
    };

    modifyUser(dataToModifyUser);
    console.log(dataToModifyUser);
    setIsModifyRefereeModalOpen(false);
  };

  const handleReinviteClick = () => {
    console.log("reinvitedpressed");
  };

  const openHandleModifyRefereeModal = (user) => {
    setRefereeToModify(user);
    setIsModifyRefereeModalOpen(true);
  };

  const openHandleRemoveRefereeModal = (user) => {
    setRefereeToModify(user);
    setIsRemoveRefereeModalOpen(true);
  };

  const headerButtons = [
    {
      label: "Create User",
      iconSrc: "/vector1.svg",
      onClick: () => setIsManuallyCreateModalOpen((prev) => !prev),
    },
    {
      label: "Import Users",
      iconSrc: "/vector1.svg",
      onClick: () => setIsImportModalOpen((prev) => !prev),
    },
  ];

  const fieldNames = ["Type", "Name", "Email", "Permission", "Action"];

  const rowsComponents = Object.values(OrganizationData?.Users || {}).map(
    (user) => (
      <RefereeRow
        key={user.Email}
        user={user}
        type="User"
        openHandleModifyRefereeModal={openHandleModifyRefereeModal}
        openHandleRemoveRefereeModal={openHandleRemoveRefereeModal}
      />
    )
  );

  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-center text-[1rem] text-khaki-200 font-outfit">
      <Header />
      <div className="w-full flex flex-col items-start justify-start py-[1.25rem] px-[0.63rem] box-border max-w-[93.75rem] text-left text-[1.25rem] text-darkslategray-100">
        <TableHeader title={"User List"} buttons={headerButtons} />

        <ModifyRefereeinOrganization
          isOpen={isModifyRefereeModalOpen}
          onClose={() => setIsModifyRefereeModalOpen(false)}
          refereeToModify={refereeToModify}
          handleModifyReferee={handleModifyReferee}
          handleReinviteClick={handleReinviteClick}
        />

        <ConfirmationModal
          isOpen={isRemoveRefereeModalOpen}
          onClose={() => setIsRemoveRefereeModalOpen(false)}
          item={refereeToModify}
          handleConfirm={handleRemoveReferee}
          message={
            "Are you sure you want to remove this user from the organization?"
          }
        />

        <ManuallyCreateModal
          isOpen={isManuallyCreateModalOpen}
          onClose={() => {
            setIsManuallyCreateModalOpen(false);
          }}
          handleManualImport={handleManualImport}
          ModalTitle="Create User"
          fields={modalInputFields}
          requiredModalFields={requiredModalFields}
        />

        <ImportModal
          isOpen={isImportModalOpen}
          onClose={() => {
            setIsImportModalOpen(false);
          }}
          ModalTitle="Import Users"
          onImport={handleCSVImport}
          fields={modalInputFields}
          exampleDownload={IMPORT_USER_EXAMPLE}
          validateRow={validateRow}
        />

        <TablePaginated
          fieldNames={fieldNames}
          rowsComponents={rowsComponents}
        />
      </div>
    </div>
  );
};

export default AssignerReferees;
