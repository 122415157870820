export {
  ManuallyCreateModalInputFields,
  ImportModalFields,
  ManuallyCreateRequiredFields,
};

const ManuallyCreateModalInputFields = [
  { label: "Time", key: "Time", type: "timePicker", required: true },
  { label: "Date", key: "Date", type: "datePicker", required: true },
  {
    label: "Half Duration",
    key: "HalfDuration",
    type: "number",
    required: true,
  },
  { label: "Field Name", key: "FieldName", type: "text" },
  { label: "Address", key: "Address", type: "text", required: true },
  { label: "Home Team", key: "HomeTeam", type: "text" },
  { label: "Away Team", key: "AwayTeam", type: "text" },
  { label: "League", key: "League", type: "text" },
  { label: "Age Group", key: "AgeGroup", type: "text" },
  { label: "Gender", key: "Gender", type: "text" },
  { label: "Level of Play", key: "LevelOfPlay", type: "text" },
];

const ManuallyCreateRequiredFields = [
  "Time",
  "Date",
  "HalfDuration",
  "Address",
];

const ImportModalFields = [
  { label: "Start Time", key: "StartTime", required: true },
  {
    label: "Half Duration",
    key: "HalfDuration",
    type: "number",
    required: true,
  },
  { label: "Field Name", key: "FieldName", type: "text" },
  { label: "Address", key: "Address", type: "text", required: true },
  { label: "Home Team", key: "HomeTeam", type: "text" },
  { label: "Away Team", key: "AwayTeam", type: "text" },
  { label: "League", key: "League", type: "text" },
  { label: "Age Group", key: "AgeGroup", type: "text" },
  { label: "Gender", key: "Gender", type: "text" },
  { label: "Level of Play", key: "LevelOfPlay", type: "text" },
];
