import axios from "axios";
import {addToLocalStorage, getFromLocalStorage, StorageKeys} from "./LocalStorage";
import {getAuth} from "firebase/auth";
import {SET_TOKEN} from "../actions";

const isTokenStale = (token) => {
    if (!token) return true; // If no token, it's considered stale
    const payloadBase64 = token.split('.')[1];
    const decodedJson = atob(payloadBase64);
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp * 1000; // Convert to milliseconds
    return exp < Date.now();
};

const updateAccessToken = async (state, dispatch, logout) => {
    const currentToken = state.access_token || getFromLocalStorage(StorageKeys.ACCESS_TOKEN);
    if (isTokenStale(currentToken)) {
        const auth = getAuth();
        if (auth.currentUser) {
            const newToken = await auth.currentUser.getIdToken(true);
            dispatch({ type: SET_TOKEN, payload: newToken }); // Update the state
            addToLocalStorage(StorageKeys.ACCESS_TOKEN, newToken); // Update local storage
            return newToken;
        } else {
            logout();
            console.error('Token refresh or authentication failed');
            return null;
        }
    } else {
        return currentToken;
    }
};

const queryEndpointInternal = async (state, dispatch, logout, endpoint, payload = null) => {
    try {
        const access_token = await updateAccessToken(state, dispatch, logout);

        // Return early if no access token found
        if (!access_token) {
            return
        }

        // Construct URL with the endpoint as part of the base URL
        const url = `https://${endpoint}-de2monrbjq-uc.a.run.app`;

        let response;
        const config = {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        };

        if (typeof payload === 'string') {
            // If payload is a string, assume GET request with query parameters
            response = await axios.get(`${url}?${payload}`, config);
        } else if (typeof payload === 'object') {
            // If payload is an object, assume POST request
            response = await axios.post(url, payload, config);
        } else {
            // If payload is null, assume GET request with no query parameters
            response = await axios.get(url, config);
        }

        console.log('Response:', response.data);
        return response.data;
    } catch (error) {
        console.error('An error occurred:', error);
        throw error;
    }
};

export {updateAccessToken, queryEndpointInternal}

