import React, { useRef, useState } from "react";
import Papa from "papaparse";
import TitleModalWrapper from "./TitleModalWrapper";
import ModuleButtonGroup from "./ModalButtonsGroup";

const ImportModal = ({
  isOpen,
  onClose,
  ModalTitle,
  fields,
  onImport,
  exampleDownload,
  validateRow,
}) => {
  const [columnMappings, setColumnMappings] = React.useState({});
  const [parsedCSVData, setParsedCSVData] = useState([]);
  const [erroredCSVData, setErroredCSVData] = useState([]);
  const [canImport, setCanImport] = useState(false);
  const fileInputRef = useRef(null);

  const handleImportFromCSV = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const isValidMimeType =
        fileType === "text/csv" || fileType === "application/vnd.ms-excel";
      const isValidExtension = fileExtension === "csv";

      if (!isValidMimeType || !isValidExtension) {
        alert("Please select a valid CSV file.");
        return;
      }

      Papa.parse(file, {
        complete: (result) => {
          let erroredRows = [];
          let autoMappings = {}; // Assuming you might auto-map based on headers or similar
          const validatedData = result.data.reduce((acc, row, index) => {
            // Initial auto-mapping could be done here based on `fields` and CSV header names
            if (index === 0) {
              // Example: auto-detect mappings based on matching header names to `fields` labels
              Object.keys(row).forEach((columnName) => {
                const matchedField = fields.find(
                  (field) => field.label === columnName
                );
                if (matchedField) {
                  autoMappings[columnName] = matchedField.key;
                }
              });
            }

            const errors = validateRow(row); // Use the validateRow prop

            if (errors.length > 0) {
              row["Error"] = errors.join("; ");
              erroredRows.push(row);
            } else {
              acc.push(row);
            }

            return acc;
          }, []);

          setColumnMappings(autoMappings); // Update the mappings based on auto-detection or existing logic
          updateCanImport(autoMappings); // Check if all required mappings are done and update `canImport`

          // Update state with validated and errored rows
          setParsedCSVData(validatedData);
          setErroredCSVData(erroredRows);

          if (erroredRows.length > 0) {
            console.log(
              `${erroredRows.length} rows with errors have been separated.`
            );
          }
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  };

  // Function to download errored CSV data
  const downloadErroredCSV = () => {
    const csv = Papa.unparse(erroredCSVData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "errored_rows.csv"); // Name the file as you like
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const updateCanImport = (mappings) => {
    const allMapped = Object.values(mappings).every(
      (mapping) => mapping && mapping !== ""
    );
    setCanImport(allMapped);
  };

  const handleMappingChange = (columnName, selectedField) => {
    setColumnMappings((prevMappings) => {
      const newMappings = {
        ...prevMappings,
        [columnName]: selectedField,
      };

      updateCanImport(newMappings);

      return newMappings;
    });
  };

  const handleImport = () => {
    const adjustedData = parsedCSVData
      .map((row) => {
        const newRow = {};
        for (let columnName in row) {
          const targetKey =
            columnMappings[columnName] ||
            fields.find((f) => f.label === columnName)?.key ||
            columnName;

          newRow[targetKey] = row[columnName];
        }
        return newRow;
      })
      .filter((item) => Object.keys(item).length > 0);

    onImport(adjustedData);
    onClose();
  };

  const leftButtons = [
    {
      downloadUrl: exampleDownload,
      text: "Download Template",
      theme: "DARK",
    },
  ];

  const rightButtons = [
    {
      onClick: () => handleImportFromCSV(),
      text: "Load",
      theme: "DARK",
    },
    {
      onClick: () => handleImport(),
      text: "Import",
      theme: "DARK",
      state: canImport ? "ACTIVE" : "DISABLED",
    },
  ];

  return (
    <TitleModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      title={ModalTitle}
      width={"829px"}
    >
      {erroredCSVData.length > 0 && (
        <div className="flex flex-row text-sm">
          <div>
            There are {erroredCSVData.length} errors in the imported CSV. You
            can proceed with the import or fix the errors. Please contact
            support@refalign.com with additional questions.
          </div>
          <button onClick={downloadErroredCSV}>Download Error CSV</button>
        </div>
      )}
      <div className="self-stretch flex flex-col">
        {/* Header */}
        <div className="self-stretch rounded-t-3xs rounded-b-none bg-darkslategray-600 flex flex-row items-center py-[15px] px-5 text-sm text-darkslategray-100 border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
          <div className="flex-1 relative font-semibold">Column Name</div>
          <div className="flex-1 relative font-semibold">RefAlign Field</div>
          <div className="flex-1 relative font-semibold">Sample Row #1</div>
          <div className="flex-1 relative font-semibold">Sample Row #2</div>
          <div className="flex-1 relative font-semibold">Sample Row #3</div>
        </div>
        {/* Body */}
        <div className="flex-grow flex flex-col text-sm text-darkslategray-200 overflow-auto max-h-[30rem]">
          {parsedCSVData.length > 0 &&
            Object.keys(parsedCSVData[0]).map((columnName, index) => (
              <ImportModalRow
                key={index}
                columnName={columnName}
                samples={[
                  parsedCSVData[0] ? parsedCSVData[0][columnName] : null,
                  parsedCSVData[1] ? parsedCSVData[1][columnName] : null,
                  parsedCSVData[2] ? parsedCSVData[2][columnName] : null,
                ]}
                fields={fields}
                onMappingChange={handleMappingChange}
                currentMapping={columnMappings[columnName]}
              />
            ))}
        </div>

        <input
          ref={fileInputRef}
          type="file"
          accept=".csv"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        {/*FOOTER*/}
        <ModuleButtonGroup
          leftButtons={leftButtons}
          rightButtons={rightButtons}
        />
      </div>
    </TitleModalWrapper>
  );
};

export default ImportModal;

const ImportModalRow = ({ columnName, samples, fields, onMappingChange }) => {
  const initialOption = fields.some((field) => field.label === columnName)
    ? columnName
    : "";
  const [selectedOption, setSelectedOption] = useState(initialOption || "");

  const handleSelectChange = (event) => {
    const optionLabel = event.target.value;

    if (optionLabel === "") {
      setSelectedOption("");
      onMappingChange(columnName, null);
    } else {
      const selectedField = fields.find((field) => field.label === optionLabel);
      setSelectedOption(optionLabel);
      onMappingChange(columnName, selectedField.key);
    }
  };

  return (
    <div className="self-stretch flex flex-row items-center justify-between border-r-[1px] border-solid border-darkslategray-400 border-b-[1px] border-l-[1px]">
      <div className="flex-1 relative">{columnName}</div>
      <div className="self-stretch flex-1 flex flex-col items-center justify-between text-left relative">
        <select
          value={selectedOption}
          onChange={handleSelectChange}
          className="rounded-3xs flex-1 w-[120px] bg-white text-xs border-[1px] border-solid border-lightgray-100 text-darkslategray-100 cursor-pointer          "
        >
          <option value="">-- Select --</option>
          {fields?.map((field) => (
            <option key={field.label} value={field.label}>
              {field.label}
            </option>
          ))}
        </select>
      </div>
      <div className="flex-1 relative">{samples[0]}</div>
      <div className="flex-1 relative">{samples[1]}</div>
      <div className="flex-1 relative">{samples[2]}</div>
    </div>
  );
};
